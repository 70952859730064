import React, { Fragment } from "react";
import styled from "styled-components";
import Placeholder from "@datashop/placeholder";
import Card from "@datashop/card";
import { Loader } from "../../packages/loader";
import Icon from "@datashop/icon";

const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 100px;
  height: 100%;
  width: 100%;
  @media (max-width: 720px) {
    padding: 32px 16px;
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
`;

const DesktopHeader = styled.div`
  display: flex;
  background-color: #fff;
  padding-bottom: 35px;
  align-items: center;
  justify-content: space-between;
`;

const HeaderContents = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderAddress = styled.div`
  margin-right: 10px;
  font-size: 16px;
  color: grey;
`;

const HeaderPhone = styled.div`
  font-size: 16px;
`;

const PhoneIcon = styled(Icon)`
  font-size: 14px;
  margin-right: 6px;
`;

const DesktopFooter = styled.div`
  display: flex;
  color: grey;
  align-self: center;
  justify-content: center;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 14px;
`;

const BodyWrapper = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/visitRequest.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  padding-bottom: 45px;
  // min-width: 500px;
  min-height: 850px;
  height: 100vh;
`;

const BodyView = styled.div`
  // min-width: 490px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
`;

const BodyLoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

const cardStyes = {
  maxWidth: "450px",
  margin: "45px auto",
  padding: "30px",
  backgroundColor: "#fff",
  width: "90%"
};

const LogoLoader = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Placeholder withImage>
        <Placeholder.Paragraph length="small" />
        <Placeholder.Paragraph length="small" />
      </Placeholder>
    </div>
  );
};

const DesktopLogo = styled.img`
  height: 32px;
`;

export const DesktopView = props => {
  const { loading, logoUrl, address, phone } = props;

  return (
    <DesktopContainer>
      <DesktopHeader>
        {loading ? (
          <LogoLoader />
        ) : (
          <Fragment>
            <DesktopLogo src={logoUrl} />
            <HeaderContents>
              <HeaderAddress>{address}</HeaderAddress>
              <HeaderPhone>
                {phone && (
                  <>
                    <PhoneIcon name="phone" /> {phone}
                  </>
                )}
              </HeaderPhone>
            </HeaderContents>
          </Fragment>
        )}
      </DesktopHeader>
      {loading ? (
        <BodyLoaderWrapper>
          <Loader />
        </BodyLoaderWrapper>
      ) : (
        <BodyWrapper>
          <BodyView>
            <Card shadow="medium" style={cardStyes}>
              {props.children}
            </Card>
          </BodyView>
        </BodyWrapper>
      )}
      <DesktopFooter>Powered by Innovaccer</DesktopFooter>
    </DesktopContainer>
  );
};

export const MobileView = props => {
  return <MobileContainer>{props.children}</MobileContainer>;
};
