import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Card from '@datashop/card';
import Placeholder from '@datashop/placeholder';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
`;

const SuccessCard = styled(Card)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const ShowResultWrapper = styled.div`
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const ShowResult = styled.div`
  color: #2f2f2f;
  line-height: 20px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
`;

class NextStep extends Component {
  render() {
    const { value, riskTemplate, loadingTemplate } = this.props;
    return (
      <Wrapper>
        <SuccessCard shadow='light'>
          {value === 'Yes' ? (
            <ShowResultWrapper>
              <ShowResult
                {...(loadingTemplate['highRisk']
                  ? {
                      style: {
                        width: '100%'
                      },
                      children: (
                        <>
                          <Placeholder.Paragraph length='medium' />
                          <Placeholder.Paragraph length='small' />
                        </>
                      )
                    }
                  : {
                      dangerouslySetInnerHTML: {
                        __html: riskTemplate['highRisk']
                      }
                    })}></ShowResult>
            </ShowResultWrapper>
          ) : (
            <ShowResultWrapper>
              <ShowResult
                {...(loadingTemplate['mediumRisk']
                  ? {
                      style: {
                        width: '100%'
                      },
                      children: (
                        <>
                          <Placeholder.Paragraph length='medium' />
                          <Placeholder.Paragraph length='small' />
                        </>
                      )
                    }
                  : {
                      dangerouslySetInnerHTML: {
                        __html: riskTemplate['mediumRisk']
                      }
                    })}></ShowResult>
            </ShowResultWrapper>
          )}
        </SuccessCard>
      </Wrapper>
    );
  }
}

export default NextStep;
