import Axios from "axios";
import history from "./../history";
import { Mixpanel } from "./mixpanelHelper";
import createToast from "./toastHelper";

const CancelToken = Axios.CancelToken;
const isCancel = Axios.isCancel;

const patientOutreachUrl = window.location.href.includes("covid")
  ? "https://dif7wpsznb.execute-api.us-east-2.amazonaws.com/prod"
  : "https://17y6kj10e4.execute-api.us-east-2.amazonaws.com/preprod";

const URL = process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_API_URL`];

const axios = Axios.create({
  baseURL: URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "X-TIMEZONE-OFFSET": new Date().getTimezoneOffset()
  }
});

axios.interceptors.response.use(null, error => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Check if error has a response object
  if (error.response) {
    const redirectPath = `${history.location.pathname}${history.location.search}`;
    // Show error toast
    const {
      response: {
        data: { error: { message = "Something went wrong" } = {} }
      }
    } = error;
    // Check for login API
    if (error.response.config.url.includes("user/auth/_validate")) {
      // Login API returns 401 on wrong attempt
      if (error.response.status === 401) {
        Mixpanel.track("Incorrect OTP");
      }
    } else if (error.response.status === 401) {
      // Check if a API gives unauthorized status
      Mixpanel.track("Unauthorized Access", {
        url: error.response.config.url
      });
      createToast({ title: message });
      localStorage.clear();
      localStorage.setItem("isOnboarded", true);
      history.push(redirectPath);
      window.location.reload();
    } else if (error.response.status === 404) {
      // Check if a API route was not found
      Mixpanel.track("Api Not Found", {
        url: error.response.config.url
      });
    } else if (error.response.status === 428) {
      const redirectUrl = encodeURIComponent(redirectPath);
      history.push(`/accept-consent?redirect=${redirectUrl}`);
    }
  }

  return Promise.reject(error);
});

export default axios;
export { CancelToken, isCancel, patientOutreachUrl };
