import React, { Component } from "react";
import styled from "styled-components";
import StyledInput from "@datashop/input";
import Button from "@datashop/button";
import DatePicker from "@datashop/date-picker";
import Radio from "@datashop/radio";
import { isValid } from "date-fns";

const Wrapper = styled.div`
  flex: 1;
`;

const Header = styled.div`
  color: rgb(0, 0, 0);
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.87px;
  line-height: 36px;
`;

const InputWrapper = styled.div`
  margin-top: 16px;
  i {
    margin-top: 4px;
  }

  input {
    height: 40px;
  }
`;

const Label = styled.p`
  color: #2f2f2f;
  font-weight: 700;
  line-height: 16px;
`;

const LabelWrapper = styled.div`
  display: flex;
`;

const MandatoryDot = styled.p`
  width: 6px;
  height: 6px;
  margin-left: 4px;
  margin-top: 2px;
  background-color: rgb(225, 95, 95);
  border-radius: 50%;
`;

const Input = styled(StyledInput)`
  height: 40px;
`;

const ButtonWrapper = styled.div`
  padding: 4px;
  display: flex;

  ${Button} {
    margin-right: 8px;
  }
`;

const RadioWrapper = styled.div`
  margin-top: 16px;

  > div {
    margin-bottom: 12px;
    color: #2f2f2f;
    font-weight: 600;
    line-height: 16px;
  }
`;

const Language = {
  English: {
    header: "Patient Details",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    email: "Email",
    dob: "DOB",
    occupation: "Occupation",
    gender: "Gender",
    male: "Male",
    female: "Female",
    other: "Other",
    roo1: "First Responder",
    roo2: "Essential/Emergency Services",
    roo3: "Health Care Worker",
    roo4: "Other"
  },
  Spanish: {
    header: "Detalles del Paciente ",
    firstName: "Nombre",
    lastName: "Apellido",
    phoneNumber: "Numero de Teléfono",
    email: "Email(Correa Electronico)",
    dob: "Fecha de Nacimiento (00mes/00día/0000año)",
    occupation: "Ocupación",
    gender: "Género",
    male: "Hombre",
    female: "Mujer",
    other: "Otro",
    roo1: "Primer Respondedor(a)",
    roo2: "Servicios  Esenciales/de Emergencia",
    roo3: "Trabajador(a) de Salud",
    roo4: "Otro"
  }
};

class PatientDetails extends Component {
  state = {
    showDatePicker: false
  };

  handleFocusChange = focus => {
    this.showDatePicker(focus);
  };

  showDatePicker = value => {
    if (value !== this.state.showDatePicker)
      this.setState({ showDatePicker: value });
  };

  handleDateChange = date => {
    this.props.onDateChange(date);
    if (date.length === 10 && isValid(new Date(date)))
      this.showDatePicker(false);
    else this.showDatePicker(true);
  };

  render() {
    const {
      onChange,
      firstName,
      lastName,
      hidePhoneNo,
      DOB,
      gender,
      onButtonChange,
      occupation,
      phoneNo,
      email,
      onDateChange,
      language = "English"
    } = this.props;

    return (
      <Wrapper>
        <Header>{Language[language].header}</Header>
        <InputWrapper>
          <LabelWrapper>
            <Label>{Language[language].firstName}</Label>
            <MandatoryDot />
          </LabelWrapper>
          <Input
            onChange={onChange}
            value={firstName}
            name="firstName"
            placeholder={Language[language].firstName}
            icon="person"
          />
        </InputWrapper>
        <InputWrapper>
          <LabelWrapper>
            <Label>{Language[language].lastName}</Label>
            <MandatoryDot />
          </LabelWrapper>
          <Input
            onChange={onChange}
            value={lastName}
            name="lastName"
            placeholder={Language[language].lastName}
            icon="person"
          />
        </InputWrapper>
        <InputWrapper>
          <LabelWrapper>
            <Label>{Language[language].email}</Label>
          </LabelWrapper>
          <Input
            onChange={onChange}
            type="email"
            value={email}
            name="email"
            placeholder="john@doe.com"
            icon="email"
          />
        </InputWrapper>
        {!hidePhoneNo && (
          <InputWrapper>
            <LabelWrapper>
              <Label>{Language[language].phoneNumber}</Label>
              <MandatoryDot />
            </LabelWrapper>
            <Input
              onChange={onChange}
              type="number"
              value={phoneNo}
              name="phoneNo"
              placeholder={Language[language].phoneNumber}
              icon="call"
            />
          </InputWrapper>
        )}
        <InputWrapper>
          <LabelWrapper>
            <Label>{Language[language].dob}</Label>
            <MandatoryDot />
          </LabelWrapper>
          <DatePicker
            date={DOB}
            dateFormat="MM/DD/YYYY"
            closeOnDateSelect
            disableDate={date => date > new Date()}
            onDateChange={date => {
              this.handleDateChange(date);
            }}
            showDatePicker={this.state.showDatePicker}
            onFocusChange={this.handleFocusChange}
            position="Bottom"
            appendToBody={false}
          />
        </InputWrapper>
        <RadioWrapper>
          <LabelWrapper>
            <Label>{Language[language].occupation}</Label>
            <MandatoryDot />
          </LabelWrapper>
          <Radio
            name="occupation"
            value="First Responder"
            checked={occupation === "First Responder"}
            label={Language[language].roo1}
            onChange={onChange}
            key={"First Responder"}
            id={"First Responder"}
          />
          <Radio
            name="occupation"
            value="Essential/Emergency Services"
            checked={occupation === "Essential/Emergency Services"}
            label={Language[language].roo2}
            onChange={onChange}
            key={"Essential/Emergency Services"}
            id={"Essential/Emergency Services"}
          />
          <Radio
            name="occupation"
            value="Health Care Worker"
            checked={occupation === "Health Care Worker"}
            label={Language[language].roo3}
            onChange={onChange}
            key={"Health Care Worker"}
            id={"Health Care Worker"}
          />
          <Radio
            name="occupation"
            value="Other"
            checked={occupation === "Other"}
            label={Language[language].roo4}
            onChange={onChange}
            key={"Other"}
            id={"Other"}
          />
        </RadioWrapper>
        <InputWrapper>
          <LabelWrapper>
            <Label>{Language[language].gender}</Label>
            <MandatoryDot />
          </LabelWrapper>
          <ButtonWrapper>
            <Button
              onClick={onButtonChange}
              hollow={gender !== "Male"}
              name="Male"
              appearance={gender === "Male" ? "primary" : "secondary"}
            >
              {Language[language].male}
            </Button>
            <Button
              onClick={onButtonChange}
              hollow={gender !== "Female"}
              name="Female"
              appearance={gender === "Female" ? "primary" : "secondary"}
            >
              {Language[language].female}
            </Button>
            <Button
              onClick={onButtonChange}
              hollow={gender !== "Other"}
              name="Other"
              appearance={gender === "Other" ? "primary" : "secondary"}
            >
              {Language[language].other}
            </Button>
          </ButtonWrapper>
        </InputWrapper>
      </Wrapper>
    );
  }
}

export default PatientDetails;
