const bandwidthProfile = {
  video: {
    mode: "grid",
    maxTracks: 3,
    renderDimensions: {
      high: { height: 1080, width: 1980 },
      standard: { height: 720, width: 1280 },
      low: { height: 540, width: 960 }
    }
  }
};

export default bandwidthProfile;
