import React, { Component } from "react";
import styled from "styled-components";
import Card from "@datashop/card";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
  padding: 16px;
`;

const ErrorCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
`;

const SubResult = styled.div`
  color: #868686;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`;

const Icon = styled.i`
  font-size: 56px;
  margin-bottom: 16px;
  align-self: center;
  color: #9e2929;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Language = {
  English: {
    completeError: "This assessment is already completed.",
    error: "Some error occurred, please try again!"
  },
  Spanish: {
    completeError: "Esta evaluación ya está completa.",
    error: "Se produjo un error, ¡inténtalo de nuevo!"
  }
};

class ErrorPage extends Component {
  render() {
    const { error, language } = this.props;
    return (
      <Wrapper>
        <ErrorCardWrapper shadow="light">
          <DataWrapper>
            <Icon className="material-icons">error</Icon>
            <SubResult>
              {error.code === 422
                ? Language[language].completeError
                : Language[language].error}
            </SubResult>
          </DataWrapper>
        </ErrorCardWrapper>
      </Wrapper>
    );
  }
}

export default ErrorPage;
