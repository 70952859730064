import { createLocalTracks } from "twilio-video";
import { Mixpanel } from "./mixpanelHelper";

/**
 * Gets the front and Rear Camera (Media Devices)
 * Which will be used to switch the camera view in mobile device.
 */
async function getMediaDevices() {
  const cameraDevices = {};
  if (navigator.mediaDevices) {
    await navigator.mediaDevices.enumerateDevices().then(devices => {
      devices.forEach(device => {
        if (device.kind === "videoinput") {
          if (device.label.toLowerCase().indexOf("front") > -1)
            cameraDevices.frontCamId = device.deviceId;
          else if (device.label.toLowerCase().indexOf("back") > -1)
            cameraDevices.rearCamId = device.deviceId;
          else cameraDevices.frontCamId = device.deviceId;
        }
      });
    });
  }

  return cameraDevices;
}

async function checkPermission() {
  let videoTrack, audioTrack;
  try {
    const tracks = await createLocalTracks({
      audio: true,
      video: { facingMode: "user" }
    });
    tracks.map(item => {
      if (item.kind === "video") {
        videoTrack = item;
        videoTrack.disable();
      } else if (item.kind === "audio") {
        audioTrack = item;
        audioTrack.disable();
      }
    });
  } catch (err) {
    Mixpanel.track(`Patient - Permission issue`, {
      Name: err.name,
      Message: err.message
    });
    return false;
  }
  return { videoTrack, audioTrack };
}

export { checkPermission, getMediaDevices };
