import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  margin-top: 24px;
  color: #151414;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
`;

const SubHeader = styled.div`
  margin-top: 8px;
  color: #868686;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const Image = styled.img`
  height: 143px;
  width: 260px;
`;

const Icon = styled.i`
  color: #868686;
  font-size: 48px;
`;

const Content = {
  error: {
    image: `${process.env.PUBLIC_URL}/img/messagesError.png`,
    header: 'Oops..Something went wrong',
    subHeader: 'Please wait while we are trying to fix the issue.'
  },
  noMessages: {
    image: 'message',
    header: 'No Messages',
    subHeader: 'Send a message to this patient'
  },
  noChats: {
    image: 'chat',
    header: 'No Chats',
    subHeader: 'Search patient to start a chat'
  },
  noSearchResult: {
    image: 'search',
    header: 'No Search Result',
    subHeader: 'Your search matches no patient'
  }
};

const NoMessage = props => (
  <Wrapper>
    <DataWrapper>
      {props.type === 'error' ? (
        <Image src={Content[props.type].image} alt='img' />
      ) : (
        <Icon className='material-icons'>{Content[props.type].image}</Icon>
      )}
      <Header>{Content[props.type].header}</Header>
      {/* <SubHeader>{Content[props.type].subHeader}</SubHeader> */}
    </DataWrapper>
  </Wrapper>
);

export default NoMessage;
