import React from "react";
import styled from "styled-components";
import createToast from "helpers/toastHelper";
import MoreOption from "./MoreOptions";
import ParticipantsList from "./ParticipantsList";

const Wrapper = styled.div`
  background-color: #fff;
  bottom: 0;
  position: absolute;
  z-index: 2;
  width: ${({ innerWidth }) => innerWidth}px;
  height: 95px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
`;

const ButtonWrapper = styled.div`
  padding: 10px 13px;
  border-radius: 50%;
  background: ${({ color }) => color};
  text-align: center;
  cursor: pointer;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ${ButtonWrapper} {
    :not(:last-child) {
      margin-right: 24px;
    }
  }
`;

const Icon = styled.i`
  font-size: 25px;
  margin-top: 2px;
  color: ${({ color }) => color};
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 32px;
  top: 0;
  margin-top: -43px;
`;

const ActionButton = ({
  isAudioEnabled,
  isVideoEnabled,
  endCallHandler,
  toggleVideo,
  toggleAudio,
  link,
  deviceType,
  isMessage,
  isFocusedView,
  changeLayout,
  messageComp,
  onCallParticipants,
  innerWidth,
  isAudioCall,
  switchCamera,
  isCamSwitchable,
  remove,
  flowType
}) => {
  function copyLink() {
    const copyElement = document.createElement("textarea");
    document.body.appendChild(copyElement);
    copyElement.value = link;
    copyElement.select();
    document.execCommand("copy");
    document.body.removeChild(copyElement);
    createToast({
      message: "invite link copied to clipboard",
      time: 5,
      appearance: "default"
    });
  }
  return (
    <Wrapper innerWidth={innerWidth}>
      <ActionWrapper>
        {deviceType !== "median" && (
          <ButtonWrapper onClick={copyLink} color={"#c0bfbf"}>
            <Icon color={"#181616"} className="material-icons">
              content_copy
            </Icon>
          </ButtonWrapper>
        )}
        {isCamSwitchable && (
          <ButtonWrapper onClick={switchCamera} color={"#c0bfbf"}>
            <Icon color={"#181616"} className="material-icons">
              flip_camera_ios
            </Icon>
          </ButtonWrapper>
        )}
        <ButtonWrapper onClick={toggleVideo} color={"#c0bfbf"}>
          <Icon color={"#181616"} className="material-icons">
            {isVideoEnabled && !isAudioCall ? "videocam" : "videocam_off"}
          </Icon>
        </ButtonWrapper>
        <ButtonWrapper onClick={toggleAudio} color={"#c0bfbf"}>
          <Icon color={"#181616"} className="material-icons">
            {isAudioEnabled ? "mic" : "mic_off"}
          </Icon>
        </ButtonWrapper>
        <ButtonWrapper onClick={endCallHandler} color={"#D93737"}>
          <Icon color={"#fff"} className="material-icons">
            call_end
          </Icon>
        </ButtonWrapper>
      </ActionWrapper>
      {window.innerWidth > 600 && (
        <Action>
          {isMessage && <>{messageComp}</>}
          <ParticipantsList
            onCallParticipants={onCallParticipants}
            remove={remove}
            flowType={flowType}
          />
          <MoreOption
            copyLink={copyLink}
            isFocusedView={isFocusedView}
            changeLayout={changeLayout}
          />
        </Action>
      )}
    </Wrapper>
  );
};

export default ActionButton;
