import Axios from "axios";
import history from "../history";

const URL = process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_AUTH_API`];
const headers = {};

const authApi = Axios.create({
  baseURL: URL,
  headers
});

authApi.interceptors.response.use(null, error => {
  const { status } = error.response;
  if (status === 401 || status === 403) {
    window.localStorage.clear();
    return history.push("/");
  }

  if (status === 428) {
    const redirectUrl = encodeURIComponent(
      `${history.location.pathname}${history.location.search}`
    );
    return history.push(`/accept-consent?redirect=${redirectUrl}`);
  }

  return Promise.reject(error);
});

export default authApi;
