import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Loader } from "packages/loader";
import { format } from "date-fns";
import ButtonDatashop from "@datashop/button";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import { default as CameraIcon } from "../../Icons/CameraTest.svg";
import { default as MicIcon } from "../../Icons/MicTest.svg";
import { default as MicOff } from "../../Icons/MicOff.svg";
import { default as CameraOff } from "../../Icons/CameraOff.svg";
import { default as AudioFile } from "../../Audio/audio_test.mp3";
import Lamp from "Icons/lamp.svg";
import Laptop from "Icons/laptop.svg";
import Recharge from "Icons/recharge.svg";
import Wifi from "Icons/wifi.svg";
import isMobile from "helpers/mobileCheck";

const TestWrapper = styled.div`
  border-radius: 6px;
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.56);
  width: min(90%, 960px);
  background-color: white;
  height: max-content;
  min-height: 480px;
  display: flex;
  position: relative;
  top: 72px;
  left: 50%;
  transform: translate(-50%, 0%);
  @media (max-width: 720px) {
    width: min(90%, 480px);
    height: max-content;
    max-height: 94%;
    top: 16px;
    overflow-y: auto;
  }
`;
const CameraMicTestWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.56);
  width: 480px;
  height: max-content;
  min-height: 480px;
  position: relative;
  top: 72px;
  left: 50%;
  transform: translate(-50%, 0%);
  overflow-y: auto;
  @media (max-width: 720px) {
    width: 90%;
    max-width: 480px;
    top: 16px;
    height: max-content;
    max-height: 94%;
    min-height: 280px;
  }
`;
const InfoWrapper = styled.div`
  flex-basis: 45%;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  min-width: 280px;
  @media (max-width: 720px) {
    flex-basis: 100%;
    min-width: 0;
  }
`;
const DetailContainer = styled.div`
  padding: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e5e5;
`;
const TextContainer = styled.div`
  padding: 36px 10%;
  height: min-content;
  @media (max-width: 720px) {
    padding: 16px;
  }
`;
const TestButton = styled(ButtonDatashop)`
  transform: translateX(-50%);
  left: 50%;
  margin-top: 32px;
  position: relative;
  border-radius: 6px;
  @media (max-width: 720px) {
    height: 48px;
  }
`;
const Details = styled.div`
  display: flex;
`;
const DetailText = styled.div`
  padding-left: min(15%, 32px);
  color: #2f2f2f;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 720px) {
    // font-size: 16px;
    // line-height: 22px;
    padding-left: 16px;
  }
`;
const DetailIconWrapper = styled.div`
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DetailIcon = styled.img`
  align-self: center;
`;
const VideoWrapper = styled.div`
  flex-basis: 55%;
  padding: 16px;
  flex-grow: 1;
  @media (max-width: 720px) {
    display: none;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fff;
  z-index: 99999;
  flex: 1;
  width: 100%;
  height: 100%;
`;
const IconButton = styled.button`
  border: none;
  background: none;
`;
const DoctorName = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;
const LoaderWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center
  font-size: 32px;
  svg{
    height: 16px;
  }
  @media (max-width: 720px) {
    margin-top: 0;
  }
`;
const WaitText = styled.div`
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.48px;
  line-height: 24px;
  text-align: center;
  margin-left: 8px;
  margin-top: 10px;
`;
const PaitentName = styled.div`
  color: #151414;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
`;
const VideoContainer = styled.div`
  position: relative;
  margin-top: 16px;
  video {
    display: ${props => (props.show ? "block" : "none")}
    margin: auto;
    position: relative;
    object-fit: cover;
    width: min(100%, 472px);
    aspect-ratio: 16/9;
    border-radius: 8px;
  }
`;
const Title = styled.div`
  color: #a6a6a6;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 18px;
`;
const Reason = styled.div`
  color: #151414;
  font-size: 20px;
  line-height: 32px;
  margin-top: 2px;
  text-align: center;
`;
const DateText = styled.p`
  color: #2f2f2f;
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;
const EditButton = styled(ButtonDatashop)`
  left: 50%;
  margin-top: 15px;
  position: relative;
  transform: translateX(-50%);
  border-radius: 6px;
  @media (max-width: 720px) {
    height: 48px;
  }
`;
const IconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`;
const Blackscreen = styled.div`
  display: ${props => (props.show ? "block" : "none")}
  position: relative;
  background-color: black;
  width: min(100%, 472px);
  aspect-ratio: 16/9;
  margin: auto;
  margin-top: 16px;
  border-radius: 8px;
`;
const HeadingWrapper = styled.div`
  padding: 16px;
  color: #151414;
  font-size: 20.02px;
  line-height: 32px;
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
`;
const BodyWrapper = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/img/visitRequest.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  margin-top: 50px;
  height: 100%;
  @media (max-width: 720px) {
    min-height: auto;
    margin-bottom: 16px;
    margin-top: 0;
    height: 100%;
  }
`;
const Button = styled(ButtonDatashop)`
  margin-right: 16px;
  border-radius: 6px;
  @media (max-width: 720px) {
    height: 48px;
  }
`;
const SpeakerCheckButton = styled(ButtonDatashop)`
  :last-of-type {
    margin-left: 10px;
  }
  @media (max-width: 720px) {
    padding: 8px 16px;
    height: 48px;
  }
`;
const YellowText = styled.div`
  color: #b78707;
`;
const TestButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  margin-bottom: 16px;
`;

const useStyles = makeStyles(theme => ({
  stepComplete: {
    color: "green !important"
  },
  stepLabel: {
    color: "#000000",
    fontSize: "16px",
    letterSpacing: "-0.48px",
    lineHeight: "24px"
  },
  activeStepLabel: {
    color: "#868686",
    fontSize: "16px",
    letterSpacing: "-0.48px",
    lineHeight: "24px",
    fontWeight: "bold !important"
  },
  stepActive: {
    color: "rgba(0, 0, 0, 0.38) !important"
  },
  labelIcon: {
    height: "25px"
  },
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

function getSteps() {
  return ["Network strength", "Test speaker", "Test microphone", "Test camera"];
}

const staticData = [
  {
    imagePath: Lamp,
    imageHeight: "36px",
    imageWidth: "27px",
    text:
      "Find a location that is safe, comfortable, private and well lit for your video visit."
  },
  {
    imagePath: Laptop,
    imageHeight: "30px",
    imageWidth: "40px",
    text: "Get a strong wifi or cellular data connection on your device."
  },
  {
    imagePath: Recharge,
    imageHeight: "24px",
    imageWidth: "38px",
    text: "Ensure that your smartphone or tablet is charged or plugged-in."
  },
  {
    imagePath: Wifi,
    imageHeight: "40px",
    imageWidth: "35px",
    text:
      "Clear any obstructions from the view of your camera. Keep the volume up."
  }
];

const PatientWaitingScreen = ({ name, date, toggleMedia = () => {} }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [testCamera, setTestCamera] = useState(true);
  const [testMic, setTestMic] = useState(true);
  const [testSoundError, setTestSoundError] = useState(false);
  const [testScreen, setTestScreen] = useState(false);
  const [networkLoading, setNetworkLoading] = useState(true);
  const [speakerError, setSpeakerError] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const videoRef = useRef(null);
  const steps = getSteps();
  const audio = new Audio(AudioFile);
  // const videoObj = useRef({});

  const toggleCamera = async () => {
    toggleMedia(!testCamera, testMic);
    setTestCamera(!testCamera);
    testCamera ? await getVideo(false) : await getVideo(true);
    setTimeout(() => setShowVideo(!showVideo), 1000);
  };
  const toggleMic = () => {
    toggleMedia(testCamera, !testMic);
    setTestMic(!testMic);
  };
  const toggleScreen = async () => {
    testScreen && (await getVideo(false));
    setTestScreen(!testScreen);
  };

  const getVideo = async show => {
    if (show) {
      window.localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { height: 1080, width: 1980 }
      });
      let video = videoRef.current;
      if (video) {
        video.srcObject = window.localStream;
        video.play();
      }
    } else {
      window.localStream.getTracks().forEach(track => track.stop());
    }
  };
  useEffect(() => {
    getVideo(true);
    return () => {
      window.localStream.getTracks() &&
        window.localStream.getTracks().forEach(track => track.stop());
    };
  }, []);

  const testNetwork = () => {
    setTimeout(() => {
      if (networkLoading) {
        if (window.navigator.onLine) {
          setNetworkLoading(false);
          setActiveStep(activeStep + 1);
        }
      }
    }, 3000);
  };
  const testAudio = () => {
    audio.play();
  };
  const startTestMic = () => {
    if (activeStep === 2) {
      setTimeout(() => {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function(stream) {
            if (activeStep === 2) {
              let audioContext = new AudioContext();
              let analyser = audioContext.createAnalyser();
              let microphone = audioContext.createMediaStreamSource(stream);
              let javascriptNode = audioContext.createScriptProcessor(
                2048,
                1,
                1
              );
              analyser.smoothingTimeConstant = 0.8;
              analyser.fftSize = 1024;
              microphone.connect(analyser);
              analyser.connect(javascriptNode);
              javascriptNode.connect(audioContext.destination);
              javascriptNode.onaudioprocess = function() {
                let array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                let values = 0;
                let length = array.length;
                for (let i = 0; i < length; i++) {
                  values += array[i];
                }
                let average = values / length;

                let allPids = document.getElementsByClassName("pid");
                allPids = [...allPids];
                let amoutOfPids = Math.round(average / 6);

                let elemRange = allPids.slice(0, amoutOfPids);

                for (let i = 0; i < allPids.length; i++) {
                  allPids[i].style.backgroundColor = "#e6e7e8";
                }

                if (elemRange.length < 3) {
                  for (let i = 0; i < elemRange.length; i++) {
                    allPids[i].style.backgroundColor = "#EB776C";
                  }
                  setTestSoundError(true);
                } else {
                  for (let i = 0; i < elemRange.length; i++) {
                    elemRange[i].style.backgroundColor = "#69ce2b";
                  }
                  setTimeout(() => {
                    setActiveStep(activeStep + 1);
                  }, 2000);
                }
              };
            } else {
              stream.getTracks().forEach(function(track) {
                track.stop();
              });
            }
          })
          .catch(function(err) {
            console.log(err);
          });
      }, 1500);
    }
  };
  const startTestVideo = () => {
    getVideo(true);
    setTimeout(() => {
      getVideo(false);
      setActiveStep(activeStep + 1);
    }, 4000);
  };

  useEffect(() => {
    if (testScreen) {
      switch (activeStep) {
        case 0:
          testNetwork();
          break;
        case 1:
          testAudio();
          break;
        case 2:
          startTestMic();
          break;
        case 3:
          startTestVideo();
          break;
      }
    }
  }, [activeStep, testScreen]);

  const resetAllTests = () => {
    setActiveStep(0);
    setSpeakerError(false);
    setNetworkLoading(true);
  };
  const closeTestScreen = async () => {
    setTestScreen(!testScreen);
    resetAllTests();
    if (audio) {
      audio.pause();
    }
    if (testCamera) {
      await getVideo(true);
    }
  };

  const renderScreen = () => {
    if (testScreen) {
      return (
        <CameraMicTestWrapper>
          <HeadingWrapper>Testing your hardware & network</HeadingWrapper>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step key="network">
                <StepLabel
                  StepIconProps={{
                    classes: {
                      completed: classes.stepComplete,
                      active: classes.stepActive
                    }
                  }}
                  classes={{
                    label: classes.stepLabel,
                    active: classes.activeStepLabel,
                    iconContainer: classes.labelIcon
                  }}
                >
                  Network strength
                </StepLabel>
                <StepContent>
                  <Typography>{networkLoading ? "Checking..." : ""}</Typography>
                </StepContent>
              </Step>
              <Step key="speaker-test">
                <StepLabel
                  StepIconProps={{
                    classes: {
                      completed: classes.stepComplete,
                      active: classes.stepActive
                    }
                  }}
                  classes={{
                    label: classes.stepLabel,
                    active: classes.activeStepLabel,
                    iconContainer: classes.labelIcon
                  }}
                >
                  Test speaker
                </StepLabel>
                <StepContent>
                  <div>
                    {!speakerError ? (
                      "Can you hear the sound played by system in the background?"
                    ) : (
                      <YellowText>
                        Try turning up your volume or change the source of
                        speaker. Can you hear now?
                      </YellowText>
                    )}
                    <div style={{ display: "flex", marginTop: "10px" }}>
                      <SpeakerCheckButton
                        appearance="primary"
                        onClick={() => setActiveStep(activeStep + 1)}
                      >
                        Yes
                      </SpeakerCheckButton>
                      <SpeakerCheckButton onClick={() => setSpeakerError(true)}>
                        No
                      </SpeakerCheckButton>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step key="microphone-test">
                <StepLabel
                  StepIconProps={{
                    classes: {
                      completed: classes.stepComplete,
                      active: classes.stepActive
                    }
                  }}
                  classes={{
                    label: classes.stepLabel,
                    active: classes.activeStepLabel,
                    iconContainer: classes.labelIcon
                  }}
                >
                  Test microphone
                </StepLabel>
                <StepContent>
                  <div>
                    {!testSoundError ? (
                      "Say something to test mic"
                    ) : (
                      <YellowText>
                        We can’t hear you, please speak louder.
                      </YellowText>
                    )}

                    <div className="pids-wrapper">
                      <div className="pid" style={{ height: "12px" }}></div>
                      <div className="pid" style={{ height: "16px" }}></div>
                      <div className="pid" style={{ height: "20px" }}></div>
                      <div className="pid" style={{ height: "24px" }}></div>
                      <div className="pid" style={{ height: "28px" }}></div>
                      <div className="pid" style={{ height: "32px" }}></div>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step key="camera-test">
                <StepLabel
                  StepIconProps={{
                    classes: {
                      completed: classes.stepComplete,
                      active: classes.stepActive
                    }
                  }}
                  classes={{
                    label: classes.stepLabel,
                    active: classes.activeStepLabel,
                    iconContainer: classes.labelIcon
                  }}
                >
                  Test camera
                </StepLabel>
                <StepContent>
                  <div>Your video will look like this to your provider.</div>
                  <video
                    id="video"
                    style={{
                      objectFit: "cover",
                      marginTop: "10px",
                      width: "min(90%, 290px)",
                      "aspect-ratio": 16 / 9
                    }}
                    ref={videoRef}
                  ></video>
                </StepContent>
              </Step>
            </Stepper>
          </div>
          <TestButtonWrapper>
            {activeStep === steps.length ? (
              <>
                <Button onClick={resetAllTests} appearance="primary">
                  Test again
                </Button>
                <Button onClick={closeTestScreen}>Close</Button>
              </>
            ) : (
              <Button onClick={closeTestScreen}>Cancel</Button>
            )}
          </TestButtonWrapper>
        </CameraMicTestWrapper>
      );
    } else {
      return (
        <TestWrapper>
          <InfoWrapper>
            <DetailContainer>
              <DoctorName>{name}</DoctorName>
              {date && (
                <DateText>{format(new Date(date), "LLLL d, P")}</DateText>
              )}
              <LoaderWrapper>
                <Loader stroke="#0070DD" strokeWidth="8" />
                <WaitText>Waiting for the provider to join</WaitText>
              </LoaderWrapper>
            </DetailContainer>
            <TextContainer>
              {staticData.map((steps, index) => {
                return (
                  <Details
                    key={index}
                    style={{ marginTop: index === 0 ? "0px" : "25px" }}
                  >
                    <DetailIconWrapper>
                      <DetailIcon
                        src={steps.imagePath}
                        width={steps.imageWidth}
                        height={steps.imageHeight}
                      />
                    </DetailIconWrapper>
                    <DetailText>{steps.text}</DetailText>
                  </Details>
                );
              })}
              <TestButton appearance="primary" onClick={toggleScreen}>
                Test mic, camera & connection
              </TestButton>
            </TextContainer>
          </InfoWrapper>
          {!isMobile() && (
            <VideoWrapper>
              <PaitentName>
                {`Hello ${localStorage.getItem("pName") || ""}`}
              </PaitentName>
              <VideoContainer show={showVideo}>
                <video id="video" ref={videoRef}></video>
                <Blackscreen show={!showVideo} />
                <IconWrapper>
                  <IconButton aria-label="camera toggle" onClick={toggleCamera}>
                    {testCamera ? (
                      <img src={CameraIcon} width="45" height="45" />
                    ) : (
                      <img src={CameraOff} width="45" height="45" />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="camera toggle"
                    style={{ marginLeft: "5px" }}
                    onClick={toggleMic}
                  >
                    {testMic ? (
                      <img src={MicIcon} width="45" height="45" />
                    ) : (
                      <img src={MicOff} width="45" height="45" />
                    )}
                  </IconButton>
                </IconWrapper>
              </VideoContainer>
              {localStorage.getItem("pReasonForVisit") ? (
                <>
                  <Title>What brings you here?</Title>
                  <Reason>“{localStorage.getItem("pReasonForVisit")}"</Reason>
                  <EditButton>Edit Details</EditButton>
                </>
              ) : null}
            </VideoWrapper>
          )}
        </TestWrapper>
      );
    }
  };
  return (
    <Wrapper>
      <BodyWrapper>{renderScreen()}</BodyWrapper>
    </Wrapper>
  );
};

export default PatientWaitingScreen;
