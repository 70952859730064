import React, { useRef } from "react";
import styled from "styled-components";
import { Loader } from "packages/loader";

const Footer = styled.div`
  display: flex;
  background-color: #fff;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e5e5;
`;

const InputTextArea = styled.textarea`
  background-color: #fff;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  padding: 0;
  height: 20px;
  max-height: 80px;
`;

const SendButton = styled.i`
  font-size: 20px;
  color: #ffff;
`;

const SendButtonContainer = styled.div`
  height: 40px;
  width: 40px;
  margin-left: 12px;
  cursor: ${props => (props.msg ? "pointer" : "not-allowed")};
  padding: 10px;
  background-color: #007aff;
  border-radius: 50%;
  pointer-events: ${props => (props.msg ? "auto" : "none")};
  opacity: ${props => (props.msg ? "1" : "0.5")};
`;

const LoaderWrapper = styled.div`
  height: 40px;
  width: 40px;
  margin-left: 12px;
  padding: 10px;
  background-color: #fff;
`;

const inputWrapperStyles = {
  display: "flex",
  padding: "10px 14px",
  backgroundColor: " #fff",
  border: "1px solid #e5e5e5",
  borderRadius: "24px",
  flex: 1
};

const MessagesFooter = props => {
  const inputRef = useRef();

  const handleResize = () => {
    inputRef.current.style.height = "auto";
    inputRef.current.style.height = inputRef.current.scrollHeight + "px";
  };

  return (
    <Footer>
      <div style={inputWrapperStyles}>
        <InputTextArea
          rows={1}
          onInput={handleResize}
          placeholder="Write a message"
          onChange={props.handleInputChange}
          onKeyDown={props.handleKeyDown}
          value={props.message}
          type="text"
          aria-multiline
          innerRef={inputRef}
        />
      </div>
      {props.sendingMessage ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <SendButtonContainer msg={props.message}>
          <SendButton className="material-icons" onClick={props.sendMessage}>
            send
          </SendButton>
        </SendButtonContainer>
      )}
    </Footer>
  );
};

export default MessagesFooter;
