import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@datashop/button";
import Heading from "@datashop/heading";
import Text from "@datashop/text";

const HeaderText = styled.div`
  color: #868686;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
  width: 372px;
`;

const DialogText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 20px 0;
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  cursor: pointer;
`;

const ButtonIcon = styled.i`
  font-size: 16px;
  color: ${({ color }) => color && color};
  margin-right: 16px;
`;

const ContainerWrapper = styled.div`
  position: absolute;
  z-index: 5;
  bottom: 0;
  width: ${({ innerWidth }) => innerWidth}px;
  display: flex;
  flex-direction: column;
  padding: 28px 16px 0;
  border-radius: 20px 20px 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 20px 0;

  ${Button} {
    color: #000;
    font-weight: 500;

    :first-child {
      margin-right: 8px;
      color: ${({ disabled }) => (disabled ? "#000" : "#fff")};
    }
  }
`;
const SpaceBetween = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
const ButtonWrapperComplete = styled.div``;

const FootEndCallModal = ({
  changeStatusToReady,
  canChangeStatus,
  close,
  userType,
  endCallHandler,
  leaveVisit,
  visitId,
  innerWidth,
  incompleteHandler,
  skipHandler,
  askForIncomplete
}) => {
  const [checked, setChecked] = useState(1);
  const [step, setStep] = useState(1);
  const [providerStatus, setProviderStatus] = useState(0);
  const [isStatusChanged, setStatusChanged] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("statusChanged") &&
      localStorage.getItem("statusChanged") === visitId
    )
      setStatusChanged(true);
  }, [visitId]);

  function changeStatus() {
    setChecked(2);
    changeStatusToReady();
    localStorage.setItem("statusChanged", visitId);
  }

  const changeStep = step => {
    setProviderStatus(step);
    setTimeout(() => {
      setStep(2);
      if (step === 2) {
        changeStatus(step);
      }
    }, 2000);
  };

  return askForIncomplete ? (
    <ContainerWrapper innerWidth={innerWidth}>
      <Text
        style={{
          marginBottom: "12px",
          color: "#919191"
        }}
      >
        We detected an abrupt ending to your call. Would you like to mark it as
        incomplete?
      </Text>
      <ButtonWrapperComplete>
        <DialogText color="#000000" onClick={incompleteHandler}>
          <ButtonIcon className="material-icons">error_outline</ButtonIcon>
          Mark incomplete
        </DialogText>
        <DialogText color="#000000" onClick={skipHandler}>
          <ButtonIcon className="material-icons">skip_next</ButtonIcon>
          Skip
        </DialogText>
      </ButtonWrapperComplete>
    </ContainerWrapper>
  ) : (
    <ContainerWrapper innerWidth={innerWidth}>
      <HeaderText>
        {userType === "STAFF" && !isStatusChanged && canChangeStatus
          ? "Please update the status before leaving"
          : "Yes, leave this visit"}
      </HeaderText>
      {userType === "STAFF" && canChangeStatus && !isStatusChanged && (
        <ButtonWrapper disabled={checked === 2}>
          <Button
            disabled={checked === 2}
            appearance={checked === 1 ? "primary" : "secondary"}
            dimension="large"
          >
            Waiting Queue
          </Button>
          <Button
            appearance={checked === 2 ? "primary" : "secondary"}
            onClick={changeStatus}
            dimension="large"
          >
            Ready for provider
          </Button>
        </ButtonWrapper>
      )}
      {userType === "PROVIDER" &&
      !isStatusChanged &&
      canChangeStatus &&
      step === 1 ? (
        <>
          <DialogText color="#000000" onClick={() => changeStep(1)}>
            <ButtonIcon className="material-icons">timer</ButtonIcon>
            Update to Post visit queue
            {providerStatus === 1 && (
              <SpaceBetween>
                <ButtonIcon className="material-icons" color="#2EA843">
                  check_circle
                </ButtonIcon>
              </SpaceBetween>
            )}
          </DialogText>
          <DialogText color="#000000" onClick={() => changeStep(2)}>
            <ButtonIcon className="material-icons">timelapse</ButtonIcon>
            No, continue without updating
            {providerStatus === 2 && (
              <SpaceBetween>
                <ButtonIcon className="material-icons" color="#2EA843">
                  check_circle
                </ButtonIcon>
              </SpaceBetween>
            )}
          </DialogText>
        </>
      ) : (
        <>
          {userType !== "VISITOR" && (
            <DialogText color="#D93737" onClick={endCallHandler}>
              <ButtonIcon className="material-icons">call_end</ButtonIcon>
              End visit for all
            </DialogText>
          )}
          {userType !== "PATIENT" && (
            <DialogText
              color="#000000"
              onClick={() => leaveVisit(checked === 1)}
            >
              <ButtonIcon className="material-icons">call_end</ButtonIcon>
              Leave
            </DialogText>
          )}
          <DialogText
            color="#000000"
            onClick={() => close(providerStatus === 1)}
          >
            <ButtonIcon className="material-icons">close</ButtonIcon>
            No, go back
          </DialogText>
        </>
      )}
    </ContainerWrapper>
  );
};

export default FootEndCallModal;
