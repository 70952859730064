import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import VisitScheduleSuccess from "./VisitScheduleSuccess";
import VisitRequestForm from "./VisitRequestForm";
import createToast from "helpers/toastHelper";
import providerApi from "helpers/providerApi";
import mobileCheck from "helpers/mobileCheck";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => (mobileCheck() ? "#f4f4f4" : "#fff")};
  z-index: 2;
  max-height: ${props => (mobileCheck() ? "100%" : "700px")};
  overflow-y: auto;
`;

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const VisitRequestModal = ({
  organization_id,
  history,
  practice_id,
  setIsOpen,
  selectedProvider,
  personalDetails,
  setPersonalDetails
}) => {
  const [showDobPicker, toggleShowDobPicker] = useState(false);
  const [showDatePicker, toggleShowDatePicker] = useState(false);
  const [visitRequestSuccess, toggleVisitRequestSuccess] = useState(false);
  const [requesting, toggleRequesting] = useState(false);
  const {
    firstName,
    phoneNumber,
    lastName,
    dob,
    email,
    gender,
    date,
    time,
    reasonForVisit
  } = personalDetails;

  const onChangeHandler = event => {
    const { name, value } = event.target;
    setPersonalDetails({
      ...personalDetails,
      [name]: value
    });
  };

  const resetForm = () => {
    setPersonalDetails({
      firstName: "",
      phoneNumber: "",
      lastName: "",
      dob: "",
      email: "",
      gender: "",
      date: "",
      time: "",
      reasonForVisit: ""
    });
  };

  const handleDobFocusChange = () => {
    toggleShowDobPicker(!showDobPicker);
  };

  const handleDateFocusChange = () => {
    toggleShowDatePicker(!showDatePicker);
  };

  const onDateChange = date => {
    setPersonalDetails({
      ...personalDetails,
      date
    });
    handleDateFocusChange();
  };

  const onDobChange = dob => {
    setPersonalDetails({
      ...personalDetails,
      dob: dob
    });
    handleDobFocusChange();
  };

  const sendRequest = event => {
    event.preventDefault();
    toggleRequesting(true);
    localStorage.setItem("pReasonForVisit", reasonForVisit);

    const payload = {
      patientInfo: {
        firstName,
        lastName,
        dob,
        phoneNo: phoneNumber.replace(/\D/g, ""),
        gender
      },
      organization: organization_id
    };

    if (date.trim() && time.trim()) {
      const dateTime = moment(date + " " + time, "MM/DD/YYYY hh:mm A");
      payload.dateString = dateTime.format("Do MMM YYYY");
      payload.scheduledOn = dateTime.valueOf();
    }

    if (practice_id) {
      payload.practice = practice_id;
    }

    if (selectedProvider) {
      payload.providerPreference = selectedProvider.value;
    }

    if (reasonForVisit) {
      payload.reasonForVisit = reasonForVisit;
    }

    providerApi
      .post("innote-survey/telehealth/patient/request_visit", payload)
      .then(() => {
        toggleRequesting(false);
        resetForm();
        toggleVisitRequestSuccess(true);
      })
      .catch(() => {
        toggleRequesting(false);
        createToast({
          title: "Visit request failed",
          message:
            "Try to request again. If it continues to fail, please contact your clinic"
        });
      });
  };

  return (
    <Wrapper>
      {visitRequestSuccess ? (
        <VisitScheduleSuccess
          toggleVisitRequestSuccess={toggleVisitRequestSuccess}
          history={history}
        />
      ) : (
        <VisitRequestForm
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          dob={dob}
          email={email}
          gender={gender}
          date={date}
          time={time}
          reasonForVisit={reasonForVisit}
          showDobPicker={showDobPicker}
          showDatePicker={showDatePicker}
          onChangeHandler={onChangeHandler}
          handleDobFocusChange={handleDobFocusChange}
          handleDateFocusChange={handleDateFocusChange}
          onDobChange={onDobChange}
          onDateChange={onDateChange}
          sendRequest={sendRequest}
          validateEmail={validateEmail}
          requesting={requesting}
          history={history}
          setIsOpen={setIsOpen}
          selectedProvider={selectedProvider}
        />
      )}
    </Wrapper>
  );
};

export default VisitRequestModal;
