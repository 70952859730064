import React, { Fragment, Component } from "react";
import Toast from "@datashop/toast";

const AxiosErrorToast = ({
  title,
  message,
  appearance,
  close,
  button: { text, function: onClick }
}) => {
  return (
    <Fragment>
      <Toast
        title={title}
        message={message}
        appearance={appearance}
        onDismissClick={close}
      >
        {text && (
          <Toast.Button
            onClick={() => {
              onClick();
              close();
            }}
          >
            {text}
          </Toast.Button>
        )}
      </Toast>
    </Fragment>
  );
};

export default AxiosErrorToast;
