import React, { useRef, useEffect } from "react";
import { isEmpty, capitalize } from "lodash-es";
import styled from "styled-components";
import { getLetterIconColor } from "helpers/badgeColor";
import avatar from "@datashop/avatar";
import Text from "@datashop/text";
import Icon from "@datashop/icon";
import MoreOption from "./MoreOptions";

const Participant = styled.div`
   display: ${({ isPinned }) => (isPinned ? "none" : "flex")};
   justify-content: center;
   align-items: center;
   background: #2f2f2f;
   max-width: 498px;
   position: relative;
   width: calc(calc(100% - 32px) / ${({ itemCount }) => itemCount}); 
   height: 100%; 
   margin-left: 4px;
   margin-right: 4px;
   
   ${({ isMainView, itemCount, isFocusedView, isVideoEnable }) =>
     isMainView
       ? `width: 100%; height: calc(100% - 144px);`
       : `
      ${
        isFocusedView
          ? "width: 204px; min-width: 204px; border-radius: 4px; height: 136px; margin-right: 8px; margin-bottom: 8px;"
          : `width: calc(calc(100% - 32px) / ${itemCount});
`
      }
   `}

   ${({ isVideoEnable }) => !isVideoEnable && "margin-right: 8px;"}
   
   :first-child {
      margin-left: 0;
   }
   :last-child {
      margin-right: 0;
   }

   video {
      display: ${({ isVideoEnable }) => (isVideoEnable ? "block" : "none")}
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
`;

const AvatarWrapper = styled(avatar)`
  position: absolute;
  ${({ isFocusedView, isSmall }) =>
    isFocusedView || isSmall
      ? "width: 40px; height: 40px;"
      : "width: 150px; height: 150px; font-size: xxx-large;"}
`;

const TextWrapper = styled.div`
  display: flex;
  z-index: 2;
  background-color: #e5e5e5;
  position: absolute;
  border-radius: 3px;
  height: 20px;
  padding: 2px 4px;
  ${({ isFocusedView }) =>
    isFocusedView ? "bottom: 8px; left: 4px;" : "bottom: 8px; left: 8px;"}
`;

const Options = styled.div`
  z-index: 2;
  position: absolute;
  ${({ isFocusedView, isMainView }) =>
    isFocusedView
      ? "top: 4px; right: 8px;"
      : `${isMainView ? "top: 8px;" : "top: 8px;"} right: 8px;`}
`;

const SelfVideoWrapper = styled.div`
   display: flex;
   bottom: 16px;
   left: 16px;
   position: absolute;
   z-index: 2;
   ${({ endCallModalType }) =>
     endCallModalType === "foot"
       ? "height: 80px; width: 40%; padding-top: 10.25%"
       : "height: 120px; width: 25%; padding-top: 17.25%"}
   visibility: visible;
   border-radius: 4px
   background: #2f2f2f;
   visibility: ${({ isVideoEnable }) => (isVideoEnable ? "visible" : "hidden")};
   div {
      background: #2f2f2f;
      video {
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         border-radius: 4px;
         object-fit: cover;
      }
   }
`;

const ParticipantVideoWrapper = styled.div`
   position: fixed;
   z-index: 1;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   height: ${({ innerHeight }) => innerHeight - 96}px;
   background: #2f2f2f;
   div {
    ${({ isVideoEnable }) => isVideoEnable && `height:100%;`}
      video {
        display: ${({ isVideoEnable }) => (isVideoEnable ? "block" : "none")}
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
`;

const ParticipantView = ({
  el: { id, pData, participant, element, isAudio, isVideo },
  itemCount,
  changeLayout,
  isPinned,
  isFocusedView,
  isMainView,
  isAudioCall,
  rowCount,
  endCallModalType,
  isViewFroTwo,
  innerHeight,
  remove,
  mute,
  unmute,
  flowType
}) => {
  const videoRef = useRef();
  const [firstName, lastName] = pData.name.split(" ");

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.append(element);
    }
  }, [element, videoRef]);

  const pinVideo = () => {
    changeLayout(id);
  };

  const removeParticipant = () => {
    remove(pData.twilioParticipantSid);
  };

  const muteParticipant = () => {
    mute();
  };

  const unMuteParticipant = () => {
    unmute();
  };

  if (isViewFroTwo) {
    if (pData && pData.isLocal) {
      return (
        <SelfVideoWrapper
          isVideoEnable={isAudioCall ? false : isVideo}
          endCallModalType={endCallModalType}
        >
          <div ref={videoRef}></div>
        </SelfVideoWrapper>
      );
    } else {
      return (
        <ParticipantVideoWrapper
          innerHeight={innerHeight}
          isVideoEnable={isAudioCall ? false : isVideo}
        >
          <div ref={videoRef}></div>
          {(isAudioCall || !isVideo) && (
            <AvatarWrapper
              text={[firstName, lastName]
                .map((el, i) => (i <= 1 && el ? el[0] : ""))
                .join("")
                .trimRight()
                .toUpperCase()}
              backgroundColor={getLetterIconColor(firstName, lastName)}
            />
          )}
        </ParticipantVideoWrapper>
      );
    }
  }
  return (
    !isEmpty(participant) && (
      <Participant
        itemCount={itemCount}
        isVideoEnable={isAudioCall ? false : isVideo}
        isFocusedView={isFocusedView}
        isMainView={isMainView}
        isAudioCall={isAudioCall}
        isPinned={isPinned}
      >
        <div style={{ height: "100%", flex: "1" }} ref={videoRef} />
        {(isAudioCall || !isVideo) && (
          <AvatarWrapper
            text={[firstName, lastName]
              .map((el, i) => (i < 1 ? el[0] : ""))
              .join("")
              .trimRight()
              .toUpperCase()}
            backgroundColor={getLetterIconColor(firstName, lastName)}
            isFocusedView={isFocusedView}
            isSmall={rowCount >= 3 && endCallModalType === "foot"}
          />
        )}
        {!isAudioCall && (
          <Options isFocusedView={isFocusedView} isMainView={isMainView}>
            <MoreOption
              isLocal={pData.isLocal}
              flowType={flowType}
              unPinVideo={changeLayout}
              pinVideo={pinVideo}
              muteParticipant={muteParticipant}
              unMuteParticipant={unMuteParticipant}
              isAudio={isAudio}
              isTiny={true}
              isMainView={isMainView}
              remove={removeParticipant}
            ></MoreOption>
          </Options>
        )}
        <TextWrapper isFocusedView={isFocusedView}>
          {!isAudio && (
            <Icon
              name="mic_off"
              style={{
                color: "#D93737",
                fontSize: "16px",
                marginRight: "4px"
              }}
            />
          )}
          <Text>{`${pData.name} ${
            pData.type === "PATIENT" ? `(${capitalize(pData.type)})` : ""
          }`}</Text>
        </TextWrapper>
      </Participant>
    )
  );
};

export default ParticipantView;
