import React, { useEffect } from "react";
import styled from "styled-components";
import Text from "@datashop/text";
import Heading from "@datashop/heading";
import Button from "@datashop/button";
import { Mixpanel } from "helpers/mixpanelHelper";

const Content = [
  {
    image: `${process.env.PUBLIC_URL}/img/chrome_128x128.png`,
    subHeader: "Chrome"
  },
  {
    image: `${process.env.PUBLIC_URL}/img/safari_128x128.png`,
    subHeader: "Safari"
  },
  {
    image: `${process.env.PUBLIC_URL}/img/firefox_128x128.png`,
    subHeader: "Firefox"
  }
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const HeadingWrapper = styled(Heading)`
  color: #151414;
  padding: 0 4px;
`;

const TextWrapper = styled(Text)`
  color: #868686;
  padding: 0 16px;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
`;

const IconArea = styled.div`
  display: flex;
  justify-content: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 40px;
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const NotSupported = ({ onClick }) => {
  useEffect(() => {
    Mixpanel.track("Detected unsupported browser", {
      Category: "InNote Provider - Browser"
    });
  }, []);
  return (
    <Wrapper>
      <HeadingWrapper as="h2" withMargin>
        It’s not you – it’s your browser
      </HeadingWrapper>
      <TextWrapper fontSize="large" fontWeight="extraLight" withMargin>
        Looks like you’re using a browser that we don’t support. For a seamless
        experience, please use the latest version of the browsers listed below.
      </TextWrapper>
      <IconArea>
        {Content.map((el, index) => (
          <ImageWrapper key={index}>
            <Image src={el.image} alt={el.subHeader} />
            <Text>{el.subHeader}</Text>
          </ImageWrapper>
        ))}
      </IconArea>
      <Button dimension="small" appearance="secondary" onClick={onClick}>
        Continue anyway
      </Button>
    </Wrapper>
  );
};

export default NotSupported;
