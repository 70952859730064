import React, { Component } from "react";
import styled from "styled-components";
import Verify from "./Verify";
import Login from "./Login";
import { Loader } from "packages/loader";
import createToast from "helpers/toastHelper";
import authApi from "../../helpers/authApi";
import mobileCheck from "../../helpers/mobileCheck";

// Import Context
import AppContext from "context/app.context";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  position: fixed;
  height: 32px;
  margin: auto;
`;

export const LogoWrapper = styled.div`
  margin: 24px 0 32px;
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  height: 45px;
`;

const BodyWrapper = styled.div`
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 400px;
  height: 100%;
  background-color: ${props => (mobileCheck() ? "#f4f4f4" : "#fff")};
`;

class Signup extends Component {
  static contextType = AppContext;

  state = {
    status: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    dob: "",
    gender: "",
    registering: false,
    editDetails: false,
    otp: {
      first: "",
      second: "",
      third: "",
      fourth: ""
    },
    verifying: false,
    resendOtpLoading: false,
    isLoading:
      this.props.location.state && !!this.props.location.state.inviteId,
    formErrors: {
      firstName: false,
      lastName: false,
      dob: false,
      gender: false,
      phoneNumber: false,
      nonFieldError: false
    }
  };

  isRegistered = () => {
    return this.state.status;
  };

  componentDidMount() {
    if (localStorage.getItem("token") && localStorage.getItem("refreshToken")) {
      this.props.history.push(this.props.baseUrl);
    }
  }

  handlePaste = field => {
    const { formErrors } = this.state;
    if (formErrors[field]) {
      this.setState({
        formErrors: {
          ...formErrors,
          field: false
        }
      });
    }
  };

  resetDetails = () => {
    this.setState({ email: null });
  };

  setDetails = (fieldName, value) => {
    let { maskedPhoneNumber = "", formErrors = {} } = this.state;
    let newFormErrors = { ...formErrors };

    if (fieldName === "phoneNumber") {
      maskedPhoneNumber = value;
      value = value.replace(/\D/g, "");
    }
    if (value === "" && formErrors[fieldName]) {
      newFormErrors[fieldName] = false;
    }
    if (formErrors[fieldName] && value) {
      newFormErrors[fieldName] = false;
    }
    this.setState({
      [fieldName]: value,
      maskedPhoneNumber,
      formErrors: newFormErrors ? newFormErrors : formErrors
    });
  };

  handleOtpInput = (event, autofocusId) => {
    event.preventDefault();
    const {
      target: { value, name }
    } = event;

    if (name === "fourth") {
      if (value.length < 1 || value.length === 1) {
        this.setState({
          otp: {
            ...this.state.otp,
            [name]: value
          }
        });
      }
    } else {
      this.setState({
        otp: {
          ...this.state.otp,
          [name]: value
        }
      });
    }
    value && autofocusId && document.getElementById(autofocusId).focus();
  };

  onKeyDownHandler = (event, autofocusId) => {
    const {
      keyCode,
      target: { value }
    } = event;
    if (keyCode === 8 && autofocusId && value === "")
      document.getElementById(autofocusId).focus();
  };

  onFocus = event => {
    const {
      target: { name }
    } = event;
    this.setState({
      otp: {
        ...this.state.otp,
        [name]: ""
      }
    });
  };

  onRegister = () => {
    const {
      firstName,
      lastName,
      dob,
      gender,
      phoneNumber,
      formErrors
    } = this.state;
    this.setState({ registering: true });
    authApi
      .post("/users/register_vfd_patient/", {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        phoneNumber,
        dob,
        gender: gender.toUpperCase()
      })
      .then(data => {
        const {
          firstName,
          lastName,
          status,
          phoneNumber,
          dob,
          // TODO: Commenting out gender since it's coming as null from the backend. Retaining the original value selected
          // gender,
          userId
        } = data.data.userDetails;
        localStorage.setItem("token", data.data.accessToken);
        localStorage.setItem("userId", userId);
        this.setState(prevState => ({
          name: `${firstName} ${lastName}`,
          status,
          phoneNumber,
          dob,
          gender: prevState.gender,
          registering: false,
          editDetails: false,
          formErrors: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            dob: "",
            gender: ""
          }
        }));
      })
      .catch(error => {
        const {
          response: { data }
        } = error;
        const errFields = Object.keys(data);
        const newFormErrors = { ...formErrors };
        errFields.forEach(field => {
          newFormErrors[field] = data[field][0];
        });

        this.setState(
          {
            registering: false,
            formErrors: newFormErrors
          },
          () => {
            createToast({ title: "Request Failed. Try Again." });
          }
        );
      });
  };

  handleEditDetails = () => {
    this.setState({ editDetails: true });
  };

  onVerify = () => {
    const { context, setContext } = this.context;
    const { otp } = this.state;
    const otpValue = `${otp.first}${otp.second}${otp.third}${otp.fourth}`;
    this.setState({ verifying: true });
    authApi.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      "token"
    )}`;
    authApi
      .post("/auth/login_otp/verify", { otp: otpValue })
      .then(response => {
        const {
          firstName,
          lastName,
          phoneNumber,
          dob,
          userId,
          status,
          hasAgreedTerms
        } = response.data.userDetails;
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("userId", response.data.userDetails.userId);
        localStorage.setItem("isLoggedIn", true);

        setContext({
          ...context,
          isLoggedIn: localStorage.getItem("isLoggedIn"),
          userDetails: response.data.userDetails
        });

        this.setState(
          {
            firstName,
            lastName,
            phoneNumber,
            dob,
            userId,
            status,
            verifying: false
          },
          () => {
            this.props.history.push(!hasAgreedTerms ? "/accept-consent" : "/");
          }
        );
      })
      .catch(error => {
        const {
          response: { data = {} }
        } = error;
        const { otp = [] } = data;

        this.setState(
          {
            verifying: false
          },
          () => {
            createToast({
              title: otp.length > 0 ? otp[0] : "Request Failed. Try Again."
            });
          }
        );
      });
  };

  onResendOtp = handleResendOtpSuccess => {
    this.setState({ resendOtpLoading: true });
    authApi.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      "token"
    )}`;
    authApi
      .get("/auth/resend_login_otp")
      .then(data => {
        this.setState({
          resendOtpLoading: false,
          otp: {
            first: "",
            second: "",
            third: "",
            fourth: ""
          }
        });
        createToast({
          title: "OTP successfully sent",
          appearance: "success"
        });
        handleResendOtpSuccess();
      })
      .catch(errro => {
        this.setState({ resendOtpLoading: false });
        createToast({
          title: "Request Failed. Try Again."
        });
      });
  };

  getOptions = () => {
    const {
      firstName,
      lastName,
      dob,
      gender,
      phoneNumber,
      maskedPhoneNumber = "",
      registering,
      editDetails,
      otp,
      verifying,
      resendOtpLoading,
      formErrors
    } = this.state;
    const options = {
      setDetails: this.setDetails,
      onRegister: this.onRegister,
      onVerify: this.onVerify,
      handleEditDetails: this.handleEditDetails,
      handleOtpInput: this.handleOtpInput,
      onKeyDownHandler: this.onKeyDownHandler,
      onFocus: this.onFocus,
      onResendOtp: this.onResendOtp,
      phoneNumber,
      maskedPhoneNumber,
      dob,
      gender,
      firstName,
      lastName,
      registering,
      editDetails,
      otp,
      verifying,
      resendOtpLoading,
      formErrors
    };
    return options;
  };

  render() {
    const { registering, editDetails } = this.state;
    const { details = {}, practiceDetails = null, baseUrl } = this.props;
    const options = this.getOptions();
    const goToVerify = !registering && this.isRegistered() && !editDetails;

    return this.state.isLoading ? (
      <LoaderWrapper data-test="loader-wrapper">
        <Loader data-test="loader-wrapper__loader" />
      </LoaderWrapper>
    ) : (
      <Wrapper data-test="wrapper">
        {mobileCheck() ? (
          <LogoWrapper data-test="wrapper__logo-wrapper">
            <Logo
              data-test="wrapper__logo-wrapper__logo"
              src={practiceDetails ? practiceDetails.logoUrl : details.logoUrl}
              alt="Innovaccer"
            />
          </LogoWrapper>
        ) : null}
        <BodyWrapper data-test="wrapper__body-wrapper">
          {goToVerify ? (
            <Verify {...options} data-test="wrapper__verify" />
          ) : (
            <Login {...options} data-test="wrapper__login" />
          )}
        </BodyWrapper>
      </Wrapper>
    );
  }
}

export default Signup;
