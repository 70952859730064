import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Icon from "@datashop/icon";
import Button from "@datashop/button";

const Wrapper = styled.div`
  width: ${({ innerWidth }) => innerWidth - 32}px;
  max-width: ${({ innerWidth }) => innerWidth - 32}px;
  position: relative;
`;

const Carousel = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    height: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isLeftAlign }) =>
    isLeftAlign ? "flex-start" : "center"};
  align-items: center;
  margin: auto;
  box-sizing: border-box;
`;

const ButtonWrapper = styled(Button)`
  position: absolute;
  ${({ isLeft, isLeftHide, isRightHide }) =>
    isLeft
      ? `
         top: 50%;
         left: 0;
         transform: translate(50%, -50%);
         ${isLeftHide ? "display: none" : "display: flex"};
         `
      : `
         top: 50%;
         right: 0;
         transform: translate(-50%, -50%);
         ${isRightHide ? "display: none" : "display: flex"};
         `}
`;
const IconWrapper = styled(Icon)`
  color: #fff;
  font-size: 16px;
`;

const Ribbon = ({ gap = 8, innerWidth, children }) => {
  const [isLeftHide, setIsLeftHide] = useState(false);
  const [isRightHide, setIsRightHide] = useState(false);
  const [width, setWidth] = useState(0);

  const carousel = useRef(null);
  const content = useRef(null);

  useEffect(() => {
    if (carousel.current) {
      const wi = carousel.current.offsetWidth;
      if (carousel.current.scrollLeft <= 0) {
        setIsLeftHide(true);
      }
      if (
        carousel.current.scrollLeft + carousel.current.offsetWidth >=
        carousel.current.scrollWidth
      ) {
        setIsRightHide(true);
      }

      setWidth(wi);
    }
  }, [innerWidth]);

  const goBack = () => {
    if (carousel.current && content.current) {
      carousel.current.scrollBy(-(width + gap), 0);
      if (carousel.current.scrollLeft - width - gap <= 0) {
        setIsLeftHide(true);
      }
      if (
        !content.current.scrollWidth - width - gap <=
        carousel.current.scrollLeft + width
      ) {
        setIsRightHide(false);
      }
    }
  };
  const goForward = () => {
    if (carousel.current && content.current) {
      carousel.current.scrollBy(width + gap, 0);
      if (carousel.current.scrollWidth !== 0) {
        setIsLeftHide(false);
      }
      if (
        content.current.scrollWidth - width - gap <=
        carousel.current.scrollLeft + width
      ) {
        setIsRightHide(true);
      }
    }
  };
  return (
    <Wrapper innerWidth={innerWidth}>
      <Carousel innerRef={carousel}>
        <Content innerRef={content} isLeftAlign={!(isRightHide && isLeftHide)}>
          {children}
          <ButtonWrapper
            dimension="tiny"
            appearance="transparent"
            isLeft={true}
            onClick={goBack}
            isLeftHide={isLeftHide}
          >
            <IconWrapper name="keyboard_arrow_left" />
          </ButtonWrapper>
          <ButtonWrapper
            dimension="tiny"
            appearance="transparent"
            isLeft={false}
            onClick={goForward}
            isRightHide={isRightHide}
          >
            <IconWrapper name="keyboard_arrow_right" />
          </ButtonWrapper>
        </Content>
      </Carousel>
    </Wrapper>
  );
};

export default Ribbon;
