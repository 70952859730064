import {
  REQUEST_SEND_EVALUATE_PATIENT,
  SEND_EVALUATE_PATIENT_FAILURE,
  SEND_EVALUATE_PATIENT_SUCCESS
} from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  status: null
};

function submitResponse(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SEND_EVALUATE_PATIENT:
      return { ...initialState, isLoading: true };
    case SEND_EVALUATE_PATIENT_SUCCESS:
      return {
        ...state,
        status: action.data.status,
        isLoading: false
      };
    case SEND_EVALUATE_PATIENT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    default:
      return state;
  }
}

export default submitResponse;
