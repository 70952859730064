import React, { Fragment, Component } from "react";
import styled from "styled-components";
import StyledButton from "@datashop/button";
import StyledInput from "@datashop/input";
import DatePicker from "@datashop/date-picker";
import Radio from "@datashop/radio";
import PhoneNumberInput from "../../helpers/phoneInput";
import { Loader } from "./../../packages/loader/";
import Text from "@datashop/text";
import mobileCheck from "../../helpers/mobileCheck";

const Form = styled.form`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;

  i {
    margin-top: 4px;
  }
  input {
    height: 40px;
  }
`;

const WelcomeText = styled.p`
  color: #151414;
  font-size: 32px;
  text-align: left;
  margin-bottom: 4px;
`;

const FormInfo = styled.p`
  color: #868686;
  font-size: 16px;
  width: 100%;
`;

const Label = styled.p`
  margin: 24px 0 4px;
  color: #2f2f2f;
  font-weight: 600;
  line-height: 16px;
`;

const ErrorMessage = styled(Text)`
  color: red;
  padding-top: 4px;
`;

const Button = styled(StyledButton)`
  margin-top: 40px;
  height: 40px;
  font-weight: 300;
`;

const Input = styled(StyledInput)`
  height: 40px;
  margin: 0;
`;

export const FormBody = styled.div`
  flex: 7;
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  background-color: ${props => (mobileCheck() ? "#f4f4f4" : "#fff")};
`;

const LoginWrappper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const GenderOptions = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  > div {
    label {
      margin-top: 8px;
    }
  }
`;

const StyledDatePicker = styled.div`
  > div {
    > div:nth-child(2) {
      width: auto;
    }
  }
  i {
    width: 16px;
  }
  input {
    height: 40px;
  }
`;

export default class Login extends Component {
  state = {
    showDatePicker: false,
    genders: [
      {
        name: "gender",
        value: "male",
        label: "Male",
        checked: false
      },
      {
        name: "gender",
        value: "female",
        label: "Female",
        checked: false
      },
      {
        name: "gender",
        value: "other",
        label: "Other"
      }
    ]
  };
  componentWillMount() {}

  handleDateChange = date => {
    this.props.setDetails("dob", date);
    this.setState({ showDatePicker: false });
  };

  handleFocusChange = showDatePicker => {
    this.setState({ showDatePicker });
  };

  handleInputChange = (key, event) => {
    let value = event.target.value;
    this.props.setDetails(key, value);
  };

  handleFormSubmit = e => {
    e.preventDefault();
    localStorage.setItem("pName", this.props.firstName);
    this.props.onRegister();
  };

  render() {
    const {
      firstName,
      lastName,
      phoneNumber,
      dob,
      gender,
      registering,
      formErrors = {}
    } = this.props;
    return (
      <LoginWrappper data-test="login__login-wrappper">
        <WelcomeText data-test="login__login-wrappper__welcome-text">
          Welcome!
        </WelcomeText>
        <FormInfo data-test="login__login-wrappper__form-info">
          Please enter required basic details to verify yourself.
        </FormInfo>
        <Form
          onSubmit={this.handleFormSubmit}
          data-test="login__login-wrappper__form"
        >
          <FormBody data-test="login__form-body">
            <Label>First Name</Label>
            <Input
              data-test="login__form-body__firstname"
              icon="person_outline"
              value={firstName}
              onChange={event => this.handleInputChange("firstName", event)}
              placeholder="First name"
              error={formErrors["firstName"]}
            />
            <ErrorMessage fontSize="small">
              {formErrors["firstName"]}
            </ErrorMessage>
            <Label>Last Name</Label>
            <Input
              data-test="login__form-body__lastname"
              icon="person_outline"
              value={lastName}
              onChange={event => this.handleInputChange("lastName", event)}
              placeholder="Last name"
              error={formErrors["lastName"]}
            />
            <ErrorMessage fontSize="small">
              {formErrors["lastName"]}
            </ErrorMessage>
            <Label>Contact Number</Label>
            <PhoneNumberInput
              data-test="login__form-body__phonenumber"
              value={phoneNumber}
              onChange={event => this.handleInputChange("phoneNumber", event)}
              error={formErrors["phoneNumber"]}
            />
            {phoneNumber && (
              <ErrorMessage fontSize="small">
                {formErrors["phoneNumber"]}
              </ErrorMessage>
            )}
            <Label>Gender</Label>
            <GenderOptions>
              {this.state.genders.map(gen => (
                <Radio
                  data-test={`login__form-body__gender--${gen.value}`}
                  name="gender"
                  key={gen.value}
                  id={gen.value}
                  value={gen.value}
                  label={gen.label}
                  checked={gender === gen.value}
                  onChange={event => this.handleInputChange("gender", event)}
                />
              ))}
            </GenderOptions>
            {gender && (
              <ErrorMessage fontSize="small">
                {formErrors["gender"]}
              </ErrorMessage>
            )}
            <Label>Date of birth</Label>
            <StyledDatePicker>
              <DatePicker
                data-test="login__form-body__dob"
                icon="calendar_today"
                onDateChange={this.handleDateChange}
                date={dob || "MM/DD/YYYY"}
                showDatePicker={this.state.showDatePicker}
                onFocusChange={this.handleFocusChange}
                disableDate={date => date > new Date()}
                appendToBody={false}
                position="Top"
              />
            </StyledDatePicker>
            <ErrorMessage fontSize="small">{formErrors["dob"]}</ErrorMessage>
          </FormBody>
          <FormFooter data-test="login__form-footer">
            <Button
              data-test="login__form-footer__submit"
              style={{ width: "100%" }}
              disabled={
                !(
                  firstName &&
                  lastName &&
                  phoneNumber &&
                  phoneNumber.length === 10 &&
                  dob &&
                  gender
                ) || registering
              }
              onClick={this.handleFormSubmit}
              appearance="primary"
            >
              {registering ? <Loader stroke="#fff" /> : "Submit"}
            </Button>
          </FormFooter>
        </Form>
      </LoginWrappper>
    );
  }
}
