import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import datashopTheme from "@datashop/theme";

import { AppProvider } from "context/app.context";
import AppContainer from "./AppContainer";

/**
 * Define app routes
 * @param {Object} props App props with store data
 */
class Routes extends Component {
  state = {
    context: {
      isLoggedIn: window.localStorage.getItem("isLoggedIn") || false,
      providers: [],
      userDetails: null,
    },
  };

  setContext = (context) => {
    this.setState(() => ({ ...this.state, context: { ...context } }));
  };

  render() {
    const { context } = this.state;
    const { setContext } = this;

    return (
      <AppProvider value={{ context, setContext }}>
        <ThemeProvider theme={datashopTheme}>
          <AppContainer />
        </ThemeProvider>
      </AppProvider>
    );
  }
}

export default Routes;
