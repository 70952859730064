import React, { Component, Fragment } from "react";
import styled from "styled-components";
import Icon from "@datashop/icon";
import { connect } from "react-redux";
import { Mixpanel } from "helpers/mixpanelHelper";
import SuccessCard from "../EvaluatePatient/SuccessCard";
import { Loader } from "../../packages/loader";
import { getEvaluatePatient } from "../../store/evaluatePatient/action";
import { sendEvaluatePatient } from "../../store/submitResponse/action";
import SelectLanguage from "./SelectLanguage";
import RenderQuestions from "../Questions/RenderQuestions";
import FillPatientDetails from "./FillPatientDetails";
import ErrorPage from "./ErrorPage";
import Disclaimer from "./Disclaimer";
import mobileCheck from "helpers/mobileCheck";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => (mobileCheck() ? "100vh" : "750px")};
`;

const ContentWrapper = styled.div`
  display: flex;
  height: ${props => (mobileCheck() ? "100vh" : "700px")};
`;

const HeaderWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
`;

const SurveyNameHeader = styled.div`
  color: #151111;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

const CardWrapper = styled.div`
  padding: 16px;
  flex: 1;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const GoBack = styled(Icon)`
  font-size: 28px;
  color: #2f2f2f;
  cursor: pointer;
  margin-right: 15px;
`;

const Language = {
  English: {
    header: "COVID-19 Assessment"
  },
  Spanish: {
    header: "Assistencia de COVID-19"
  }
};
class EvaluatePatient extends Component {
  state = {
    currentStep: 1,
    firstName: "",
    lastName: "",
    DOB: "",
    gender: "",
    occupation: "Other",
    patientId: "",
    phoneNo: "",
    email: "",
    canMoveForward: false,
    dobError: false,
    surveyStarted: false,
    redirectToSuccess: false,
    redirectToMain: false,
    toast: { type: "", title: "" },
    surveyCompleted: false,
    questions: [],
    activeQuestion: {},
    currentQuestion: 0,
    activeQuestionNumber: 0,
    totalQuestions: null,
    questionsAnswered: 0,
    answers: {},
    answerMap: {},
    language: "English",
    currentAnswer: true,
    showError: false,
    preStep: 1
  };

  onPatientDetailsChangeChange = event => {
    const {
      target: { value, name }
    } = event;
    this.setState(
      () => ({ [name]: value }),
      () => {
        this.checkForNextStep();
      }
    );
  };

  onDateChange = date => {
    let dobError = false;
    const pattern = /^((0)[1-9]|(1)[0-2]|[0-9])(\/)((0)[1-9]|(1|2)[0-9]|(3)[0-1]|[1-9])(\/)(\d{4})$/g;
    dobError = !pattern.test(date);
    this.setState({ DOB: date, dobError }, () => {
      this.checkForNextStep();
    });
  };

  checkForNextStep = () => {
    if (
      this.state.firstName &&
      this.state.lastName &&
      this.state.DOB &&
      !this.state.dobError &&
      this.state.gender &&
      this.state.occupation
    ) {
      this.setState({ canMoveForward: true });
    } else {
      this.setState({ canMoveForward: false });
    }
  };

  nextStep = () => {
    this.setState({ currentStep: 2 });
  };

  onButtonChange = event => {
    const {
      target: { name }
    } = event;
    this.setState(
      () => ({ gender: name }),
      () => {
        this.checkForNextStep();
      }
    );
  };

  componentWillMount() {
    const {
      match: {
        params: { assessmentId, responseId, patientId }
      }
    } = this.props;
    Mixpanel.track("Patient - Landing Page Visited", {
      Category: "COVID-19 : Assessment"
    });
    this.setState({ assessmentId, responseId, patientId });
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.evaluatePatient.isLoading &&
      !this.props.evaluatePatient.isLoading
    ) {
      this.setState({
        questions: this.props.evaluatePatient.data,
        surveyList: this.props.evaluatePatient.data,
        totalQuestions: this.props.evaluatePatient.data.length,
        activeQuestion: this.props.evaluatePatient.data[0],
        activeQuestionNumber: 0,
        dependentSurveyList: this.props.evaluatePatient.dependentSurveyList
      });
    }
    if (
      prevProps.submitResponse.isLoading &&
      !this.props.submitResponse.isLoading &&
      this.props.submitResponse.status
    ) {
      this.setState({
        redirectToSuccess: true
      });
    }
    if (
      prevProps.evaluatePatient.isLoading &&
      !this.props.evaluatePatient.isLoading &&
      !prevProps.evaluatePatient.error &&
      this.props.evaluatePatient.error
    ) {
      this.setState({
        showError: true
      });
    }
  };

  onClickCheckboxHandler = (event, selection) => {
    const { questionId, answerId } = selection;
    let newAnswers = this.state.answers;
    newAnswers[questionId] = answerId;
    this.setState({ answers: newAnswers });
  };

  goToNextStep = () => {
    const {
      firstName,
      lastName,
      DOB,
      gender,
      occupation,
      patientId,
      email
    } = this.state;
    if (firstName && lastName && DOB && gender) {
      Mixpanel.identify(patientId);
      Mixpanel.people.set({
        AppName: "COVID-19",
        Type: "Patient"
      });
      Mixpanel.track("Patient Details Entered", {
        Category: "COVID-19 : Assessment"
      });
      this.setState({ currentStep: 2, canMoveForward: false });
    } else {
      this.setState({
        showError: true
      });
    }
  };

  onChange = (questionId, answerId) => {
    this.setState(prevState => {
      let state = { ...prevState };
      state.answerMap[questionId] = answerId;
      state.canMoveForward = state.answerMap[questionId].length > 0;
      return state;
    });
  };

  disableNext = () => {
    this.setState({
      canMoveForward: false
    });
  };

  handleNavClick = (event, step) => {
    if (step !== "complete") {
      Mixpanel.track(
        `Patient - Question ${this.state.currentQuestion + 1} Completed`,
        {
          Category: "COVID-19 : Assessment"
        }
      );
      this.setState(prevState => ({
        currentQuestion: prevState.currentQuestion + (step === "next" ? 1 : -1),
        questionsAnswered:
          prevState.questionsAnswered - (step === "next" ? 0 : 1),
        canMoveForward:
          step === "next"
            ? typeof this.state.answerMap[
                this.state.surveyList[this.state.currentQuestion + 1].id
              ] !== "undefined" &&
              this.state.answerMap[
                this.state.surveyList[this.state.currentQuestion + 1].id
              ].length > 0
            : true
      }));
    } else {
      const answerMap = this.state.answerMap;
      const patientInfo = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        dob: this.state.DOB,
        gender: this.state.gender,
        email: this.state.email,
        occupation: this.state.occupation
      };
      this.props.sendEvaluatePatient(
        this.state.assessmentId,
        this.state.patientId,
        {
          answerMap,
          patientInfo,
          assessmentId:
            this.state.language === "English"
              ? this.state.assessmentId
              : "5e7c475098c5bf3ad5482b19",
          responseId: this.state.responseId
        },
        this.state.language
      );
    }
  };

  startSurvey = () => {
    Mixpanel.track("Patient - Disclaimer (accept and continue)", {
      Category: "COVID-19 : Assessment"
    });
    this.setState({ surveyIsLoading: true });
    // if (this.state.language === 'Spanish') {
    //     this.setState({
    //         assessmentId: '5e782b3298c5bf3ad54624fe'
    //     });
    // }
    this.props
      .getEvaluatePatient(
        this.state.language === "English"
          ? this.state.assessmentId
          : "5e7c475098c5bf3ad5482b19",
        this.state.responseId
      )
      .then(() => {
        this.setState({ surveyStarted: true, surveyIsLoading: false });
      });
  };

  closeToast = () => {
    this.setState({ showError: false, toast: { type: null, title: "" } });
  };

  languageChangeHandler = event => {
    Mixpanel.track("Patient - Language Selected", {
      category: "COVID-19 : Assessment",
      Language: event.target.value
    });
    this.setState({
      language: event.target.value
    });
  };

  nextPrepStep = () => {
    this.setState({
      preStep: this.state.preStep + 1
    });
  };

  render() {
    const { language } = this.state;
    if (this.state.showError) {
      return (
        <ErrorPage
          error={this.props.evaluatePatient.error}
          language={language}
        />
      );
    }

    return (
      <Wrapper>
        <HeaderWrapper>
          <span>
            <GoBack
              name="arrow_back"
              onClick={() => this.props.history.push("/")}
            />
          </span>
          <SurveyNameHeader>{Language[language].header}</SurveyNameHeader>
        </HeaderWrapper>
        {this.props.evaluatePatient.isLoading ||
        this.props.submitResponse.isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <ContentWrapper>
            {!this.state.surveyStarted ? (
              <Fragment>
                {this.state.preStep === 1 && (
                  <SelectLanguage
                    language={this.state.language}
                    languageChangeHandler={this.languageChangeHandler}
                    surveyIsLoading={this.state.surveyIsLoading}
                    startSurvey={this.nextPrepStep}
                  />
                )}
                {this.state.preStep === 2 && (
                  <Disclaimer
                    nextPrepStep={this.startSurvey}
                    language={language}
                  />
                )}
              </Fragment>
            ) : (
              <Fragment>
                {this.state.redirectToSuccess ? (
                  <CardWrapper>
                    <SuccessCard
                      language={language}
                      responseId={this.state.responseId}
                      state={this.props.submitResponse.status}
                      from="patient"
                      assessmentId={
                        this.state.language === "English"
                          ? "5e7b840c98c5bf3ad547f6a2"
                          : "5e7c475098c5bf3ad5482b19"
                      }
                    />
                  </CardWrapper>
                ) : (
                  <Fragment>
                    {this.state.currentStep === 1 ? (
                      <FillPatientDetails
                        {...this.state}
                        onButtonChange={this.onButtonChange}
                        onChange={this.onPatientDetailsChangeChange}
                        onDateChange={this.onDateChange}
                        goToNextStep={this.goToNextStep}
                      />
                    ) : (
                      <RenderQuestions
                        {...this.state}
                        disableNext={this.disableNext}
                        onChange={this.onChange}
                        handleNavClick={this.handleNavClick}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </ContentWrapper>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ evaluatePatient, submitResponse }) => ({
  evaluatePatient,
  submitResponse
});

const mapDispatchToProps = { getEvaluatePatient, sendEvaluatePatient };

export default connect(mapStateToProps, mapDispatchToProps)(EvaluatePatient);
