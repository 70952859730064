import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
   color: #ffffff;
   font-size: 28px;
   line-height: 40px;
`;

const Wrapper = styled.div`
   z-index: 2;
   line-height: 22px;
   text-align: center;
   margin: auto;
   left: 0;
   right: 0;
   width: 520px;
   padding: 16px;
   top: 30%;
   background-color: #2f2f2f;
   opacity: 0.5;
   position: fixed;

   @media (max-width: 480px) {
      width: 300px;

      ${Text} {
         color: #ffffff;
         font-size: 16px;
         font-weight: 500;
         letter-spacing: -0.38px;
         line-height: 24px;
      }
   }
`;

function PoorNetwork({ isSelfNetworkPoor, poorNetworkArray }) {
   return (
      <Wrapper>
         {isSelfNetworkPoor && (
            <Text>Your internet connection is unstable</Text>
         )}
         {poorNetworkArray.length > 0 && !isSelfNetworkPoor && (
            <Text>{`${poorNetworkArray.map(item => item.name).join(', ')}${
               poorNetworkArray.length === 1
                  ? `'s internet connection is unstable`
                  : ' have a unstable internet connection'
            }`}</Text>
         )}
      </Wrapper>
   );
}

export default PoorNetwork;
