import Axios from "axios";
import history from "./../history";
const URL = process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_BOT_API`];
// const URL = 'https://inconnect.oninnovaccer.com/api/chatv2/'

const botApi = Axios.create({
  baseURL: URL,
  headers: {}
});

botApi.interceptors.response.use(
  res => res,
  err => {
    if (err.response.status === 404) {
      // throw new Error(`${err.config.url} not found`);
    }

    if (err.response.status === 428) {
      const redirectUrl = encodeURIComponent(
        `${history.location.pathname}${history.location.search}`
      );
      history.push(`/accept-consent?redirect=${redirectUrl}`);
    }

    throw err;
  }
);

export default botApi;
