import React, { Component } from 'react';
import styled from 'styled-components';
import StyledButton from '@datashop/button';

const Button = styled(StyledButton)`
   padding: 4px 12px;

   :first-child {
      margin-right: 8px;
   }
`;

const AnswerWrapper = styled.div`
   display: flex;
   margin-top: 8px;

   ${Button} {
      margin-right: 8px;
   }
`;

class Answer extends Component {
   state = {
      selected: this.props.response !== undefined ? this.props.response : null
   };

   componentDidUpdate(prevProps, prevState) {
      if (this.props.response && this.props.response !== prevProps.response) {
         this.setState({
            selected: this.props.response
         });
      }
   }

   onClick = selected => {
      this.setState({ selected });
      this.props.answerQuestion(this.props.id, selected);
   };
   render() {
      const {
         question: { answer: { choiceList = [] } = {} }
      } = this.props;
      return (
         <AnswerWrapper>
            {choiceList.map(item => (
               <Button
                  dimension={!this.props.providerFlow ? 'regular' : 'tiny'}
                  appearance={`${
                     this.state.selected === item.id ? 'primary' : 'secondary'
                  }`}
                  onClick={this.onClick(item.id)}>
                  {item.value}
               </Button>
            ))}
         </AnswerWrapper>
      );
   }
}

export default Answer;
