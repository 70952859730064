import React from "react";
import Card from "@datashop/card";
import Text from "@datashop/text";
import moment from "moment";
import styled from "styled-components";
import Button from "@datashop/button";
import theme from "@datashop/theme";
import { FlexBox } from "./commons";

const Wrapper = styled.div`
  background: ${props => props.theme.datashop.palette.stone.lightest};
  height: 100%;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: 100%;
  width: 100%;
  > * {
    margin-bottom: 16px;
    max-width: 97%;
  }
`;

const DetailsCard = styled(Card).attrs({ shadow: "light" })`
  background-color: ${props => props.theme.datashop.palette.white.main};
  padding: 16px;
  width: 100%;
  div {
    display: flex;
    justify-content: space-between;
    :not(:last-child) {
      margin-bottom: 12px;
    }
    > *:last-child {
      text-align: right;
    }
  }
`;

const Actions = styled.div`
  flex-shrink: 0;
  > * {
    width: 100%;
  }
`;

const PaymentStatus = ({
  paymentMethod,
  transactionStatus,
  joinCall,
  payLater
}) => {
  const {
    status,
    amount,
    scheduledOn,
    transactionId,
    providerDetails = {}
  } = transactionStatus;

  const { card } = paymentMethod || {};

  const getInfoList = required => {
    const list = {
      amount: amount && (
        <div
          style={{
            paddingBottom: 12,
            borderBottom: `1px solid ${theme.datashop.palette.stone.light}`
          }}
        >
          <Text fontWeight="bold">Amount</Text>
          <FlexBox direction="column">
            <Text style={{ fontSize: 24 }} fontWeight="bold">
              ${amount}
            </Text>
            <Text style={{ textTransform: "capitalize" }}>
              {card ? `${card.funding} card (**** ${card.last4})` : `card`}
            </Text>
          </FlexBox>
        </div>
      ),
      transactionId: transactionId && (
        <div>
          <Text style={{ flexShrink: 0 }}>Transaction ID</Text>
          &nbsp;&nbsp;
          <Text ellipsis={true}>{transactionId}</Text>
        </div>
      ),
      paymentType: (
        <div>
          <Text>Payment type</Text>
          <Text style={{ textTransform: "capitalize" }}>
            {card ? `${card.funding} card (**** ${card.last4})` : `card`}
          </Text>
        </div>
      ),
      providerName: providerDetails && (
        <div>
          <Text>Provider name</Text>
          <Text ellipsis={true} style={{ textTransform: "capitalize" }}>
            {providerDetails.firstName}&nbsp;{providerDetails.lastName}
          </Text>
        </div>
      ),
      visitDate: scheduledOn && (
        <div>
          <Text>Visit date</Text>
          <Text>{moment(scheduledOn).format("MM/DD/YY")}</Text>
        </div>
      ),
      status: status && (
        <div>
          <Text>Status</Text>
          <Text style={{ textTransform: "capitalize" }}>
            {status.toLowerCase()}
          </Text>
        </div>
      )
    };

    return required.map(value => list[value]);
  };

  const getContent = () => {
    const st = (status || "").toUpperCase();
    if (["SUCCESS"].includes(st)) {
      return {
        img: `${process.env.PUBLIC_URL}/img/checkCircle.svg`,
        title: "Payment successful",
        description:
          "We will send you payment receipt on your registered mobile number.",
        info: getInfoList([
          "amount",
          "transactionId",
          "providerName",
          "visitDate"
        ]),
        actions: (
          <Button appearance="primary" onClick={joinCall}>
            Join call
          </Button>
        )
      };
    }
    if (["FAILED"].includes(st)) {
      return {
        img: `${process.env.PUBLIC_URL}/img/paymentFailed.svg`,
        title: "Payment failed",
        description:
          "In case your account was charged the amount will be refunded in 5-7 days.",
        info: getInfoList(["transactionId", "paymentType", "status"]),
        actions: <Button onClick={payLater}>Pay later</Button>
      };
    }
    return {
      img: `${process.env.PUBLIC_URL}/img/paymentPending.svg`,
      title: "Payment pending",
      description:
        "We will notify you about the payment status on your registered mobile number.",
      info: getInfoList(["transactionId", "paymentType", "status"]),
      actions: (
        <Button appearance="primary" onClick={joinCall}>
          Join call
        </Button>
      )
    };
  };

  const { title, img, info, description, actions } = getContent();

  return (
    <Wrapper>
      <Body>
        <img src={img} alt="payment status" />
        <Text style={{ textAlign: "center", fontSize: 20 }} fontWeight="bold">
          {title}
        </Text>
        <Text style={{ textAlign: "center" }}>{description}</Text>
        <DetailsCard>{info}</DetailsCard>
        <Text
          fontSize="small"
          style={{ textAlign: "center", color: "#868686" }}
        >
          In case of any payment related concern, please contact the practice
          directly with the transaction details.
        </Text>
      </Body>
      <Actions>{actions}</Actions>
    </Wrapper>
  );
};

export default PaymentStatus;
