/**
 * Transform error response
 * @param {Object} error Formatted error response
 */
const transformError = error => {
  const {
    response: { data, status }
  } = error;
  return status === 403
    ? {
        error: {
          ...data,
          code: status
        }
      }
    : {
        error: {
          message: data.error.message,
          code: data.error.code ? data.error.code : data.statusCode
        }
      };
};

/**
 * Transform error response
 * @param {Object} error Formatted error response
 */
const transformHandledError = error => {
  const {
    response: { data }
  } = error;
  return {
    error: {
      message: data.error.message,
      code: data.error.code ? data.error.code : data.statusCode
    }
  };
};

export { transformError, transformHandledError };
