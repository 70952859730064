import React from "react";
import MaskedInput from "react-text-mask";
import styled from "styled-components";
import Input from "@datashop/input";

// const StyledInput = styled(Input)`
//   + i {
//     top: 12px;
//   }
// `;

const phoneNumberFormat = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const PhoneNumberInput = ({
  onChange,
  onBlur,
  value,
  error,
  ...rest
}) => {
  return (
    <MaskedInput
      mask={phoneNumberFormat}
      placeholder="(000) 000-0000"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      render={(ref, renderProps) => {
        const { onChange, placeholder, value, onBlur, ...other } = renderProps;
        return (
          <Input
            icon="phone"
            innerRef={ref}
            inputFormat="phoneNumber"
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            value={value}
            error={error}
            style={{ color: error ? "red" : "#2D3142" }}
            {...rest}
            {...other}
          />
        );
      }}
    />
  );
};

export default PhoneNumberInput;
