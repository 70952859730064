const APP_NAME = "covid-innovaccer";

const isSupported = () => {
  // Sometimes browsers block the access to LocalStorage, like while browsing in private mode and so on...
  // So wrapping storage accessing code in try/catch because an exception will be thrown in such cases
  try {
    const testKey = "__some_random_key_you_are_not_going_to_use__";
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

const getKey = key => `${APP_NAME}.${key}`;

export const setItem = (key, value) => {
  if (isSupported()) {
    const stringVal = JSON.stringify(value);
    localStorage.setItem(getKey(key), stringVal);
  }
};

export const getItem = key => {
  if (isSupported()) {
    const val = localStorage.getItem(getKey(key));
    return val !== null ? JSON.parse(val) : null;
  }
  return null;
};

export const removeItem = key => {
  if (isSupported()) {
    localStorage.removeItem(getKey(key));
  }
};
