import React, { Fragment, Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { differenceInDays, format } from "date-fns";
import { forEach } from "lodash";
import { Loader } from "../../packages/loader";
import { formatTime, isToday, monthNames } from "../../helpers/formatTime";
import Text from "@datashop/text";
import mobileCheck from "../../helpers/mobileCheck";

function parentHeight(elem) {
  if (elem) {
    return elem.parentElement.clientHeight;
  }
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const LoaderWrapper = styled.div`
  max-height: ${props =>
    mobileCheck() ? `${window.innerHeight - 128}px` : "inherit"};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  overflow-y: auto;
`;

const NotesDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-self: ${props =>
    props.item &&
    props.item.sender &&
    props.item.sender === `PATIENT.${localStorage.getItem("userId")}`
      ? "flex-end"
      : "flex-start"};
`;

const NoteText = styled.div`
  color: #151414;
  line-height: 20px;
  word-break: break-word;
  white-space: pre-wrap;

  a {
    color: #007aff;
  }
`;

const TimeText = styled.div`
  margin-top: 8px;
  color: #76797c;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

const NoteTextWrapper = styled.div`
  padding: 12px;
  max-width: 328px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e7e5e5;
  border-radius: ${props =>
    props.item &&
    props.item.sender &&
    props.item.sender === `PATIENT.${localStorage.getItem("userId")}`
      ? "8px 8px 0 8px"
      : "8px 8px 8px 0"};
  background-color: ${props =>
    props.item &&
    props.item.sender &&
    props.item.sender === `PATIENT.${localStorage.getItem("userId")}`
      ? "#F4F4F4"
      : "#FFF"};
`;

const DateText = styled.div`
  color: #868686;
  font-weight: 600;
  line-height: 20px;
  margin-top: 12px;
`;

const BeginningChat = styled.div`
  color: #151414;
  line-height: 20px;
  margin: 12px 0 4px;
  text-align: center;
`;

const Bullet = styled.p`
  color: #868686;
  margin: 4px 4px 0 4px;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const chatDate = date => {
  const chatDate = new Date(date);
  const todayDate = new Date();
  const diffInDays = Math.abs(differenceInDays(todayDate, chatDate));
  let dateToShow = "";
  if (diffInDays === 0) {
    dateToShow = "Today";
  } else if (diffInDays === 1) {
    dateToShow = "Yesterday";
  } else {
    dateToShow = format(chatDate, "MMM dd, yyyy");
  }
  return dateToShow;
};

const chatTime = epoch => format(new Date(epoch), "p");

class MessageBody extends Component {
  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate = prevProps => {
    this.scrollToBottom();
    // if(prevProps.unreadMessages.length === 0){
    //    this.scrollToBottom();
    // }
  };
  // componentWillReceiveProps(nextProps) {
  //    if(nextProps.unreadMessages.length === 0){
  //       this.scrollToBottom();
  //    }

  /**
   * Scroll to bottom
   * @param {String} behavior Behavior
   * @memberof MessageBody
   */
  scrollToBottom = (behavior = "smooth") => {
    this.messagesEndRef.current.scrollIntoView({
      behavior,
      block: "nearest",
      inline: "start"
    });
  };

  setUpMsgContainerObserver = () => {
    const options = {
      root: null,
      rootMargin: "0",
      threshold: 0.5
    };

    let observer = new IntersectionObserver(this.observerCallback, options);
    observer.observe(this.messagesEndRef.current);
  };

  /**
   * Observer call back to bring earlier messages on scroll up
   * @param {List} entryList Entry list
   * @memberof MessageBody
   */
  observerCallback = (entries, observer) => {
    entries.map(entry => {
      // Handle received enties here
    });
  };

  handleSentTime = time => {
    const sentAt = new Date(time);
    const isSentToday = isToday(sentAt);
    if (isSentToday) {
      return formatTime(time);
    } else {
      return (
        monthNames[sentAt.getMonth()] &&
        `${sentAt.getDate()} ${monthNames[sentAt.getMonth()].slice(0, 3)}`
      );
    }
  };

  /**
   * Convert URLs in a string to anchor links
   * @param {!string} string
   * @returns {!string}
   */

  linkify = string => {
    const urls = string.match(/(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g);

    if (urls) {
      urls.forEach(function(url) {
        string = string.replace(
          url,
          '<a target="_blank" href="' + url + '">' + url + "</a>"
        );
      });
    }

    return string.replace("(", "<br/>(");
  };

  generateChatBubble = (item, index) => {
    let chatBubble = "";

    switch (item.type) {
      case "chatAssigned":
        chatBubble = (
          <Text style={{ textAlign: "center" }}>{item.message}</Text>
        );
        break;
      default:
        chatBubble = (
          <Texts>
            <NotesDataWrapper item={item} key={index}>
              <NoteTextWrapper item={item}>
                <NoteText
                  dangerouslySetInnerHTML={{
                    __html: item.message && this.linkify(item.message.body)
                  }}
                />
                <TimeWrapper>
                  <TimeText>{this.handleSentTime(item.sent_at)}</TimeText>
                  {item.sender &&
                    item.sender ===
                      `PATIENT.${localStorage.getItem("userId")}` && (
                      <Fragment>
                        <Bullet>&bull;</Bullet>
                        <TimeText>
                          {item.ticks && item.ticks.blue && item.ticks.grey
                            ? "Read"
                            : "Unread"}
                        </TimeText>
                      </Fragment>
                    )}
                </TimeWrapper>
              </NoteTextWrapper>
            </NotesDataWrapper>
          </Texts>
        );
        break;
    }

    return chatBubble;
  };

  chatBubble = (item = {}, index) => {
    return <>{this.generateChatBubble(item, index)}</>;
  };

  render() {
    const { loadingMsgs, messageList = [], handleMarksAsRead } = this.props;
    return false ? (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    ) : (
      <Wrapper>
        <TextWrapper onScroll={this.props.handleScroll}>
          {messageList.length > 0 &&
            messageList.map((item = {}, index) => (
              <div key={index}>{this.chatBubble(item, index)}</div>
            ))}
          <div ref={this.messagesEndRef}>&nbsp;</div>
        </TextWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ socket }) => ({ ...socket });

export default connect(mapStateToProps, null)(MessageBody);
