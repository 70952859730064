const formatTime = sentAt => {
  const date = new Date(sentAt);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const isToday = sentAt => {
  const today = new Date();
  return (
    sentAt.getDate() == today.getDate() &&
    sentAt.getMonth() == today.getMonth() &&
    sentAt.getFullYear() == today.getFullYear()
  );
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export { formatTime, isToday, monthNames };
