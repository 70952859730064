import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import "./App.css";
import "whatwg-fetch";

import Routes from "./routes";
// import registerServiceWorker from "./registerServiceWorker";
// Define store
export const store = configureStore();

// For WalkMe
window.skeletonPwa = {
  container: {
    auth: {
      userData: {
        email: "test@innovaccer.com"
      }
    }
  }
};

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
