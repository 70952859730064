import React, { useState, useEffect, Fragment, useContext } from "react";
import styled from "styled-components";
import { Loader } from "packages/loader";
import axios from "helpers/axios";

import createToast from "helpers/toastHelper";
import SelectProvider from "../VisitRequest/SelectProvider";
import mobileCheck from "helpers/mobileCheck";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => (mobileCheck() ? "#f4f4f4" : "#fff")};
  min-height: 450px;
  height: ${props => (mobileCheck() ? "auto" : "700px")};
  position: relative;
`;

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 450px;
  height: 700px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LoadingMessage = styled.div`
  margin-left: 5px;
  font-size: 14px;
  font-weight: 400;
`;

const VirtualWalkInContainer = props => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Creates avirtual walkin for the
   * logged-in user in VFD (Patient Web App)
   *
   * @param {String} organizationId
   * @param {String} providerId
   */
  const createVirtualWalkin = (organizationId, providerId) => {
    setIsLoading(true);
    // Make APi For Virtual WalkIn
    axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
      "token"
    )}`;
    axios
      .post("innote-survey/telehealth/video/patient/walkin", {
        organization: organizationId,
        assignedTo: providerId
      })
      .then(response => {
        const { data } = response;
        // Redirect to the Video visit call url
        window.location.href = data.patientInviteLink;
      })
      .catch(error => {
        setIsLoading(false);
        const {
          response: { data }
        } = error;

        createToast({
          title: data.error.message
            ? data.error.message
            : "Virtual Walk-In Failed. Try Again."
        });

        // Redirect to Virtual WalkIn page
        props.history.push("/virtual-walkin");
      });
  };

  /**
   * Called once Provider is selected form the list
   * @param {Object} provider
   */
  const onProviderSelected = provider => {
    const { organization_id } = props;
    const { value } = provider;

    // Create Virtual Walk-in
    createVirtualWalkin(organization_id, value);
  };

  const onClose = () => {
    props.history.push("/");
  };

  return (
    <Fragment>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
          <LoadingMessage>Creating virtual walk-in ... </LoadingMessage>
        </LoaderWrapper>
      ) : (
        <Container>
          <SelectProvider
            onClose={onClose}
            selectProvider={onProviderSelected}
            backIcon={"close"}
            organizationId={props.organization_id}
            fetchOnline={true}
          />
        </Container>
      )}
    </Fragment>
  );
};

export default VirtualWalkInContainer;
