import React from "react";
import styled from "styled-components";
import Button from "@datashop/button";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  padding-bottom: 0;
  overflow: auto;
  flex: 1;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${window.innerHeight - 144}px;
  overflow: auto;
  padding: 0 16px;
  flex: 1;
`;

const Header = styled.p`
  color: #000000;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const Text = styled.p`
  margin-top: 16px;
  color: #000000;
  font-size: 16px;
  letter-spacing: -0.38px;
  line-height: 24px;
`;

const ButtonWrapper = styled.div`
  padding: 12px 16px;
  background-color: #f4f4f4;
`;

const Language = {
  English: {
    disclaimer: "Disclaimer",
    para1:
      "You have received a self assessment for COVID-19. Please read and accept the conditions.",
    para2:
      "This Questionnaire is based on the CDC and WHO guidelines for self-assessment of whether you may or may not have COVID-19. This Questionnaire or website does not screen whether you may have another medical condition that may require medical attention. The Questionnaire is limited to the CDC and WHO guidelines for known symptoms of COVID-19.",
    para3:
      "As always, if you have any questions about your symptoms or a medical condition, or if you think you may have another condition besides COVID-19, please consult with your doctor or a medical professional. You should not rely upon this Questionnaire or any content within the website for any medical decision-making or decision whether to contact a healthcare professional.",
    para4:
      "This Questionnaire and any educational materials you receive on the website are not medical advice, not intended to replace consultation with a healthcare professional for the diagnosis, cure, mitigation, treatment or prevention of disease, injury or other conditions, and are provided for informational purposes only to help you check for known symptoms of COVID-19, according to the CDC and WHO guidelines.",
    para5:
      "If you answer Yes to any questions in the Questionnaire, your responses will be forwarded to a medical professional who will review your information and will make a determination regarding your health condition.",
    para6:
      "I accept the terms of use and want to proceed further with the survey.",
    termText: "Terms of use",
    buttonText: "Accept & Proceed"
  },
  Spanish: {
    disclaimer: "Términos de Uso",
    para1:
      "Ha recibido una autoevaluación relacionada al Coronavirus-19 (COVID-19). Por favor, lea y acepte estos términos de uso.",
    para2:
      "Este Cuestionario se basa en los lineamientos de los CDC y la La Organización Mundial De Salud (WHO) sobre el COVID-19. Este Cuestionario del sitio web no evalúa la posibilidad de que tenga otra afección de salud que requiera atención médica. La evaluación se limita a las pautas de los CDC y la OMS para los síntomas conocidos de  COVID-19.",
    para3:
      "Como siempre, si usted tiene alguna pregunta sobre sus síntomas o una afección médica, o si cree que puede tener otra afección además de COVID-19, consulte  su médico. No debe confiar en esta encuesta ni en su contenido dentro de este sitio web para tomar una decisión médica o para contactar a un profesional médico.",
    para4:
      "El Cuestionario y cualquier material educativo que reciba en el sitio web no son consejos médicos, ni pretenden reemplazar la consulta con un médico para el diagnóstico, cura, mitigación, tratamiento o prevención de enfermedades, lesiones u otras afecciones. Se utiliza exclusivamente para ayudarle a verificar los síntomas conocidos de COVID-19. de acuerdo con las pautas de los CDC y la OMS.",
    para5:
      "Si responde Sí a cualquiera de las preguntas en el Cuestionario, sus respuestas se enviarán a un médico, que revisará su información y tomará una decisión con respecto a su afección médica.",
    para6: "Acepto los términos y deseo continuar con la encuesta.",
    termText: "Términos de Uso",
    buttonText: "Aceptar y continuar"
  }
};

const Disclaimer = ({ nextPrepStep, language }) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Header>{Language[language].disclaimer}</Header>
        <TextWrapper>
          <Text style={{ fontWeight: 600 }}>{Language[language].para1}</Text>
          <Text>{Language[language].para2}</Text>
          <Text>{Language[language].para3}</Text>
          <Text>{Language[language].para4}</Text>
          <Text>{Language[language].para5}</Text>
          <Text style={{ fontWeight: 600, marginBottom: "40px" }}>
            {Language[language].para6}
          </Text>
          <a
            style={{ textAlign: "center", marginBottom: "24px" }}
            href={`${process.env.PUBLIC_URL}/termsOfUse.pdf`}
            target="_blank"
            download
          >
            {Language[language].termText}
          </a>{" "}
        </TextWrapper>
      </ContentWrapper>
      <ButtonWrapper>
        <Button
          onClick={nextPrepStep}
          expanded
          dimension="large"
          appearance="primary"
        >
          {Language[language].buttonText}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Disclaimer;
