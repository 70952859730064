import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Articles } from "innovaccer";

import "innovaccer/dist/index.css";

export const ArticlePage = () => {
  const { documentId, sectionId, refreshToken, "0": authToken } = useParams();

  useEffect(() => {
    let root = document.getElementById("root");
    root.style.height = "auto";

    return () => {
      let body = document.getElementById("HwMain");
      body && body.parentElement.removeChild(body);
      root.style.height = "100%";
    };
  }, []);

  if (!!authToken) {
    localStorage.setItem("authToken", authToken);
  }
  if (!!refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }

  return (
    <Articles
      documentId={documentId}
      sectionId={sectionId}
      authToken={authToken || localStorage.getItem("authToken")}
      refreshToken={refreshToken || localStorage.getItem("refreshToken")}
    />
  );
};
