import React, { Component } from "react";
import styled from "styled-components";
import Button from "@datashop/button";
import Radio from "@datashop/radio";

const SurveyText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const StartSurvey = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  justify-content: space-between;
  flex-direction: column;
`;

const TextWrapper = styled.div``;

const LanguageWrapper = styled.div``;

const RadioWrapper = styled.div`
  display: flex;
  margin-top: 8px;

  > div {
    margin-right: 12px;
  }
`;

const Label = styled.p`
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.34px;
  line-height: 20px;
`;

const Language = {
  English: {
    acceptButton: "Accept & Begin Survey",
    select: "Select Language",
    text:
      "Please fill in the responses to these questions. This will help us provide you with CDC-recommended resources."
  },
  Spanish: {
    acceptButton: "Aceptar y comenzar encuesta",
    select: "Seleccione el idioma",
    text:
      "Por favor, conteste a las siguientes preguntas. Esto nos ayudará a proporcionarle recursos recomendados por los CDC."
  }
};

class SelectLanguage extends Component {
  render() {
    const {
      language,
      languageChangeHandler,
      surveyIsLoading,
      startSurvey
    } = this.props;

    return (
      <StartSurvey>
        <TextWrapper>
          <SurveyText>{Language[language].text}</SurveyText>
          <LanguageWrapper>
            <Label>{Language[language].select}:</Label>
            <RadioWrapper>
              <Radio
                key={"English"}
                value={"English"}
                label={"English"}
                id={"English"}
                name="language"
                checked={"English" === language}
                onChange={languageChangeHandler}
              />{" "}
              <Radio
                key={"Spanish"}
                value={"Spanish"}
                id={"Spanish"}
                label={"Español"}
                name="language"
                checked={"Spanish" === language}
                onChange={languageChangeHandler}
              />
            </RadioWrapper>
          </LanguageWrapper>
        </TextWrapper>
        <Button
          type="submit"
          appearance="primary"
          dimension="large"
          disabled={surveyIsLoading}
          expanded
          onClick={startSurvey}
        >
          {Language[language].acceptButton}
        </Button>
      </StartSurvey>
    );
  }
}

export default SelectLanguage;
