import React from "react";
import styled from "styled-components";
import Card from "@datashop/card";
import Input from "@datashop/input";
import Text from "@datashop/text";
import Axios from "axios";

export const StyledCard = styled(Card).attrs({ shadow: "light" })`
  background: ${props => props.theme.datashop.palette.white.main};
  padding: 16px;
  width: 100%;
`;

export const Label = styled(Text)`
  margin-bottom: 4px;
`;

export const FlexBox = styled.div`
  display: flex;
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => props.direction && `flex-direction: ${props.direction};`}
`;

export const inConnect = Axios.create({
  baseURL: process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_INCONNECT_URL`]
});

export const inConnectAuth = Axios.create({
  baseURL: process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_AUTH_API`]
});
