import React, { Component } from "react";
import styled from "styled-components";
import { StyledCard, Label, inConnect } from "./commons";
import { Modal, motion } from "@krishnaxv/react-surface";
import Text from "@datashop/text";
import Input from "@datashop/input";
import { debounce } from "lodash";
import Placeholder from "@datashop/placeholder";
import Icon from "@datashop/icon";

const CardWrapper = styled(StyledCard)`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .modal__container {
    left: 0;
    margin: 0 auto;
    right: 0;
  }
`;

const ModalContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 12px;
  > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const InsuranceListWrapper = styled.div`
  overflow: auto;
`;

const ListItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  :not(:last-child) {
    border-bottom: 1px solid
      ${props => props.theme.datashop.palette.stone.light};
  }
`;

const defaultInsuranceParams = {
  offset: 0,
  limit: 30,
  search: ""
};

class InsuranceCard extends Component {
  state = {
    modalOpen: false,
    insurances: [],
    apiParams: {
      ...defaultInsuranceParams
    },
    fetchingInsurances: false
  };

  toggleModal = () => {
    const { modalOpen } = this.state;
    if (modalOpen && this.insuranceListRef) {
      this.insuranceListRef.removeEventListener(
        "scroll",
        this.handleInfiniteScroll
      );
    }
    this.setState(
      {
        modalOpen: !modalOpen,
        apiParams: defaultInsuranceParams,
        insurances: []
      },
      () => {
        if (!modalOpen) {
          this.insuranceListRef.addEventListener(
            "scroll",
            this.handleInfiniteScroll
          );
          this.fetchInsurances();
        }
      }
    );
  };

  fetchInsurances = () => {
    const { insurances, apiParams, fetchingInsurances } = this.state;
    if (fetchingInsurances || apiParams.offset === null) {
      return;
    }
    this.setState({ fetchingInsurances: true });
    inConnect
      .get("/payers", { params: apiParams })
      .then(({ data }) => {
        const { results, next } = data;
        this.setState({
          fetchingInsurances: false,
          insurances: [...insurances, ...(results || [])],
          apiParams: { ...apiParams, ...next }
        });
      })
      .catch(() => {
        this.setState({
          fetchingInsurances: false
        });
      });
  };

  onSeachChange = debounce(search => {
    this.setState(
      {
        apiParams: { ...defaultInsuranceParams, search },
        insurances: []
      },
      this.fetchInsurances
    );
  }, 300);

  handleInfiniteScroll = debounce(() => {
    const element = this.insuranceListRef;
    if (
      element &&
      element.scrollHeight - element.scrollTop <= element.clientHeight
    )
      this.fetchInsurances();
  }, 300);

  insuranceListRef = null;
  render() {
    const { modalOpen, insurances, fetchingInsurances } = this.state;
    const { details = {}, onUpdate } = this.props;

    return (
      <CardWrapper>
        <Text fontWeight="bold" fontSize="large">
          Insurance Details
        </Text>
        <div>
          <Label>Insurance Provider</Label>
          <Input
            onClick={this.toggleModal}
            value={details.name}
            placeholder="Select provider"
          />
        </div>
        <div>
          <Label>Group ID</Label>
          <Input
            onChange={e => {
              onUpdate({ groupId: e.target.value });
            }}
            value={details.groupId}
            placeholder="Enter Group ID"
          />
        </div>
        <div>
          <Label>Member ID</Label>
          <Input
            onChange={e => {
              onUpdate({ memberId: e.target.value });
            }}
            value={details.memberId}
            placeholder="Enter Member ID"
          />
        </div>
        {modalOpen && (
          <Modal
            motion={motion.slideInBottom}
            style={{
              position: "absolute",
              width: "100%",
              height: "90%",
              bottom: 0,
              display: "flex",
              backgroundColor: "#fff"
            }}
            onClose={this.toggleModal}
          >
            {() => (
              <ModalContentWrapper>
                <Text fontWeight="bold">Select insurance provider</Text>
                <Input
                  placeholder="Search, e.g. cigna health"
                  onChange={e => this.onSeachChange(e.target.value)}
                />
                <InsuranceListWrapper
                  innerRef={el => (this.insuranceListRef = el)}
                >
                  {insurances.length > 0 &&
                    insurances.map(({ id, name }) => {
                      return (
                        <ListItem
                          key={id}
                          onClick={() => {
                            onUpdate({
                              insuranceProvider: id,
                              name
                            });
                            this.toggleModal();
                          }}
                        >
                          <Text>{name}</Text>
                          <Icon name="keyboard_arrow_right" />
                        </ListItem>
                      );
                    })}
                  {!fetchingInsurances && insurances.length == 0 && (
                    <Text>No insurances found</Text>
                  )}
                  {fetchingInsurances && (
                    <Placeholder>
                      <Placeholder.Paragraph length="medium" />
                      <Placeholder.Paragraph length="large" />
                      <Placeholder.Paragraph length="medium" />
                      <Placeholder.Paragraph length="large" />
                    </Placeholder>
                  )}
                </InsuranceListWrapper>
              </ModalContentWrapper>
            )}
          </Modal>
        )}
      </CardWrapper>
    );
  }
}

export default InsuranceCard;
