import { createStore, applyMiddleware, combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import evaluatePatient from './evaluatePatient/reducer';
import submitResponse from './submitResponse/reducer';
import socket from './socket/reducer';
import virtualVisit from './virtalVisit/reducer';

// Enable redux-logger in all environments other than production
const middlewareList =
  process.env.NODE_ENV !== 'production'
    ? applyMiddleware(thunk, createLogger())
    : applyMiddleware(thunk);

// App reducer
const appReducer = combineReducers({
  router: routerReducer,
  evaluatePatient,
  submitResponse,
  socket,
  virtualVisit
});

/**
 * Root reducer
 * @param  {Object} state Current state
 * @param  {Object} action Action
 * @returns {Object} App reducer
 */
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

// Create store
const configureStore = initialState => createStore(rootReducer, middlewareList);

export default configureStore;
