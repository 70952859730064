import mixpanel from "mixpanel-browser";

import { mixpanelConfigs } from "../services/mixpanelService";

const { NODE_ENV, REACT_APP_BUILD } = process.env;
const mixpanelConfiguration = mixpanelConfigs[REACT_APP_BUILD];

let prefix =
  localStorage.getItem("access") === "PROVIDER" ? "Provider" : "Staff";
let user_id = localStorage.getItem("user_id");
let empi = localStorage.getItem("empi");
let platform = localStorage.getItem("platform");

const actions = {
  mixpanelInitiated: false,
  track: (name, props, appendPrefix = false) => {
    if (NODE_ENV && mixpanelConfiguration.isActive)
      mixpanel.track(appendPrefix ? `${prefix} - ${name}` : name, {
        user_id,
        empi,
        platform,
        ...props
      });
  },
  register: defaultProps => {
    if (NODE_ENV && mixpanelConfiguration.isActive)
      mixpanel.register(defaultProps);
  },
  unregister: defaultProps => {
    if (NODE_ENV && mixpanelConfiguration.isActive)
      mixpanel.unregister(defaultProps);
  },
  identify: id => {
    if (NODE_ENV && mixpanelConfiguration.isActive) {
      mixpanel.identify(id);
      prefix =
        localStorage.getItem("access") === "PROVIDER" ? "Provider" : "Staff";
    }
  },
  alias: id => {
    if (NODE_ENV && mixpanelConfiguration.isActive) mixpanel.alias(id);
  },
  people: {
    set: props => {
      if (NODE_ENV && mixpanelConfiguration.isActive)
        mixpanel.people.set(props);
    }
  },
  init: () => {
    return new Promise(resolve => {
      const init = mixpanel.init(mixpanelConfiguration.token);
      resolve(init);
    });
  }
};

export { actions as Mixpanel };
