export const mixpanelConfigs = {
  PROD: {
    token: "112b00768252de2f07420f4fabc3ce5d",
    isActive: true
  },
  PREPROD: {
    token: "020cd887c8027525132a483a445ca249",
    isActive: true
  },
  STAGING: {
    token: "",
    isActive: false
  }
};
