import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Avatar from "@datashop/avatar";
import { getLetterIconColor } from "helpers/badgeColor";
import Text from "@datashop/text";
import Icon from "@datashop/icon";
import Options from "./Options";
import { isEmpty, capitalize } from "lodash-es";

const List = styled.div`
  display: flex;
  width: 100%;
  padding: 4px 0;
  min-height: 40px;
  justify-content: space-between;
  border-bottom: solid 1px #e5e5e5;
`;

const ListLeft = styled.div`
  display: flex;
  align-items: center;
`;
const ListRight = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(Icon)`
  color: ${({ color }) => color};
  margin-left: 12px;
`;

const AvatarWrapper = styled(Avatar)`
  margin-right: 8px;
`;
const Wrapper = styled.div`
  width: 48px;
`;

const ListDesign = ({ pData, participant, remove, flowType }) => {
  const [isAudioEnable, setAudio] = useState(true);
  const [isVideoEnable, setVideo] = useState(
    pData.callType === "AUDIO" ? false : true
  );

  const [firstName, lastName] = pData.name.split(" ");

  useEffect(() => {
    if (!isEmpty(participant)) {
      participant.audioTracks.forEach(function(track) {
        if (track.track) {
          setAudio(track.track.isEnabled);
        }
      });
      participant.videoTracks.forEach(function(track) {
        if (track.track) {
          setVideo(track.track.isEnabled);
        }
      });
    }
  });

  const removeHandler = () => {
    remove(pData.twilioParticipantSid);
  };

  return (
    !isEmpty(participant) && (
      <List>
        <ListLeft>
          <AvatarWrapper
            text={[firstName, lastName]
              .map((el, i) => (i <= 1 && el ? el[0] : ""))
              .join("")
              .trimRight()
              .toUpperCase()}
            backgroundColor={getLetterIconColor(firstName, lastName)}
          />
          <Text>{`${firstName} ${lastName ? lastName : ""} ${
            pData.type === "PATIENT" ? `(${capitalize(pData.type)})` : ""
          }`}</Text>
        </ListLeft>
        <ListRight>
          <IconWrapper
            name={isAudioEnable ? "mic" : "mic_off"}
            color={isAudioEnable ? "#2EA843" : "#D93737"}
          />
          <IconWrapper
            name={isVideoEnable ? "videocam" : "videocam_off"}
            color={isVideoEnable ? "#2EA843" : "#D93737"}
          />
        </ListRight>
      </List>
    )
  );
};

export default ListDesign;
