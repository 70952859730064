import React from "react";
import styled from "styled-components";

const ProgressContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

const ProgressPlaceholder = styled.div`
  position: relative;
  background-color: rgb(230, 228, 228);
  border-radius: 15px;
  width: 100%;
  height: 8px;
  margin-bottom: 0.75rem;
`;

const Progress = styled.div`
  background-color: rgb(0, 172, 71);
  border-radius: 15px;
  position: absolute;
  height: 100%;
  width: ${props => (props.activeQuestion / props.totalQuestions) * 100}%;
  transition: width 0.1s linear;
`;

const NumericProgress = styled.div`
  display: flex;
  i,
  img {
    padding: 2px 4px 2px 0;
    position: relative;
    height: 22px;
    width: 20px;
  }
`;

const Language = {
  English: {
    question: "Question",
    of: "of"
  },
  Spanish: {
    question: "Pregunta",
    of: "de"
  }
};

const ProgressBar = props => (
  <ProgressContainer>
    <ProgressPlaceholder>
      <Progress {...props} />
    </ProgressPlaceholder>
    <NumericProgress>
      {!props.puiForm && (
        <div>
          {Language[props.language].question} {props.activeQuestion + 1}{" "}
          {Language[props.language].of} {props.totalQuestions}
        </div>
      )}
    </NumericProgress>
  </ProgressContainer>
);

export default ProgressBar;
