import React, { useState, memo } from "react";
import styled from "styled-components";
import Popup from "@datashop/popup";
import Button from "@datashop/button";
import { Mixpanel } from "helpers/mixpanelHelper";

const MoreButton = styled(Button)`
  margin-left: 32px;
  @media (max-width: 1024px) {
    margin-left: 24px;
  }
`;

const MenuOption = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  padding: 4px 0;
  bottom: 104px;
`;

const ButtonWrapper = styled(Button)`
  color: #2f2f2f;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
`;

const MoreOption = ({
  isTiny,
  copyLink,
  pinVideo,
  isFocusedView,
  changeLayout,
  unPinVideo,
  isMainView,
  remove,
  muteParticipant,
  unMuteParticipant,
  isAudio,
  isLocal,
  flowType
}) => {
  const [showExpanded, toggleExpanded] = useState(false);
  const clickHandler = e => {
    copyLink(e);
    toggleExpanded(false);
  };

  return (
    <Popup
      trigger={
        <MoreButton
          appearance="secondary"
          dimension={isTiny ? "tiny" : "regular"}
        >
          <i className="material-icons">more_vert</i>
        </MoreButton>
      }
      onToggle={open => {
        toggleExpanded(open);
      }}
      position="TopLeft"
      verticalOffset={4}
      horizontalOffset={4}
      open={showExpanded}
    >
      <MenuOption>
        {!isLocal &&
          (flowType === "PROVIDER" || flowType === "STAFF") &&
          isAudio &&
          muteParticipant && (
            <ButtonWrapper
              appearance="transparent"
              onClick={() => {
                muteParticipant();
                toggleExpanded(false);
              }}
            >
              <div style={{ marginLeft: 8 }}>Mute</div>
            </ButtonWrapper>
          )}
        {!isLocal &&
          (flowType === "PROVIDER" || flowType === "STAFF") &&
          !isAudio &&
          unMuteParticipant && (
            <ButtonWrapper
              appearance="transparent"
              onClick={() => {
                unMuteParticipant();
                toggleExpanded(false);
              }}
            >
              <div style={{ marginLeft: 8 }}>Request to unmute</div>
            </ButtonWrapper>
          )}
        {copyLink && (
          <ButtonWrapper appearance="transparent" onClick={clickHandler}>
            <i className="material-icons">content_copy</i>
            <div style={{ marginLeft: 8 }}>Copy invitation link</div>
          </ButtonWrapper>
        )}
        {!isMainView && pinVideo && (
          <ButtonWrapper
            appearance="transparent"
            onClick={() => {
              pinVideo();
              toggleExpanded(false);
            }}
          >
            <div style={{ marginLeft: 8 }}>Pin video</div>
          </ButtonWrapper>
        )}
        {isMainView && unPinVideo && (
          <ButtonWrapper
            appearance="transparent"
            onClick={() => {
              unPinVideo();
              toggleExpanded(false);
            }}
          >
            <div style={{ marginLeft: 8 }}>Switch to tiled layout</div>
          </ButtonWrapper>
        )}
        {changeLayout && (
          <ButtonWrapper
            appearance="transparent"
            onClick={() => {
              Mixpanel.track("View changed");
              changeLayout();
              toggleExpanded(false);
            }}
          >
            <i className="material-icons">
              {isFocusedView ? "view_module" : "view_sidebar"}
            </i>
            <div style={{ marginLeft: 8 }}>
              {isFocusedView
                ? "Switch to tiled layout"
                : "Switch to focused layout"}
            </div>
          </ButtonWrapper>
        )}
        {!isLocal &&
          (flowType === "PROVIDER" || flowType === "STAFF") &&
          remove && (
            <ButtonWrapper
              appearance="transparent"
              onClick={() => {
                remove();
                toggleExpanded(false);
              }}
            >
              <div style={{ marginLeft: 8 }}>Remove</div>
            </ButtonWrapper>
          )}
      </MenuOption>
    </Popup>
  );
};

export default memo(MoreOption);
