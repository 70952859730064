import React from "react";
import MaskedInput from "react-text-mask";
import Input from "@datashop/input";

export const TimeInput = ({ onChange, onBlur, value, error, ...rest }) => {
  /**
   * Generate time mask format
   * @param {String} value
   */
  const generateTimeMaskPattern = value => {
    const chars = value.split("");
    const hours = [/[0-1]/, chars[0] == "1" ? /[0-2]/ : /[0-9]/];
    const minutes = [/[0-5]/, /[0-9]/];
    const amPms = [/[AaPp]/, /[Mm]/];

    return hours
      .concat(":")
      .concat(minutes)
      .concat(" ")
      .concat(amPms);
  };

  return (
    <MaskedInput
      mask={generateTimeMaskPattern}
      placeholder="12:00 pm"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      render={(ref, renderProps) => {
        const { onChange, placeholder, value, onBlur, ...other } = renderProps;
        return (
          <Input
            icon="schedule"
            innerRef={ref}
            inputFormat="timeFormat"
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            value={value}
            error={error}
            style={{ color: error ? "red" : "#2D3142" }}
            {...rest}
            {...other}
          />
        );
      }}
    />
  );
};

export default TimeInput;
