import React, { useState, useEffect, Fragment, useContext } from "react";
import styled from "styled-components";
import { Loader } from "packages/loader";

import VisitRequestModal from "./VisitRequestModal";
import SelectProvider from "./SelectProvider";
import mobileCheck from "helpers/mobileCheck";

// Import Context
import AppProvider from "context/app.context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => (mobileCheck() ? "#f4f4f4" : "#fff")};
  min-height: 450px;
  height: ${props => (mobileCheck() ? "auto" : "700px")};
  position: relative;
`;

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 450px;
  height: 700px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const VisitRequestContainer = props => {
  const { context } = useContext(AppProvider);
  const [userDetails] = useState(context.userDetails);
  const [organization, setOrganization] = useState("");
  const [isLoading, toggleLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dob: "",
    gender: "",
    date: "",
    time: "",
    reasonForVisit: ""
  });

  const selectProvider = provider => {
    setSelectedProvider(provider);
    setIsOpen(false);
  };

  useEffect(() => {
    setPersonalDetails({
      ...personalDetails,
      firstName: userDetails ? userDetails.firstName : "",
      lastName: userDetails ? userDetails.lastName : "",
      email: userDetails ? userDetails.email : "",
      phoneNumber: userDetails ? userDetails.phoneNumber : "",
      dob: userDetails ? userDetails.dob : "",
      gender: userDetails ? userDetails.gender : ""
    });
  }, [userDetails]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const org = params.get("organization");
    setOrganization(org);
    toggleLoading(false);
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <Container>
          {!isOpen && (
            <VisitRequestModal
              practice_id={props.practiceDetails && props.practiceDetails.id}
              organization_id={props.organization_id}
              history={props.history}
              setIsOpen={setIsOpen}
              selectedProvider={selectedProvider}
              personalDetails={personalDetails}
              setPersonalDetails={setPersonalDetails}
            />
          )}
          {isOpen && (
            <SelectProvider
              onClose={setIsOpen}
              selectProvider={selectProvider}
              backIcon={"arrow_back"}
              organizationId={props.organization_id}
            />
          )}
        </Container>
      )}
    </Fragment>
  );
};

export default VisitRequestContainer;
