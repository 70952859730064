import React, { Component } from "react";
import styled from "styled-components";
import Text from "@datashop/text";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const ImageWrapper = styled.div`
  margin: 64px 0 40px 0;
  height: 157px;
  width: 100%;
  text-align: center;
`;

const Title = styled(Text)`
  color: #2d2b2b;
  font-size: 24px;
  text-align: center;
  margin-bottom: 12px;
`;

const Message = styled(Text)`
  color: #4d4b4b;
  font-size: 16px;
  line-height: 21.82px;
  text-align: center;
`;

const NoPage = () => {
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={`${process.env.PUBLIC_URL}/img/noPage.png`} />
      </ImageWrapper>
      <Title>Uh oh! Page not found</Title>
      <Message>
        Sorry, the page you're looking for does not exist. Please check the url
        and search again
      </Message>
    </Wrapper>
  );
};

export default NoPage;
