import React, { Component } from "react";
import styled from "styled-components";
import Text from "@datashop/text";
import Button from "@datashop/button";
import { debounce } from "lodash";
import { Mixpanel } from "helpers/mixpanelHelper";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10% 40px 20%;
  height: 100%;
  width: 100%;
`;

const Heading = styled(Text)`
  color: ${({ theme }) => theme.datashop.palette.neutral.light};
  text-align: center;
  font-size: 26px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionsWrapper = styled.div`
  background-color: white;
  position: absolute;
  width: 100%;
  height: 144px;
  bottom: 0;
  padding: 32px;
  display: flex;
  flex-direction: column;
  margin: 0 -40px;
  box-shadow: 0px -1px #ebebeb;
  border-radius: 18px 18px 0 0;
`;

const getAppUrl = () => {
  const q = window.location.href.split("?")[1];
  let result = {};
  if (q) {
    let pairs = q.split("&");
    pairs.forEach(pair => {
      pair = pair.split("=");
      result[pair[0]] = decodeURIComponent(pair[1]);
    });
  }
  return result;
};

const getMobileOperatingSystem = () => {
  let platform = "";
  var userAgent =
    navigator.userAgent ||
    navigator.vendor ||
    navigator.platform ||
    window.opera;
  if (/windows phone/i.test(userAgent)) {
    platform = "Windows Phone";
  } else if (/android/i.test(userAgent)) {
    platform = "Android";
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    platform = "IOS";
  } else {
    platform = "unknown";
  }
  return platform;
};

const availablePlatforms = ["Android", "IOS"];

class PatientOnboarding extends Component {
  redirectUrls = getAppUrl();
  platform = getMobileOperatingSystem();

  redirectToLink = debounce(link => {
    const a = document.createElement("a");
    a.href = link;
    a.click();
    a.remove();
  }, 300);

  render() {
    const { webUrl, appUrl } = this.redirectUrls;

    if (availablePlatforms.indexOf(this.platform) === -1) {
      return null;
    }

    return (
      <Container>
        <Heading>Download our app for a smoother experience</Heading>
        <ImageWrapper>
          <img
            alt="patientOnboarding"
            style={{ width: "100%" }}
            src={`${process.env.PUBLIC_URL}/img/patientOnboarding.png`}
          />
        </ImageWrapper>
        <ActionsWrapper>
          <Button
            dimension="large"
            appearance="primary"
            onClick={() => {
              Mixpanel.track("App CTA clicked");
              this.redirectToLink(appUrl);
            }}
          >
            Open in app
          </Button>
          <Button
            style={{ marginTop: 8 }}
            dimension="large"
            onClick={() => {
              Mixpanel.track("Browser CTA clicked");
              this.redirectToLink(webUrl);
            }}
          >
            No, I'll continue in browser
          </Button>
        </ActionsWrapper>
      </Container>
    );
  }

  componentDidMount() {
    Mixpanel.track("Intermediary page loads");
    if (availablePlatforms.indexOf(this.platform) === -1) {
      const { webUrl } = this.redirectUrls;
      window.location.replace(webUrl);
    }
  }
}

export default PatientOnboarding;
