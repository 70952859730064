import { transformError } from '../helpers/errorTransformer';
import axios from './axios';

const getEvaluatePatient = (id, responseId) =>
  axios
    .get(`innote-survey/assessments/${id}/_fetch?responseId=${responseId}`)
    .then(({ data }) => data)
    .catch(error => Promise.reject(transformError(error)));

const sendEvaluatePatient = (id, phone, answers, language) =>
  axios
    .patch(`/innote-survey/assessments/${id}/patients/${phone}/_update`, {
      ...answers
    })
    .then(({ data }) => data)
    .catch(error => Promise.reject(transformError(error)));

export {
  getEvaluatePatient as _getEvaluatePatient,
  sendEvaluatePatient as _sendEvaluatePatient
};
