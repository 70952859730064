import React from "react";
import styled, { injectGlobal } from "styled-components";
import { Loader } from "packages/loader";
import { Mixpanel } from "helpers/mixpanelHelper";

const LoaderWrapper = styled.div`
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  position: fixed;
  height: 32px;
  margin: auto;
`;

class App extends React.Component {
  lastestVersion;
  interval;
  state = {
    issueId: 0,
    showToast: false,
    isUpdateAvailable: false
  };

  componentWillMount() {
    Mixpanel.init().then(() => {
      Mixpanel.mixpanelInitiated = true;
    });
  }

  openDevTools = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("openDevTools");
    }
  };

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          this.props.children
        )}
      </>
    );
  }
}

export default App;
