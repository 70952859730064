import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  padding: 24px 16px 160px;
`;

const SubHeader = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 16px 0 8px;
`;

const Text = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const Bold = styled.span`
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-top: 12px;
`;

const ArbitrationNotice = styled.div`
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin: 8px 0;
`;

const TermsOfUse = () => (
  <Wrapper>
    <ContentWrapper>
      <Text>
        Welcome, and thank you for your interest in Innovaccer Inc. (“
        <Bold>Innovaccer</Bold>,” “<Bold>we</Bold>,” or “<Bold>us</Bold>”) and
        our Telemedicine solution, along with our related websites, networks,
        applications, mobile applications, and other services provided by us
        (collectively, the “<Bold>Service</Bold>”). These Terms of Service are a
        legally binding contract between you and Innovaccer regarding your use
        of the Service.
      </Text>
      <SubHeader>Please Read the following terms carefully.</SubHeader>
      <Text>
        <Bold>
          By downloading, installing, or otherwise accessing or using the
          service, you agree that you have read and understood, and, as a
          condition to your use of the service
        </Bold>
        , you agree to be bound by, the following terms and conditions,
        including innovaccer’s Privacy policy (together, these “terms”). If you
        are not eligible, or do not agree to the terms, then you do not have our
        permission to use the service. Your use of the service, and innovaccer’s
        provision of the service to you, constitutes an agreement by innovaccer
        and by you to be bound by these terms.
      </Text>
      <ArbitrationNotice>Arbitration Notice</ArbitrationNotice>
      <Text>
        Except for certain kinds of disputes described in section 12, you agree
        that disputes arising under these terms will be resolved by binding,
        individual Arbitration, and by accepting these terms, you and innovaccer
        are each waiving the right to a trial by jury or to participate in any
        class action or representative proceeding. You agree to give up your
        right to go to court to assert or defend your rights under this contract
        (except for matters that may be taken to small claims court). Your
        rights will be Determined by a neutral arbitrator and not a judge or
        jury. (see section 12.)
      </Text>
      <Text>
        <Bold>1. Service Overview and Disclaimers</Bold>
      </Text>
      <Text>
        {" "}
        &emsp;&emsp;The Innovaccer Telemedicine solution is used to help your
        medical professional conduct your screening assessment quickly and
        remotely.{" "}
      </Text>
      <Text>
        {" "}
        &emsp;&emsp;Any educational materials you receive on the website or app
        are not medical advice, not intended to replace consultation with a
        healthcare professional for the diagnosis, cure, mitigation, treatment
        or prevention of disease, injury or other conditions, and are provided
        for informational purposes only.
      </Text>
      <Text>
        {" "}
        <Bold>2. Eligibility</Bold>
      </Text>
      <Text>
        {" "}
        &emsp;&emsp;You must be at least 13 years old to use the Service. By
        agreeing to these Terms, you represent and warrant to us that: (a) you
        are at least 13 years old; (b) you have not previously been suspended or
        removed from the Service; and (c) your registration and your use of the
        Service is in compliance with any and all applicable laws and
        regulations. If you are an entity, organization, or company, the
        individual accepting these Terms on your behalf represents and warrants
        that they have authority to bind you to these Terms and you agree to be
        bound by these Terms.{" "}
      </Text>
      <Text>
        {" "}
        <Bold>3. Licenses</Bold>
      </Text>
      <Text>
        {" "}
        <Bold> 3.1 Limited License-</Bold> Subject to your complete and ongoing
        compliance with these Terms, Innovaccer grants you, solely for your
        personal, non- commercial use, a limited, non-exclusive,
        non-transferable, non- sublicensable, revocable license to access and
        use the Service.
      </Text>
      <Text>
        {" "}
        <Bold> 3.2 License Restrictions-</Bold> Except and solely to the extent
        such a restriction is impermissible under applicable law, you may not:
        (a) reproduce, distribute, publicly display, or publicly perform the
        Service; (b) make modifications to the Service; or (c) interfere with or
        circumvent any feature of the Service, including any security or access
        control mechanism. If you are prohibited under applicable law from using
        the Service, you may not use it.
      </Text>
      <Text>
        {" "}
        <Bold> 3.3 Feedback-</Bold> If you choose to provide input and
        suggestions regarding problems with or proposed modifications or
        improvements to the Service (“Feedback”), then you hereby grant
        Innovaccer an unrestricted, perpetual, irrevocable, non-exclusive,
        fully-paid, royalty-free right to exploit the Feedback in any manner and
        for any purpose, including to improve the Service and create other
        products and services.{" "}
      </Text>
      <Text>
        {" "}
        <Bold>4. Ownership; Proprietary Rights</Bold>
      </Text>
      <Text>
        {" "}
        &emsp;&emsp;The Service is owned and operated by Innovaccer. The visual
        interfaces, graphics, design, compilation, information, data, computer
        code (including source code or object code), products, software,
        services, and all other elements of the Service (“Materials”) provided
        by Innovaccer are protected by intellectual property and other laws. All
        Materials included in the Service are the property of Innovaccer or its
        third party licensors. Except as expressly authorized by Innovaccer, you
        may not make use of the Materials. Innovaccer reserves all rights to the
        Materials not granted expressly in these Terms.
      </Text>
      <Text>
        {" "}
        <Bold> 5. Third Party Terms</Bold>
      </Text>
      <Text>
        {" "}
        <Bold> 5.1 Linked Websites-</Bold> The Service may contain links to
        third party websites. Linked websites are not under Innovaccer’s
        control, and Innovaccer is not responsible for their content.
      </Text>
      <Text>
        {" "}
        <Bold> 5.2 Third Party Software-</Bold> The Service may include or
        incorporate third party software components that are generally available
        free of charge under licenses granting recipients broad rights to copy,
        modify, and distribute those components (“Third Party Components”).
        Although the Service is provided to you subject to these Terms, nothing
        in these Terms prevents, restricts, or is intended to prevent or
        restrict you from obtaining Third Party Components under the applicable
        third party licenses or to limit your use of Third Party Components
        under those third party licenses.{" "}
      </Text>
      <Text>
        {" "}
        <Bold>6. Communication</Bold>
      </Text>
      <Text>
        {" "}
        &emsp;&emsp;Innovaccer or your medical provider may send you text (SMS)
        messages at the phone number you provide your medical provider. These
        messages may include operational messages about your use of the Service.
        You may opt out of receiving text messages at any time by sending an
        email to vcnsupport@innovaccer.com indicating that you no longer wish to
        receive text messages along with the phone number of the mobile device
        receiving texts. You may continue to receive text messages for a short
        period while we process your request, and you may also receive text
        messages confirming the receipt of your opt-out request. Text messages
        may be sent using an automatic telephone dialing system. Standard data
        and message rates may apply whenever you send or receive such messages,
        as specified by your carrier.
      </Text>
      <Text>
        {" "}
        <Bold> 7. Prohibited Conduct</Bold>
      </Text>
      <Text>
        {" "}
        <Bold> BY USING THE SERVICE YOU AGREE NOT TO:</Bold>
      </Text>
      <Text>
        {" "}
        a. use the Service for any illegal purpose or in violation of any local,
        state, national, or international law;
      </Text>
      <Text>
        {" "}
        b. interfere with security-related features of the Service, including
        by: (i) disabling or circumventing features that prevent or limit use or
        copying of any content; or (ii) reverse engineering or otherwise
        attempting to discover the source code of any portion of the Service
        except to the extent that the activity is expressly permitted by
        applicable law;
      </Text>
      <Text>
        c. perform any fraudulent activity including impersonating any person or
        entity;
      </Text>
      <Text>
        {" "}
        d. sell or otherwise transfer the access granted under these Terms or
        any Materials (as defined in Section 4) or any right or ability to view,
        access, or use any Materials; or
      </Text>
      <Text>
        {" "}
        e. attempt to do any of the acts described in this Section 7 or assist
        or permit any person in engaging in any of the acts described in this
        Section 7.
      </Text>
      <Text>
        {" "}
        <Bold> 8. Term, Termination and Modification of the Service</Bold>
      </Text>
      <Text>
        <Bold>8.1 Term-</Bold> These Terms are effective beginning when you
        accept the Terms or first access the Service, and ending when terminated
        as described in Section 7.2.
      </Text>
      <Text>
        {" "}
        <Bold> 8.2 Termination-</Bold> If you violate any provision of these
        Terms, your authorization to access the Service and these Terms
        automatically terminate. In addition, Innovaccer may, at its sole
        discretion, terminate these Terms, or suspend or terminate your access
        to the Service, at any time for any reason or no reason, with or without
        notice.{" "}
      </Text>
      <Text>
        {" "}
        <Bold>8.3 Effect of Termination- </Bold>Upon termination of these Terms:
        (a) your license rights will terminate and you must immediately cease
        all use of the Service. Sections 3.3, 4, 8.3, 9, 10, 11, 12 and 13 will
        survive.
      </Text>
      <Text>
        {" "}
        <Bold> 8.4 Modification of the Service-</Bold> Innovaccer reserves the
        right to modify or discontinue the Service at any time (including by
        limiting or discontinuing certain features of the Service), temporarily
        or permanently, without notice to you. Innovaccer will have no liability
        for any change to the Service or any suspension or termination of your
        access to or use of the Service.
      </Text>
      <Text>
        {" "}
        <Bold> 9. Indemnity</Bold>
      </Text>
      <Text>
        {" "}
        &emsp;&emsp;To the fullest extent permitted by law, you are responsible
        for your use of the Service, and you will defend and indemnify
        Innovaccer and its officers, directors, employees, consultants,
        affiliates, subsidiaries and agents (together, the “Innovaccer
        Entities”) from and against every claim brought by a third party, and
        any related liability, damage, loss, and expense, including reasonable
        attorneys’ fees and costs, arising out of or connected with: (a) your
        unauthorized use of, or misuse of, the Service; (b) your violation of
        any portion of these Terms, any representation, warranty, or agreement
        referenced in these Terms, or any applicable law or regulation; (c) your
        violation of any third party right, including any intellectual property
        right or publicity, confidentiality, other property, or privacy right;
        or (d) any dispute or issue between you and any third party. We reserve
        the right, at our own expense, to assume the exclusive defense and
        control of any matter otherwise subject to indemnification by you
        (without limiting your indemnification obligations with respect to that
        matter), and in that case, you agree to cooperate with our defense of
        those claims.
      </Text>
      <Text>
        {" "}
        <Bold> 10. Disclaimers; No Warranties</Bold>
      </Text>
      <Text>
        {" "}
        &emsp;&emsp;The service and all materials and content available through
        the service are provided “as is” and on an “as available” basis.
        Innovaccer disclaims all warranties of any kind, whether express or
        implied, relating to the service and all materials and content available
        through the service, including: (a) any implied warranty of
        merchantability, fitness for a particular purpose, title, quiet
        enjoyment, or non-infringement; and (b) any warranty arising out of
        course of dealing, usage, or trade. Innovaccer does not warrant that the
        service or any portion of the service, or any materials or content
        offered through the service, will be uninterrupted, secure, or free of
        errors, viruses, or other harmful components, and innovaccer does not
        warrant that any of those issues will be corrected.{" "}
      </Text>
      <Text>
        &emsp;&emsp;No advice or information, whether oral or written, obtained
        by you from the service or innovaccer entities or any materials or
        content available through the service will create any warranty regarding
        any of the innovaccer entities or the service that is not expressly
        stated in these terms. We are not responsible for any damage that may
        result from the service and your dealing with any other service user.
        You understand and agree that you use any portion of the service at your
        own discretion and risk, and that we are not responsible for any damage
        to your property (including your computer system or mobile device used
        in connection with the service) or any loss of data.{" "}
      </Text>
      <Text>
        &emsp;&emsp;The limitations, exclusions and disclaimers in this section
        apply to the fullest extent permitted by law. Innovaccer does not
        disclaim any warranty or other right that innovaccer is prohibited from
        disclaiming under applicable law.
      </Text>
      <Text>
        <Bold>11. Limitation of Liability</Bold>
      </Text>
      <Text>
        {" "}
        &emsp;&emsp;To the fullest extent permitted by law, in no event will the
        innovaccer entities be liable to you for any indirect, incidental,
        special, consequential or punitive damages (including damages for loss
        of profits, goodwill, or any other intangible loss) arising out of or
        relating to your access to or use of, or your inability to access or
        use, the service or any materials or content on the service, whether
        based on warranty, contract, tort (including negligence), statute, or
        any other legal theory, and whether or not any innovaccer entity has
        been informed of the possibility of damage.{" "}
      </Text>
      <Text>
        &emsp;&emsp;Notwithstanding anything to the contrary herein or anywhere
        else, innovaccer does not assume any liability for the results or
        consequences occurring out of the use of the services. You acknowledge
        and agree innovaccer is merely facilitating a consultation or visit
        between you and your medical provider and shall in no way be responsible
        for any making medical judgments, and the results of any medical
        determinations and any and all treatment decisions based thereon.
        Innovaccer expressly disclaims any and all responsibility for any
        medical errors, injuries, or similar claims arising from your use or
        misuse of the services in connection with any medical diagnosis or
        treatment.{" "}
      </Text>
      <Text>
        &emsp;&emsp;Except as provided in section 12.5 and to the fullest extent
        permitted by law, the aggregate liability of the innovaccer entities to
        you for all claims arising out of or relating to the use of or any
        inability to use any portion of the service or otherwise under these
        terms, whether in contract, tort, or otherwise, is limited to $100. Each
        provision of these terms that provides for a limitation of liability,
        disclaimer of warranties, or exclusion of damages is intended to and
        does allocate the risks between the parties under these terms. This
        allocation is an essential element of the basis of the bargain between
        the parties. Each of these provisions is severable and independent of
        all other provisions of these terms. The limitations in this section 11
        will apply even if any limited remedy fails of its essential purpose.
      </Text>
      <Text>
        <Bold>12. Dispute Resolution and Arbitration</Bold>
      </Text>
      <Text>
        <Bold> 12.1 Generally-</Bold> In the interest of resolving disputes
        between you and Innovaccer in the most expedient and cost effective
        manner, and except as described in Section 12.2 and 12.3, you and
        Innovaccer agree that every dispute arising in connection with these
        Terms will be resolved by binding arbitration. Arbitration is less
        formal than a lawsuit in court. Arbitration uses a neutral arbitrator
        instead of a judge or jury, may allow for more limited discovery than in
        court, and can be subject to very limited review by courts. Arbitrators
        can award the same damages and relief that a court can award. This
        agreement to arbitrate disputes includes all claims arising out of or
        relating to any aspect of these Terms, whether based in contract, tort,
        statute, fraud, misrepresentation, or any other legal theory, and
        regardless of whether a claim arises during or after the termination of
        these Terms.{" "}
        <Bold>
          You understand and agree that, by entering into these terms, you and
          innovaccer are each waiving the right to a trial by jury or to
          participate in a class action.
        </Bold>
      </Text>
      <Text>
        {" "}
        <Bold>12.2 Exceptions-</Bold>
      </Text>
      <Text>
        {" "}
        Despite the provisions of Section 12.1, nothing in these Terms will be
        deemed to waive, preclude, or otherwise limit the right of either party
        to: (a) bring an individual action in small claims court; (b) pursue an
        enforcement action through the applicable federal, state, or local
        agency if that action is available; (c) seek injunctive relief in a
        court of law in aid of arbitration; or (d) to file suit in a court of
        law to address an intellectual property infringement claim.{" "}
      </Text>
      <Text>
        {" "}
        <Bold>12.3 Opt-Out- </Bold>If you do not wish to resolve disputes by
        binding arbitration, you may opt out of the provisions of this Section
        12 within 30 days after the date that you agree to these Terms by
        sending a letter to Innovaccer Inc., Attention: Legal Department –
        Arbitration Opt-Out, Suite 1829, Floor 18th, 535 Mission St, San
        Francisco, CA 94105 that specifies: your full legal name and a statement
        that you wish to opt out of arbitration (“Opt-Out Notice”). Once
        Innovaccer receives your Opt-Out Notice, this Section 12 will be void
        and any action arising out of these Terms will be resolved as set forth
        in Section 13.2. The remaining provisions of these Terms will not be
        affected by your Opt-Out Notice.{" "}
      </Text>
      <Text>
        <Bold>12.4 Arbitrator-</Bold> Any arbitration between you and Innovaccer
        will be settled under the Federal Arbitration Act and administered by
        the American Arbitration Association (“AAA”) under its Consumer
        Arbitration Rules (collectively, “AAA Rules”) as modified by these
        Terms. The AAA Rules and filing forms are available online at
        www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting
        Innovaccer. The arbitrator has exclusive authority to resolve any
        dispute relating to the interpretation, applicability, or enforceability
        of this binding arbitration agreement.
      </Text>
      <Text>
        {" "}
        <Bold> 12.5 Notice of Arbitration; Process-</Bold> A party who intends
        to seek arbitration must first send a written notice of the dispute to
        the other party by certified U.S. Mail or by Federal Express (signature
        required) or, only if that other party has not provided a current
        physical address, then by electronic mail (“Notice of Arbitration”).
        Innovaccer’s address for Notice is: Innovaccer Inc., Suite 1829, Floor
        18th, 535 Mission St, San Francisco, CA 94105. The Notice of Arbitration
        must: (a) describe the nature and basis of the claim or dispute; and (b)
        set forth the specific relief sought (“Demand”). The parties will make
        good faith efforts to resolve the claim directly, but if the parties do
        not reach an agreement to do so within 30 days after the Notice of
        Arbitration is received, you or Innovaccer may commence an arbitration
        proceeding. All arbitration proceedings between the parties will be
        confidential unless otherwise agreed by the parties in writing. During
        the arbitration, the amount of any settlement offer made by you or
        Innovaccer must not be disclosed to the arbitrator until after the
        arbitrator makes a final decision and award, if any. If the arbitrator
        awards you an amount higher than the last written settlement amount
        offered by Innovaccer in settlement of the dispute prior to the award,
        Innovaccer will pay to you the higher of: (i) the amount awarded by the
        arbitrator; or (ii) $10,000.
      </Text>
      <Text>
        <Bold> 12.6 Fees-</Bold> If you commence arbitration in accordance with
        these Terms, Innovaccer will reimburse you for your payment of the
        filing fee, unless your claim is for more than $10,000, in which case
        the payment of any fees will be decided by the AAA Rules. Any
        arbitration hearing will take place at a location to be agreed upon in
        san Francisco County, California, but if the claim is for $10,000 or
        less, you may choose whether the arbitration will be conducted: (a)
        solely on the basis of documents submitted to the arbitrator; (b)
        through a non-appearance based telephone hearing; or (c) by an in-person
        hearing as established by the AAA Rules in the county (or parish) of
        your billing address. If the arbitrator finds that either the substance
        of your claim or the relief sought in the Demand is frivolous or brought
        for an improper purpose (as measured by the standards set forth in
        Federal Rule of Civil Procedure 11(b)), then the payment of all fees
        will be governed by the AAA Rules. In that case, you agree to reimburse
        Innovaccer for all monies previously disbursed by it that are otherwise
        your obligation to pay under the AAA Rules. Regardless of the manner in
        which the arbitration is conducted, the arbitrator must issue a reasoned
        written decision sufficient to explain the essential findings and
        conclusions on which the decision and award, if any, are based. The
        arbitrator may make rulings and resolve disputes as to the payment and
        reimbursement of fees or expenses at any time during the proceeding and
        upon request from either party made within 14 days of the arbitrator’s
        ruling on the merits.
      </Text>
      <Text>
        <Bold> 12.7 No Class Actions-</Bold> YOU AND INNOVACCER AGREE THAT EACH
        MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
        CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
        OR REPRESENTATIVE PROCEEDING. Further, unless both you and Innovaccer
        agree otherwise, the arbitrator may not consolidate more than one
        person’s claims, and may not otherwise preside over any form of a
        representative or class proceeding.
      </Text>
      <Text>
        <Bold> 12.8 Enforceability-</Bold> If Section 12.7 or the entirety of
        this Section 12 is found to be unenforceable, or if Innovaccer receives
        an Opt-Out Notice from you, then the entirety of this Section 12 will be
        null and void and, in that case, the exclusive jurisdiction and venue
        described in Section 13.2 will govern any action arising out of or
        related to these Terms.
      </Text>
      <Text>
        <Bold> Miscellaneous</Bold>
      </Text>
      <Text>
        <Bold> 13.1 General Terms-</Bold> These Terms, together with the Privacy
        Policy and any other agreements expressly incorporated by reference into
        these Terms, are the entire and exclusive understanding and agreement
        between you and Innovaccer regarding your use of the Service. You may
        not assign or transfer these Terms or your rights under these Terms, in
        whole or in part, by operation of law or otherwise, without our prior
        written consent. We may assign these Terms at any time without notice or
        consent. The failure to require performance of any provision will not
        affect our right to require performance at any other time after that,
        nor will a waiver by us of any breach or default of these Terms, or any
        provision of these Terms, be a waiver of any subsequent breach or
        default or a waiver of the provision itself. Use of section headers in
        these Terms is for convenience only and will not have any impact on the
        interpretation of any provision. Throughout these Terms the use of the
        word “including” means “including but not limited to”. If any part of
        these Terms is held to be invalid or unenforceable, the unenforceable
        part will be given effect to the greatest extent possible, and the
        remaining parts will remain in full force and effect.{" "}
      </Text>
      <Text>
        <Bold>13.2 Governing Law-</Bold> These Terms are governed by the laws of
        the State of California without regard to conflict of law principles.
        You and Innovaccer submit to the personal and exclusive jurisdiction of
        the state courts and federal courts located within San Francisco County,
        California for resolution of any lawsuit or court proceeding permitted
        under these Terms.{" "}
      </Text>
      <Text>
        <Bold>13.3 Privacy Policy-</Bold>
        Please read the Innovaccer Privacy Policy carefully for information
        relating to our collection, use, storage, disclosure of your personal
        information. The Innovaccer Privacy Policy is incorporated by this
        reference into, and made a part of, these Terms.{" "}
      </Text>
      <Text>
        <Bold>13.4 Additional Terms-</Bold> Your use of the Service is subject
        to all additional terms, policies, rules, or guidelines applicable to
        the Service or certain features of the Service that we may post on or
        link to from the Service (the “Additional Terms”). All Additional Terms
        are incorporated by this reference into, and made a part of, these
        Terms.
      </Text>
      <Text>
        <Bold> 13.5 Consent to Electronic Communications-</Bold> By using the
        Service, you consent to receiving certain electronic communications from
        us as further described in our Privacy Policy. Please read our Privacy
        Policy to learn more about our electronic communications practices. You
        agree that any notices, agreements, disclosures, or other communications
        that we send to you electronically will satisfy any legal communication
        requirements, including that those communications be in writing.{" "}
      </Text>
      <Text>
        <Bold>13.6 Contact Information- </Bold>The Service is offered by
        Innovaccer Inc., located at Suite 1829, Floor 18th, 535 Mission St, San
        Francisco, CA 94105. You may contact us by sending correspondence to
        that address or by emailing us at vcnsupport@innovaccer.com.
      </Text>
      <Text>
        <Bold> 13.7 Notice to California Residents-</Bold>
        If you are a California resident, under California Civil Code Section
        1789.3, you may contact the Complaint Assistance Unit of the Division of
        Consumer Services of the California Department of Consumer Affairs in
        writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California
        95834, or by telephone at (800) 952-5210 in order to resolve a complaint
        regarding the Service or to receive further information regarding use of
        the Service.{" "}
      </Text>
      <Text>
        <Bold>13.8 No Support-</Bold>
        We are under no obligation to provide support for the Service. In
        instances where we may offer support, the support will be subject to
        published policies.{" "}
      </Text>
      <Text>
        <Bold>13.9 International Use-</Bold> The Service is intended for
        visitors located within the United States. We make no representation
        that the Service is appropriate or available for use outside of the
        United States. Access to the Service from countries or territories or by
        individuals where such access is illegal is prohibited.
      </Text>
    </ContentWrapper>
  </Wrapper>
);

export default TermsOfUse;
