import React from 'react';
import styled, { keyframes } from 'styled-components';

// Rotation animation
const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
`;

// Turn animation
const turn = keyframes`
  0% {
    stroke-dashoffset: 180;
  }
  50% {
    stroke-dashoffset: 45;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 180;
    transform: rotate(450deg);
  }
`;

// Wrapper
const Wrapper = styled.div`
  text-align: center;
`;

// SVG root node
const SVG = styled.svg`
  stroke: ${({ stroke }) => stroke};
  animation: ${rotation} 1.35s linear infinite;
`;

// SVG Circle node
const Circle = styled.circle`
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${turn} 1.35s ease-in-out infinite;
`;

/**
 * Loader component
 */
const Loader = props => (
  <Wrapper>
    <SVG
      className='spinner'
      width={props.width || '24px'}
      height={props.height || '24px'}
      stroke={props.stroke || '#2d3142'}
      viewBox='0 0 64 64'
      xmlns='http://www.w3.org/2000/svg'>
      <Circle
        className='circle'
        fill='none'
        strokeWidth={`${props.strokeWidth || '4'}`}
        strokeLinecap='round'
        cx='32'
        cy='32'
        r='28'
      />
    </SVG>
  </Wrapper>
);

export default Loader;
