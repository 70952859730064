import React, { Component } from 'react';
import styled from 'styled-components';

const TextWrapper = styled.form`
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid rgb(232, 228, 228);
  flex: 1;
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  cursor: default;
  background-color: #fff;

  &:last-child {
    ${TextWrapper} {
      border-bottom: none;
    }
  }
`;

const TextField = styled.input`
  padding: 8px;
  outline: none;
  width: 100%;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
`;

class RangeQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = { text: '' };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ text: this.props.question.selectedAnswerId });
  }

  handleChange(event) {
    this.setState({ text: event.target.value });
    this.props.onTextChangeHandler(event.target.value, this.props.question.id);
  }

  render() {
    const {
      question: { id }
    } = this.props;

    return (
      <Item>
        <TextWrapper key={id}>
          <TextField
            name='text'
            type='number'
            data-test='email'
            placeholder='Enter number'
            autoComplete='off'
            autoFocus
            value={this.state.text}
            onChange={this.handleChange}
            required
            expanded
          />
        </TextWrapper>
      </Item>
    );
  }
}

export default RangeQuestion;
