import authApi from "./authApi";

const refreshToken = () => {
  const payload = {
    refreshToken: localStorage.getItem("refreshToken")
  };
  return authApi.post("/auth/refresh_token", payload);
};

export default refreshToken;
