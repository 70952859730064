import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Icon from "@datashop/icon";

import AppContext from "context/app.context";
import { Loader } from "../../packages/loader/";
import authApi from "../../helpers/authApi";
import mobileCheck from "../../helpers/mobileCheck";

const LogOutWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 25px;
  padding: ${(props) => (mobileCheck() ? "20px 15px 20px" : "0 15px 20px")};
`;

const LogoutButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const LogoutIcon = styled(Icon)`
  display: flex;
  font-size: 18px;

  + i {
    top: 12px;
  }
`;

const LogoutLabel = styled.label`
  margin-left: 5px;
  cursor: pointer;
`;

export const LogoutComponent = () => {
  const { context, setContext } = React.useContext(AppContext);
  const [isLoggedIn, setIsLoggedIn] = useState(context.isLoggedIn);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsLoggedIn(context.isLoggedIn);
  }, [context.isLoggedIn]);

  /**
   * Logout the current user
   * --> Invalidates the auth token
   */

  const logout = () => {
    setIsLoggingOut(true);
    authApi.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    authApi.post("auth/logout/", {}).then(() => {
      setIsLoggingOut(false);
      setContext({ ...context, isLoggedIn: false });
      window.localStorage.clear();
      history.push("/signup");
    });
  };

  return (
    <>
      {isLoggedIn && (
        <LogOutWrapper data-test="wrapper__logout-wrapper">
          <LogoutButton
            onClick={() => logout()}
            data-test="wrapper__logout-button"
          >
            {isLoggingOut ? (
              <Loader stroke="#cccc" />
            ) : (
              <LogoutIcon name="logout" />
            )}
            <LogoutLabel>Logout</LogoutLabel>
          </LogoutButton>
        </LogOutWrapper>
      )}
    </>
  );
};
