import React, { Fragment, useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Icon from "@datashop/icon";
import Input from "@datashop/input";
import Avatar from "@datashop/avatar";
import Card from "@datashop/card";
import Text from "@datashop/text";
import { Loader } from "packages/loader";
import { Modal, motion } from "@krishnaxv/react-surface";
import mobileCheck from "helpers/mobileCheck";
import providerApi from "helpers/providerApi";
import returnCamelCasedName from "helpers/nameTransformer";

const LoaderWrapper = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

// Should be used to position modal on the surface
const modalStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  position: "absolute",
  backgroundColor: "#eeeeee"
};

const cardStyles = {
  height: "70px",
  width: "100%",
  alignItems: "center",
  display: "flex",
  backgroundColor: "white",
  padding: "10px 15px",
  cursor: "pointer",
  marginBottom: "10px",
  borderRadius: "5px",
  border: "none",
  boxShadow: "rgba(0, 0, 0, 0.16) 0 2px 3px 0"
};

const avatarStyles = {
  marginRight: "20px",
  height: "48px",
  width: "48px"
};

const StyledSearchField = styled(Input)`
  height: 50px;
  border-radius: 5px;
  border: solid 1px #e6e6e6;

  + i {
    color: #d2d2d2;
    font-size: 20px;
    position: absolute;
    left: 8px;
    vertical-align: middle;
    top: auto;
  }
`;
const BodyWrapper = styled.div`
  margin-top: 15px;
  height: ${props => (mobileCheck() ? "100vh" : "700px")};
  overflow-y: auto;
`;
const ModalWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`;
const ModalContainer = styled.div`
  > div {
    width: 100%;
    height: ${props => (mobileCheck() ? "100vh" : "700px")};
    display: flex;
    left: 0;
    background: none;
    justify-content: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute !important;
    border-radius: 5px;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const GoBack = styled(Icon)`
  font-size: 28px;
  color: #2f2f2f;
  margin-bottom: 20px;
  cursor: pointer;
`;
const StyedHeader = styled(Text)`
  color: #151414;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 15px;
`;
const Label = styled.div`
  display: flex;
  color: #2f2f2f;
  font-weight: 601;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  margin-bottom: 5px;
`;

const Position = styled(Text)`
  display: flex;
  line-height: 16px;
  align-items: center;
  color: #abaaaa;
  font-weight: 600;
`;

const NoProviders = styled(Text)`
  padding: 16px;
  font-size: 16px;
  color: grey;
  display: flex;
  justify-content: center;
`;

const SelectProvider = ({
  onClose,
  selectProvider,
  organizationId,
  backIcon,
  fetchOnline = false
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [availableProviders, setAvailableProviders] = useState(null);
  const [cachedProviders, setCachedProviders] = useState([]);

  useEffect(() => {
    loadProviders();
  }, []);

  const loadProviders = () => {
    setIsLoading(true);
    providerApi.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "token"
    )}`;
    providerApi
      .get(
        `/innote-survey/user/organization/${organizationId}/providers?userType=PROVIDER&availableWalkIn=1${
          fetchOnline ? "&socketAlive=1" : ""
        }`
      )
      .then(({ data: { userList = [] } }) => {
        const newList = userList.map(item => ({
          value: item.userId,
          profession: "",
          shortName: generateUserShortName(item.name),
          label:
            `${item.name.title ? item.name.title : ""} ` +
            returnCamelCasedName(item.name.firstName, item.name.lastName)
        }));

        setAvailableProviders([...newList]);
        setCachedProviders([...newList]);
        setIsLoading(false);
      });
  };

  const generateUserShortName = ({ firstName, lastName }) => {
    return (
      (firstName ? firstName[0].toUpperCase() : "") +
      (lastName ? lastName[0].toUpperCase() : "")
    );
  };

  const filterProviders = searchTerm => {
    return availableProviders.filter(prov =>
      prov.label.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
  };

  const handleSearch = event => {
    const { value } = event.target;
    value
      ? setAvailableProviders(filterProviders(value))
      : setAvailableProviders(cachedProviders);
  };

  const generateProvidersList = providerList => {
    let content = "";

    if (providerList && providerList.length) {
      content = providerList.map(provider => (
        <Card
          key={provider.value}
          style={cardStyles}
          onClick={() => selectProvider(provider)}
        >
          <Avatar
            style={avatarStyles}
            alt={provider.label}
            text={provider.shortName}
          />
          <div>
            <Label>{provider.label}</Label>
            <Position>{provider.profession || "Provider"}</Position>
          </div>
        </Card>
      ));
    } else {
      content =
        providerList && providerList.length === 0 ? (
          <NoProviders>No providers available now!</NoProviders>
        ) : (
          ""
        );
    }

    return content;
  };

  return (
    <Fragment>
      <ModalContainer>
        <Modal
          motion={motion.slideInBottom}
          style={modalStyle}
          onClose={onClose}
          hideScroll={false}
        >
          {onClose => (
            <ModalWrapper>
              <HeaderWrapper>
                <GoBack name={backIcon} onClick={() => onClose(false)} />
                <StyedHeader>Select your provider</StyedHeader>
                <StyledSearchField
                  placeholder="Search for provider"
                  icon="search"
                  onChange={handleSearch}
                />
              </HeaderWrapper>
              <BodyWrapper>
                {isLoading && (
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                )}
                {generateProvidersList(availableProviders)}
              </BodyWrapper>
            </ModalWrapper>
          )}
        </Modal>
      </ModalContainer>
    </Fragment>
  );
};

export default SelectProvider;
