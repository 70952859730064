import React, { Component, Fragment } from "react";
import styled from "styled-components";
import Card from "@datashop/card";
import Button from "@datashop/button";
import axios, { patientOutreachUrl } from "helpers/axios";
import { Redirect } from "react-router-dom";

import NextStep from "../NextStep/NextStep";
import { Mixpanel } from "helpers/mixpanelHelper";
import { Loader } from "packages/loader";
import createToast from "helpers/toastHelper";
import Placeholder from "@datashop/placeholder";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

const RequestVisitWrapper = styled(Card)`
  margin-top: 16px;
  display: flex;
  background: linear-gradient(180deg, #f1f8ff 0%, #f1f7fd 100%);
  padding: 0 12px 0 0;
  justify-content: space-between;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const TalkToDoctor = styled.p`
  font-weight: bold;
  letter-spacing: -0.51px;
  line-height: 24px;
`;

const RecieveText = styled.p`
  color: #868686;
  margin-top: 8px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 16px;
  width: 232px;
`;

const RequestVisitText = styled.p`
  margin-top: 24px;
  color: ${({ color }) => color && color};
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 20px;
  cursor: ${({ cursor }) => cursor && cursor};
`;

const DoctorWrapper = styled.div`
  display: flex;
  align-self: flex-end;
`;

const Doctor = styled.img`
  height: 91px;
`;

const SuccessCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 12px;
`;

const Result = styled.div`
  color: #151111;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 2px;
`;

const ShowResult = styled.div`
  color: #929292;
  line-height: 20px;
  margin-bottom: 4px;
`;

const SubResult = styled.div`
  color: #868686;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 8px;
`;

const Icon = styled.img`
  width: 64px;
  margin-bottom: 16px;
  align-self: center;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px;
`;

const CardSuccess = styled(Card)`
  margin-top: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  justify-content: space-between;
`;

const NextSteps = styled.div`
  color: #151414;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
`;

const ReportWrapper = styled.div`
  display: flex;
`;

const ReportTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const TickIcon = styled.i`
  font-size: 16px;
  margin-top: 2px;
`;

const ReportText = styled.div`
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 2px;
`;

const ReportSubText = styled.div`
  color: #868686;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

const ButtonWrapper = styled.div`
  margin: 16px 0 0 12px;
  display: flex;
`;

const SuccessState = {
  HIGH: {
    iconSource: `${process.env.PUBLIC_URL}/img/covidLikely.png`,
    result: [
      "Difficulty breathing or shortness of breath",
      "Persistent pain or pressure in the chest",
      "New confusion or inability to arouse",
      "Bluish lips or face"
    ],
    subResult:
      "Based on the assessment responses, patient is likely to have COVID-19"
  },
  LOW: {
    iconSource: `${process.env.PUBLIC_URL}/img/noRisk.png`,
    result: "There’s no risk",
    subResult: "Based on the assessment responses, patient seems healthy."
  },
  MEDIUM: {
    iconSource: `${process.env.PUBLIC_URL}/img/commonCold.png`,
    result: "Common Cold or Allergy",
    subResult:
      "Based on the assessment responses, patient is likely to have common cold or flu."
  }
};

const Language = {
  English: {
    highResult: "Are you developing any of these signs:",
    highSubResult: [
      "Difficulty breathing or shortness of breath",
      "Persistent pain or pressure in the chest",
      "New confusion or inability to arouse",
      "Bluish lips or face"
    ],
    yes: "Yes",
    no: "No",
    talkHeader: "Talk to your doctor",
    talkBody:
      "You will receive an SMS from your provider with the link to join a video call.",
    talkBeforeText: "Request virtual visit",
    talkAfterText: "Request sent"
  },
  Spanish: {
    highResult: "¿Presenta alguno de los siguientes síntomas?",
    highSubResult: [
      "Dificultad para respirar",
      "Dolor o presión constante en el pecho",
      "Confusión o dificultad para mantenerse  alerta que no haya tenido antes",
      "Coloración azulada en los labios o el rostro"
    ],
    yes: "Si",
    no: "No",
    talkHeader: "Hable Con Su Doctor",
    talkBody:
      "Su médico le enviará un enlace por mensaje de texto para conectarse mediante video",
    talkBeforeText: "Solicite visita virtual",
    talkAfterText: "Solicitud Enviada"
  }
};

const defaultRiskTemplates = {
  English: {
    mediumRisk: `A physician should reach out to you to discuss your case. Meanwhile,  if your situation changes, please re-contact your provider. In the meantime, we want you to be safe at home. Please read these resources: <br /><a
      style='color: #0070DD' target='_blank' href='https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html'>What to do if you are sick</a><br /><a style='color: #0070DD' target='_blank' href='https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/caring-for-yourself-at-home.html'>Caring for yourself at home</a>`,
    lowRisk: `You don’t report any symptoms of coronavirus infection, so your risk is low. Although, you should keep healthy and safe. Please read more guidelines on: <br /><a style='color: #0070DD' target='_blank' href='https://www.cdc.gov/coronavirus/2019-ncov/prepare/prevention.html'> how to stay safe and protect yourself.</a>`,
    highRisk: `Please reach out for immediate medical attention and inform them that you might have COVID-19.`
  },
  Spanish: {
    mediumRisk: `Mientras tanto, si su situación cambia, vuelva a contactar a su médico.  Queremos que esté seguro(a) en casa. Por favor lea estos recursos: <br /><a style='color: #0070DD' target='_blank' href='https://espanol.cdc.gov/enes/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html'>Mantenerse en Casa/Cuándo Buscar Atención Médica</a>`,
    lowRisk: `Usted no ha informado de ningún síntoma de coronavirus, y parece que su riesgo es bajo. Lea como <br /><a style='color: #0070DD' target='_blank' href='https://espanol.cdc.gov/enes/coronavirus/2019-ncov/prevent-getting-sick/prevention.html'> manterse sano and seguro(a).</a>`,
    highRisk: `Busque atención médica de inmediato e informe tan pronto sea posible que tiene COVID-19 Coronavirus.`
  }
};

const defaultRiskTemplateLoading = {
  mediumRisk: true,
  highRisk: true,
  lowRisk: true
};

class SuccessCard extends Component {
  state = {
    state: "",
    response: null,
    redirectToMain: false,
    showToast: false,
    toast: { appearance: "", title: "" },
    virtualVisitRequest: false,
    riskTemplate: {
      ...defaultRiskTemplates[this.props.language || "English"]
    },
    loadingTemplate: { ...defaultRiskTemplateLoading }
  };

  componentWillMount() {
    Mixpanel.track("Patient - Assessment Result Viewed", {
      Category: "COVID-19 : Assessment"
    });
  }

  virtualVisit = () => {
    Mixpanel.track("Patient - Virtual Visit Requested");
    axios
      .post(
        `innote-survey/assessments/${this.props.assessmentId}/responses/${this.props.responseId}/_callback`,
        {
          callbackRequested: true
        }
      )
      .finally(() => {
        this.setState({ virtualVisitRequest: true });
      });
  };

  submitSurvey = feedback => {
    axios
      .patch(
        `innote-survey/assessments/${this.props.assessmentId ||
          "5e7b840c98c5bf3ad547f6a2"}/responses/${
          this.props.responseId
        }/_feedback`,
        {
          feedback
        }
      )
      .finally(() => {
        this.setState({
          submitted: true
        });
      });
  };

  completeForm = type => {
    this.setState({ response: type });
    if (this.props.from === "patient") {
      Mixpanel.track(`Patient - Warning Signs - ${type}`);
    } else {
      Mixpanel.track("Provider Response", {
        Category: "COVID-19 : Assessment",
        Response: type
      });
    }

    if (this.props.from !== "patient")
      this.submitSurvey(type === "Yes" ? "HIGH" : "LOW");
  };

  submitPuiForm = () => {
    this.setState({ isPuiSending: true });
    axios
      .post(
        `innote-survey/assessments/5e70364798c5bf3ad543dd99/responses/${this.props.responseId}/_report_pui`
      )
      .then(({ data: { fileUrl, fileName } }) => {
        Mixpanel.track("Assessment Reported", {
          Category: "COVID-19 : Assessment",
          Form: "PUI"
        });
        return window.fetch(fileUrl).then(async res => {
          const blob = await res.blob();
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          createToast({
            title: "PUI report sent successfully",
            appearance: "success"
          });
          this.setState({
            redirectToMain: true
          });
          this.props.close(null, true);
        });
      })
      .catch(() => {
        createToast({ title: "Please try after some time." });
      })
      .finally(() => {
        this.setState({ isPuiSending: false });
      });
  };

  render() {
    const { language } = this.props;
    const result = SuccessState[this.props.state];
    const { redirectToMain, riskTemplate, loadingTemplate } = this.state;
    if (redirectToMain) {
      setTimeout(function() {
        return <Redirect to="/" />;
      }, 3000);
    }
    return (
      <Wrapper>
        {this.props.from === "patient" ? (
          <SuccessCardWrapper shadow="light">
            {this.props.state === "HIGH" || this.props.state === "MEDIUM" ? (
              this.state.response ? (
                <NextStep
                  value={this.state.response}
                  language={language}
                  riskTemplate={riskTemplate}
                  loadingTemplate={loadingTemplate}
                />
              ) : (
                <Fragment>
                  <DataWrapper>
                    <Icon src={result.iconSource} alt="logo" />
                    <Result>{Language[language].highResult}</Result>
                    {Language[language].highSubResult.map(item => (
                      <SubResult>&bull; {item}</SubResult>
                    ))}
                    <ButtonWrapper>
                      <Button
                        style={{
                          marginRight: "16px"
                        }}
                        dimension="small"
                        type="submit"
                        appearance={
                          this.state.response === "Yes"
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() => this.completeForm("Yes")}
                      >
                        {Language[language].yes}
                      </Button>
                      <Button
                        type="submit"
                        appearance={
                          this.state.response === "No" ? "primary" : "secondary"
                        }
                        dimension="small"
                        onClick={() => this.completeForm("No")}
                      >
                        {Language[language].no}
                      </Button>
                    </ButtonWrapper>
                    {/* <Terms>
										*This list is not all inclusive. Please consult your medical provider for any
										other symptoms that are severe or concerning.
									</Terms> */}
                  </DataWrapper>
                </Fragment>
              )
            ) : (
              <DataWrapper style={{ alignItems: "center" }}>
                <Icon src={result.iconSource} alt="logo" />
                <Result
                  {...(loadingTemplate["lowRisk"]
                    ? {
                        style: {
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center"
                        },
                        children: (
                          <>
                            <Placeholder.Paragraph length="medium" />
                            <Placeholder.Paragraph length="small" />
                          </>
                        )
                      }
                    : {
                        style: { textAlign: "center" },
                        dangerouslySetInnerHTML: {
                          __html: riskTemplate["lowRisk"]
                        }
                      })}
                ></Result>
              </DataWrapper>
            )}
          </SuccessCardWrapper>
        ) : (
          <Fragment>
            {!this.state.response && (
              <SuccessCardWrapper shadow="light" style={{ padding: "16px" }}>
                <Result>
                  Based on your assessment, do you think the patient is likely
                  to have COVID-19?
                </Result>
                <ButtonWrapper>
                  <Button
                    style={{
                      marginRight: "16px"
                    }}
                    dimension="small"
                    type="submit"
                    appearance={
                      this.state.response === "Yes" ? "primary" : "secondary"
                    }
                    onClick={() => this.completeForm("Yes")}
                  >
                    Yes
                  </Button>
                  <Button
                    type="submit"
                    appearance={
                      this.state.response === "No" ? "primary" : "secondary"
                    }
                    dimension="small"
                    onClick={() => this.completeForm("No")}
                  >
                    No
                  </Button>
                </ButtonWrapper>
              </SuccessCardWrapper>
            )}
            {this.state.response === "No" && (
              <CardSuccess shadow="light">
                <ShowResult>
                  Thank you for taking this assessment. To take next assessment
                  please close this window.
                </ShowResult>
              </CardSuccess>
            )}
            {this.state.response === "Yes" && (
              <Fragment>
                <CardSuccess shadow="light">
                  <NextSteps>Next Step</NextSteps>
                  <ReportWrapper>
                    <TickIcon className="material-icons">
                      check_circle_outline
                    </TickIcon>
                    <ReportTextWrapper>
                      <ReportText>Download PUI form</ReportText>
                      <ReportSubText>
                        Completed CDC Coronavirus PUI form is available for you
                        to download.
                      </ReportSubText>
                    </ReportTextWrapper>
                  </ReportWrapper>
                  <ButtonWrapper>
                    <Button
                      disabled={this.state.isPuiSending}
                      type="submit"
                      appearance="primary"
                      onClick={this.submitPuiForm}
                    >
                      {this.state.isPuiSending
                        ? `Downloading Form`
                        : "Download Form"}
                      {this.state.isPuiSending && (
                        <Loader stroke="#fff" height="14px" />
                      )}
                    </Button>
                  </ButtonWrapper>
                </CardSuccess>
              </Fragment>
            )}
          </Fragment>
        )}
        {this.props.from === "patient" &&
          this.props.state === "HIGH" &&
          !this.state.response && (
            <RequestVisitWrapper shadow="light">
              <InfoWrapper>
                <TalkToDoctor>{Language[language].talkHeader}</TalkToDoctor>
                <RecieveText>{Language[language].talkBody}</RecieveText>
                <RequestVisitText
                  onClick={!this.state.virtualVisitRequest && this.virtualVisit}
                  color={this.state.virtualVisitRequest ? "#2EA843" : "#0070DD"}
                  cursor={
                    this.state.virtualVisitRequest ? "default" : "pointer"
                  }
                >
                  {this.state.virtualVisitRequest ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px"
                      }}
                    >
                      <i
                        className="material-icons"
                        style={{
                          marginRight: "4px",
                          fontSize: "16px"
                        }}
                      >
                        done
                      </i>
                      {Language[language].talkAfterText}
                    </div>
                  ) : (
                    <span>{Language[language].talkBeforeText}</span>
                  )}
                </RequestVisitText>
              </InfoWrapper>
              <DoctorWrapper>
                <Doctor
                  src={`${process.env.PUBLIC_URL}/img/doctor.png`}
                  alt="logo"
                />
              </DoctorWrapper>
            </RequestVisitWrapper>
          )}
      </Wrapper>
    );
  }

  fetchRiskTemplate = riskType => {
    const { assessmentId, responseId } = this.props;
    this.setState({
      loadingTemplate: { ...this.state.loadingTemplate, [riskType]: true }
    });
    axios
      .get(
        `${patientOutreachUrl}/organizations/educational-configurations/${assessmentId}/educationalMsg/${riskType}?response_id=${responseId}`
      )
      .then(
        ({
          data: {
            config: { value }
          }
        }) => {
          this.setState({
            loadingTemplate: {
              ...this.state.loadingTemplate,
              [riskType]: false
            },
            riskTemplate: {
              ...this.state.riskTemplate,
              [riskType]: value
            }
          });
        }
      )
      .catch(() => {
        this.setState({
          loadingTemplate: {
            ...this.state.loadingTemplate,
            [riskType]: false
          }
        });
      });
  };

  componentDidMount() {
    ["lowRisk", "mediumRisk", "highRisk"].forEach(this.fetchRiskTemplate);
  }
}

export default SuccessCard;
