import React, { Component } from 'react';
import styled from 'styled-components';
import MultiSelectQuestion from './MultiSelectQuestion';
import SingleSelectQuestion from './SingleSelectQuestion';
import TextInputQuestion from './TextInputQuestion';
import RangeQuestion from './RangeQuestion';
import BooleanQuestion from './BooleanQuestion';
import PuiSingleSelect from './PuiSingleSelect';

const QuestionContainer = styled.div``;

const QuestionLabel = styled.div`
   font-size: 16px;
   ${({ providerFlow }) =>
      providerFlow
         ? 'color: #2F2F2F;font-weight: 600;letter-spacing: 0;line-height: 16px;'
         : 'line-height: 24px;font-weight: 600;padding: 8px;'};
`;

const Opt = styled.div`
   border-top: 1px solid rgb(232, 228, 228);
   padding: 0 4px;

   & > div {
      padding: 14px 0;
      border-bottom: 1px solid rgb(232, 228, 228);
   }
   div:last-child {
      border-bottom: none;
      li {
         div {
            :last-child {
               border-bottom: none;
            }
         }
      }
   }
`;

class Question extends Component {
   render() {
      const { type, question } = this.props.question || {};

      return (
         <QuestionContainer>
            <QuestionLabel>{question}</QuestionLabel>
            <Opt>
               {type === 'MULTIPLECHOICE' && (
                  <MultiSelectQuestion {...this.props} />
               )}
               {type === 'SINGLECHOICE' && this.props.fromPui && (
                  <PuiSingleSelect {...this.props} />
               )}
               {type === 'SINGLECHOICE' && !this.props.fromPui && (
                  <SingleSelectQuestion {...this.props} />
               )}
               {type === 'TEXTINPUT' && <TextInputQuestion {...this.props} />}
               {type === 'BOOLEAN' && <BooleanQuestion {...this.props} />}
               {type === 'RANGE' && <RangeQuestion {...this.props} />}
            </Opt>
         </QuestionContainer>
      );
   }
}

export default Question;
