import { _getEvaluatePatient } from "helpers/evaluatePatientHelper";
import {
  REQUEST_GET_EVALUATE_PATIENT,
  GET_EVALUATE_PATIENT_FAILURE,
  GET_EVALUATE_PATIENT_SUCCESS
} from "./constants";

function requestGetEvaluatePatient() {
  return {
    type: REQUEST_GET_EVALUATE_PATIENT
  };
}

function getEvaluatePatientSuccess(data, dependentSurveyList) {
  return {
    type: GET_EVALUATE_PATIENT_SUCCESS,
    data,
    dependentSurveyList
  };
}

function getEvaluatePatientFailure({ error }) {
  return {
    type: GET_EVALUATE_PATIENT_FAILURE,
    error
  };
}

function getEvaluatePatient(ID, responseID) {
  return dispatch => {
    dispatch(requestGetEvaluatePatient());
    return _getEvaluatePatient(ID, responseID)
      .then(({ data: { surveyList, dependentSurveyList } }) => {
        dispatch(getEvaluatePatientSuccess(surveyList, dependentSurveyList));
      })
      .catch(error => {
        dispatch(getEvaluatePatientFailure(error));
      });
  };
}

export { getEvaluatePatient };
