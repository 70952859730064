import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Icon from "@datashop/icon";
import Card from "@datashop/card";
import Button from "@datashop/button";
import DatePicker from "@datashop/date-picker";
import Input from "@datashop/input";
import PhoneNumberInput from "../../helpers/phoneInput";
import TimeInput from "../../helpers/TimeInput";
import { Loader } from "../../packages/loader";
import Radio from "@datashop/radio";
import Textarea from "@datashop/textarea";
import mobileCheck from "../../helpers/mobileCheck";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => (mobileCheck() ? "#f4f4f4" : "#fff")};
  overflow-y: auto;
  position: relative;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => (mobileCheck() ? "15px" : "0 0 15px 0")};
`;
const GoBack = styled(Icon)`
  font-size: 28px;
  color: #2f2f2f;
  margin-bottom: 20px;
  cursor: pointer;
`;
const Header = styled.p`
  color: #151414;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
`;
const ContentCard = styled(Card)`
  padding: 15px;
  margin: 0 3px 15px 3px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
`;
const StyledInput = styled(Input)`
  height: 40px;
  + i {
    top: 12px;
  }
`;
const StyledButton = styled(Button)`
  justify-content: space-between;
  padding: 12px 5px 12px 0;
  height: auto;
`;
const Required = styled.p`
  border-radius: 50%;
  height: 6px;
  background-color: #e15f5f;
  width: 6px;
  margin-left: 4px;
  margin-top: 2px;
`;
const Label = styled.div`
  display: flex;
  color: #2f2f2f;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 4px;
`;
const FieldWrapper = styled.div`
  margin-bottom: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  padding: 2px;
  background-color: ${props => (mobileCheck() ? "#f4f4f4" : "#fff")};
`;
const FormFieldWrapper = styled.div`
  > div {
    > div:nth-child(2) {
      width: auto;
    }
  }
  i {
    top: 12px;
    width: 16px;
  }
  input {
    height: 40px;
  }
`;
const GenderOptions = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyleTimeField = styled(TimeInput)`
  height: 40px;

  + i {
    top: 12px;
  }
`;
const StyledPhoneNumber = styled(PhoneNumberInput)`
  height: 40px;

  + i {
    top: 12px;
  }
`;

const VisitRequestForm = ({
  firstName,
  phoneNumber,
  lastName,
  dob,
  gender,
  date,
  time,
  reasonForVisit,
  showDatePicker,
  showDobPicker,
  onChangeHandler,
  handleDobFocusChange,
  handleDateFocusChange,
  onDobChange,
  onDateChange,
  sendRequest,
  requesting,
  history,
  setIsOpen,
  selectedProvider
}) => {
  const genders = [
    {
      name: "gender",
      value: "male",
      label: "Male",
      checked: false
    },
    {
      name: "gender",
      value: "female",
      label: "Female",
      checked: false
    },
    {
      name: "gender",
      value: "other",
      label: "Other"
    }
  ];

  return (
    <Container>
      <HeaderWrapper>
        <GoBack name="arrow_back" onClick={() => history.push("/")} />
        <Header>Request a virtual appointment</Header>
      </HeaderWrapper>
      <ContentCard shadow="medium">
        <Label style={{ fontSize: "16px", marginBottom: "12px" }}>
          Personal Details
        </Label>
        <FieldWrapper>
          <Label>
            First Name
            <Required />
          </Label>
          <StyledInput
            required
            icon="person"
            type="text"
            placeholder="John"
            value={firstName}
            name="firstName"
            onChange={onChangeHandler}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>
            Last Name
            <Required />
          </Label>
          <StyledInput
            required
            icon="person"
            type="text"
            placeholder="Doe"
            value={lastName}
            name="lastName"
            onChange={onChangeHandler}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label htmlFor="dob">
            Date of Birth <Required />
          </Label>
          <FormFieldWrapper id="dob">
            <DatePicker
              icon="calendar_today"
              date={dob}
              dateFormat="MM/DD/YYYY"
              disableDate={date => date > new Date()}
              onDateChange={onDobChange}
              showDatePicker={showDobPicker}
              onFocusChange={handleDobFocusChange}
              position="Top"
              appendToBody={false}
            />
          </FormFieldWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <Label htmlFor="phoneNumber">
            Phone Number
            <Required />
          </Label>
          <StyledPhoneNumber
            style={{ height: "40px" }}
            value={phoneNumber}
            onChange
            name="phoneNumber"
            onChange={event => {
              typeof parseInt(event.target.value.slice(-1)) === "number" &&
                event.target.value.slice(-1) !== " " &&
                !isNaN(event.target.value.slice(-1)) &&
                onChangeHandler(event);
            }}
            id="phoneNumber"
          />
        </FieldWrapper>
        <FieldWrapper style={{ marginBottom: "0" }}>
          <Label style={{ marginBottom: "10px" }}>
            Gender
            <Required />
          </Label>
          <GenderOptions>
            {genders.map(gen => {
              return (
                <Radio
                  data-test={`login__form-body__gender--${gen.value}`}
                  name="gender"
                  key={gen.value}
                  id={gen.value}
                  value={gen.value}
                  label={gen.label}
                  checked={gender && gender.toLowerCase() === gen.value}
                  onChange={onChangeHandler}
                />
              );
            })}
          </GenderOptions>
        </FieldWrapper>
      </ContentCard>
      <ContentCard shadow="medium">
        <Label style={{ fontSize: "16px", marginBottom: "12px" }}>
          Select Preference ( Optional )
        </Label>
        <FieldWrapper>
          <Label htmlFor="provider">Provider</Label>
          <FormFieldWrapper id="provider">
            <StyledButton expanded onClick={() => setIsOpen(true)}>
              <div style={{ marginLeft: 10 }}>
                {selectedProvider
                  ? selectedProvider.label
                  : "Select a Provider"}
              </div>
              <i className="material-icons">keyboard_arrow_right</i>
            </StyledButton>
          </FormFieldWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <Label htmlFor="date">Preferred date</Label>
          <FormFieldWrapper id="date">
            <DatePicker
              icon="calendar_today"
              date={date}
              dateFormat="MM/DD/YYYY"
              onDateChange={onDateChange}
              disableDate={date => date < new Date()}
              showDatePicker={showDatePicker}
              onFocusChange={handleDateFocusChange}
              position="Top"
              appendToBody={false}
            />
          </FormFieldWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <Label htmlFor="time">Preferred time</Label>
          <StyleTimeField
            name="time"
            value={time}
            onChange={onChangeHandler}
            id="time"
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label>
            <Label>Reason for the visit</Label>
          </Label>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "end",
              paddingTop: "4px"
            }}
          >
            <Textarea
              placeholder="Use this space to inform the patient about the reason behind scheduling this visit."
              rows="5"
              maxLength="140"
              name="reasonForVisit"
              value={reasonForVisit}
              onChange={onChangeHandler}
              style={{ color: "#2f2f2f", resize: "none" }}
            />
            <span
              style={{
                marginTop: "-21px",
                marginRight: "10px",
                zIndex: "1",
                color: "#868686"
              }}
            >
              {`${reasonForVisit.length}`}
              /140
            </span>
          </div>
        </FieldWrapper>
      </ContentCard>
      <ButtonWrapper>
        <Button
          style={{ height: "40px" }}
          appearance="primary"
          onClick={sendRequest}
          disabled={
            !phoneNumber ||
            phoneNumber.replace(/\D/g, "").length !== 10 ||
            !firstName ||
            !lastName ||
            !dob ||
            !gender ||
            requesting
          }
        >
          {requesting ? <Loader /> : "Submit"}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default VisitRequestForm;
