import React, { Fragment } from "react";
import styled from "styled-components";
import Button from "@datashop/button";
import Card from "@datashop/card";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.p`
  color: #000000;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 24px;
`;

const Link = styled.p`
  color: #0070dd;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  cursor: pointer;
`;

const TermsOfService = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
`;

const CardWrapper = styled.div`
  padding: 12px;
  background-color: rgba(248, 248, 248, 0.82);
  height: 100vh;
`;

const ContentCard = styled(Card)`
  padding: 24px 12px 12px;
  background-color: #fff;
`;

const ConsentForm = props => (
  <Wrapper>
    <CardWrapper>
      <ContentCard shadow="light">
        <Header>Consent Form</Header>
        <TermsOfService style={{ marginBottom: "136px" }}>
          I certify that I have read and understood and give the consent to the{" "}
          {props.organization === "CMHC" && (
            <Fragment>
              <Link onClick={() => props.seeContent(0)}>
                Consent, Use and Disclosure Statement >
              </Link>
              <Link onClick={() => props.seeContent(1)}>
                Privacy Practices Notice >
              </Link>
            </Fragment>
          )}
          <Link onClick={() => props.seeContent(2)}>Terms of Service ></Link>
        </TermsOfService>
        <Button
          onClick={props.acceptAndContinue}
          expanded
          dimension="large"
          appearance="primary"
        >
          Accept and Continue
        </Button>
      </ContentCard>
    </CardWrapper>
  </Wrapper>
);

export default ConsentForm;
