import { _sendEvaluatePatient } from "helpers/evaluatePatientHelper";
import {
  REQUEST_SEND_EVALUATE_PATIENT,
  SEND_EVALUATE_PATIENT_FAILURE,
  SEND_EVALUATE_PATIENT_SUCCESS
} from "./constants";

function requestSendEvaluatePatient() {
  return {
    type: REQUEST_SEND_EVALUATE_PATIENT
  };
}

function sendEvaluatePatientSuccess(data) {
  return {
    type: SEND_EVALUATE_PATIENT_SUCCESS,
    data
  };
}

function sendEvaluatePatientFailure({ error }) {
  return {
    type: SEND_EVALUATE_PATIENT_FAILURE,
    error
  };
}

function sendEvaluatePatient(id, phone, answers) {
  return dispatch => {
    dispatch(requestSendEvaluatePatient());
    return _sendEvaluatePatient(id, phone, answers)
      .then(({ assessment }) => {
        dispatch(sendEvaluatePatientSuccess(assessment));
      })
      .catch(error => {
        dispatch(sendEvaluatePatientFailure(error));
      });
  };
}

export { sendEvaluatePatient };
