import createToast from "../../helpers/toastHelper";

let MessageSocket = {};

const initSocket = () => {
  const url =
    process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_SOCKET_URL`];

  // const url = 'wss://inconnect.oninnovaccer.com/api/chatv2/ws/'
  const socket = new WebSocket(
    `${url}?AUTHORIZATION=${localStorage.getItem("token")}&USERTYPE=patient`
  );
  return socket;
};
const create = (onConnect, responseHandler) => {
  // const url = process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_SOCKET_URL`];
  const socket = initSocket();
  socket.onopen = e => {
    onConnect();
  };
  socket.onmessage = ({ data }) => {
    const details = JSON.parse(data);
    responseHandler(details);
  };

  socket.onclose = data => {
    // setTimeout(() => {
    //   create(onConnect, responseHandler);
    // }, 1000);
  };

  socket.onerror = e => {
    // Handle Socket error here
    // createToast({ title: "Error in Socket Connection." });
  };

  return socket;
};

const close = socket => {
  if (socket) {
    socket.close();
  }
};

MessageSocket = {
  create,
  close
};

export default MessageSocket;
