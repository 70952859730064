import React, { Fragment } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import StyledButton from "@datashop/button";
import StyledInput from "@datashop/input";
import { Loader } from "./../../packages/loader/";
import { FormBody, FormFooter } from "./Login";
import mobileCheck from "../../helpers/mobileCheck";

const Header = styled.p`
  color: #151414;
  font-size: 32px;
  margin-bottom: 4px;
`;

const VerificationCode = styled.p`
  margin-right: 64px;
  text-align: left;
  color: #868686;
  font-size: 16px;
`;

const Form = styled.form`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
  flex-direction: column;
`;

const OTPWrapper = styled.div`
  width: 100%;
  margin: 32px 0 16px 0;
  display: flex;
  justify-content: space-between;
`;

const OTPDiv = styled.div`
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #ffffff;
  flex: 1;
`;

const Input = styled(StyledInput)`
  border: none;
  width: 30px;
  align-self: center;
  font-size: ${props => (mobileCheck() ? "16px" : "inherit")}

  :focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

const Button = styled(StyledButton)`
  margin-top: 24px;
  height: 40px;
  font-weight: 300;
`;

const ValidityText = styled.p`
  margin: 24px auto;
  width: 201px;
  color: #898c8f;
  font-size: 16px;
  text-align: center;
`;

const GetNewCode = styled.p`
  cursor: pointer;
  margin-top: 8px;
  color: #0070dd;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

export default class Verify extends React.Component {
  _isMounted = true;
  state = {
    first: "",
    second: "",
    third: "",
    fourth: "",
    button: {},
    isLoading: false,
    minutes: 15,
    seconds: 0,
    showResendOTP: false,
    shouldVerifyDetails: false,
    canContinue: false
  };

  componentDidMount() {
    this.createInterval();
  }

  componentDidUpdate() {
    if (
      this.state.minutes === 14 &&
      this.state.seconds === 30 &&
      !this.state.showResendOTP
    ) {
      this.setState({ showResendOTP: true });
    }
    if (
      this.state.minutes === 0 &&
      this.state.seconds === 0 &&
      !this.state.showResendOTP
    ) {
      this.setState({ showResendOTP: true });
    }
  }

  createInterval = () => {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59
          }));
        }
      }
    }, 1000);
  };

  resetOtpCounter = () => {
    this.setState({ showResendOTP: false, minutes: 15, seconds: 0 });
    clearInterval(this.myInterval);
    this.createInterval();
  };

  resendOtp = () => {
    this.props.onResendOtp(this.resetOtpCounter);
  };

  onInput = event => {
    event.target.value = event.target.value.slice(0, 1);
  };

  onChange = (event, autofocusId) => {
    this.props.handleOtpInput(event, autofocusId);
  };

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.myInterval);
  }

  render() {
    const { minutes, seconds } = this.state;
    const {
      handleEditDetails,
      otp,
      verifying,
      onVerify,
      resendOtpLoading,
      maskedPhoneNumber
    } = this.props;
    const { first, second, third, fourth } = otp;
    return (
      <Fragment>
        {this.state.success && <Redirect to="/" />}
        <Header data-test="verify__header">Verify Details</Header>
        <Fragment>
          <VerificationCode data-test="verify__message">
            {`We’ve sent a verification code on your mobile number
                  ${
                    !maskedPhoneNumber ? "(000)-000000" : maskedPhoneNumber
                  } to verify it’s you.`}
          </VerificationCode>
          <Form data-test="verify__form" onSubmit={e => e.preventDefault()}>
            <FormBody data-test="verify__form-body">
              <OTPWrapper>
                <OTPDiv style={{ marginRight: "16px" }}>
                  <Input
                    data-test="verify__form-body__first-otp-val"
                    type="number"
                    onChange={event => this.onChange(event, "second")}
                    value={first}
                    name="first"
                    id="first"
                    onInput={this.onInput}
                  />
                </OTPDiv>
                <OTPDiv style={{ marginRight: "16px" }}>
                  <Input
                    data-test="verify__form-body__second-otp-val"
                    type="number"
                    id="second"
                    onKeyDown={event =>
                      this.props.onKeyDownHandler(event, "first")
                    }
                    onChange={event => this.onChange(event, "third")}
                    value={second}
                    name="second"
                    onInput={this.onInput}
                  />
                </OTPDiv>
                <OTPDiv style={{ marginRight: "16px" }}>
                  <Input
                    data-test="verify__form-body__third-otp-val"
                    type="number"
                    id="third"
                    name="third"
                    onKeyDown={event =>
                      this.props.onKeyDownHandler(event, "second")
                    }
                    onChange={event => this.onChange(event, "fourth")}
                    value={third}
                    onInput={this.onInput}
                  />
                </OTPDiv>
                <OTPDiv>
                  <Input
                    data-test="verify__form-body__forth-otp-val"
                    type="number"
                    onChange={this.onChange}
                    value={fourth}
                    onKeyDown={event =>
                      this.props.onKeyDownHandler(event, "third")
                    }
                    id="fourth"
                    name="fourth"
                    onInput={this.onInput}
                  />
                </OTPDiv>
              </OTPWrapper>
              <ValidityText data-test="verify__otp-valid-msg">
                The verification code is valid for {minutes}:
                {seconds < 10 ? `0${seconds}` : seconds} minutes
              </ValidityText>
              {this.state.showResendOTP && (
                <GetNewCode
                  data-test="verify__get-otp"
                  onClick={this.resendOtp}
                >
                  Get a new code
                </GetNewCode>
              )}
            </FormBody>
            <FormFooter data-test="verify__form-footer">
              <Button
                data-test="verify__form-footer__verify-btn"
                style={{ width: "100%" }}
                onClick={() => {
                  onVerify();
                }}
                disabled={!(first && second && third && fourth) || verifying}
                appearance="primary"
              >
                {verifying || resendOtpLoading ? (
                  <Loader stroke="#fff" />
                ) : (
                  "Verify"
                )}
              </Button>
              <Button
                data-test="verify__form-footer__edit-btn"
                disabled={verifying || resendOtpLoading}
                style={{ width: "100%", marginTop: "8px" }}
                onClick={handleEditDetails}
                appearance="secondary"
              >
                Edit Details
              </Button>
            </FormFooter>
          </Form>
        </Fragment>
      </Fragment>
    );
  }
}
