import React from "react";
import styled from "styled-components";
import Heading from "@datashop/heading";
import Icon from "@datashop/icon";
import Button from "@datashop/button";

const BackIconWrapper = styled(Heading)`
  cursor: pointer;
  margin-bottom: 40px;
`;
const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SuccessCheckContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;
const SuccessText = styled.div`
  color: #151414;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
  text-align: center;
`;
const SuccessSubText = styled.div`
  color: #151414;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;
const BackButton = styled(Button)`
  height: 40px;
`;

const VisitScheduleSuccess = ({ history }) => {
  return (
    <SuccessWrapper>
      <ContentWrapper>
        <BackIconWrapper as="h1" onClick={() => history.push("/")}>
          <Icon name="close" />
        </BackIconWrapper>
        <SuccessCheckContainer>
          <img
            width="120"
            height="120"
            src={`${process.env.PUBLIC_URL}/img/success_check.png`}
          />
        </SuccessCheckContainer>
        <SuccessText>Visit requested successfully</SuccessText>
        <SuccessSubText>
          {`You will receive a call from the 
         front desk to confirm and schedule an appointment.`}
        </SuccessSubText>
      </ContentWrapper>
      <BackButton onClick={() => history.push("/")}>Back to home</BackButton>
    </SuccessWrapper>
  );
};

export default VisitScheduleSuccess;
