export const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isMobileAndroid = /Android/i.test(userAgent);
  const isMobileIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
  if (isMobileAndroid || isMobileIOS) {
    return true;
  }
  return false;
};

export default isMobile;
