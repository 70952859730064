import React, { memo } from "react";
import styled from "styled-components";
import Button from "@datashop/button";
import PatientDetails from "../Assessments/PatientDetails";

const PatientDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
`;

const NextStepButtonWrapper = styled.div``;

const Language = {
  English: { nextStep: "Next Step" },
  Spanish: { nextStep: "Próximo Paso" }
};

const EvaluatePatient = memo(
  ({ language, canMoveForward, goToNextStep, ...rest }) => (
    <PatientDetailsWrapper>
      <PatientDetails {...rest} hidePhoneNo={true} />
      <NextStepButtonWrapper>
        <Button
          type="submit"
          appearance="primary"
          dimension="large"
          expanded
          onClick={goToNextStep}
          disabled={!canMoveForward}
        >
          {Language[language].nextStep}
        </Button>
      </NextStepButtonWrapper>
    </PatientDetailsWrapper>
  )
);

export default EvaluatePatient;
