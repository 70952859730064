import React from 'react';
import styled from 'styled-components';
import { Loader } from 'packages/loader';

const Wrapper = styled.div`
   position: fixed;
   right: 0;
   left: 0;
   top: 0;
   bottom: 0;
   height: 136px;
   margin: auto;
   background-color: #2f2f2f;
   display: flex;
   align-items: center;
   flex-direction: column;
   width: 280px;
   z-index: 3;
   border-radius: 4px;
   padding: 24px;
`;

const Text = styled.p`
   margin-top: 12px;
   color: #868686;
   font-size: 16px;
   font-weight: bold;
   line-height: 24px;
   text-align: center;
`;

function SingleParticipantIndicator() {
   return (
      <Wrapper>
         <Loader stroke='#0070DD' />
         <Text>Please wait while someone joins you for this visit</Text>
      </Wrapper>
   );
}

export default SingleParticipantIndicator;
