import React from "react";
import Ribbon from "./Ribbon";
import ParticipantView from "./ParticipantView";
import styled from "styled-components";

const Row = styled.div`
  width: ${({ innerWidth }) => innerWidth}px;
  ${({ innerHeight }) => `height: ${innerHeight}px;`}
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding-bottom: 8px;

  :last-child {
    padding-bottom: 16px;
  }
`;

const ViewVideo = ({
  isFocusedView,
  onCallParticipants,
  pinedParticipant,
  flowType,
  innerWidth: width,
  rowCount,
  itemCount,
  innerHeight: height,
  endCallModalType,
  isAudioCall,
  isVideoEnabled,
  isAudioEnabled,
  changeLayout,
  setPinParticipant,
  remove,
  mute,
  unmute
}) => {
  if (onCallParticipants.length === 2) {
    return onCallParticipants.map(el => (
      <ParticipantView
        endCallModalType={endCallModalType}
        rowCount={rowCount}
        key={el.participant.sid}
        el={el}
        itemCount={itemCount}
        changeLayout={data => {
          // Mixpanel.track('Pin view - tile');
          changeLayout(data);
        }}
        isAudioCall={isAudioCall}
        isVideoEnabled={isVideoEnabled}
        isAudioEnabled={isAudioEnabled}
        isViewFroTwo={true}
        innerHeight={height}
      />
    ));
  }
  if (isFocusedView) {
    let pinedIndex = onCallParticipants.findIndex(
      el => el.id === pinedParticipant
    );
    if (pinedIndex === -1) {
      if (flowType === "PATIENT") {
        pinedIndex = onCallParticipants.findIndex(
          el => el.pData.type === "PROVIDER"
        );
      } else {
        pinedIndex = onCallParticipants.findIndex(
          el => el.pData.type === "PATIENT"
        );
      }
    }
    if (pinedIndex === -1) {
      changeLayout();
      return "";
    }
    if (!pinedParticipant) {
      setPinParticipant(onCallParticipants[pinedIndex].id);
    }
    // Mixpanel.track('Focused view');
    return (
      <>
        <Ribbon pinedIndex={pinedIndex} innerWidth={width}>
          {onCallParticipants.map((el, i) => {
            return (
              pinedIndex !== i &&
              el.participant &&
              el.participant.sid && (
                <ParticipantView
                  key={el.participant.sid}
                  el={el}
                  itemCount={itemCount}
                  changeLayout={changeLayout}
                  isPinned={pinedIndex === i}
                  isFocusedView={isFocusedView}
                  isAudioCall={isAudioCall}
                  isVideoEnabled={isVideoEnabled}
                  isAudioEnabled={isAudioEnabled}
                  remove={remove}
                  mute={mute}
                  unmute={unmute}
                  flowType={flowType}
                />
              )
            );
          })}
        </Ribbon>
        <ParticipantView
          el={onCallParticipants[pinedIndex]}
          isMainView={true}
          changeLayout={changeLayout}
          isAudioCall={isAudioCall}
          isVideoEnabled={isVideoEnabled}
          isAudioEnabled={isAudioEnabled}
          remove={remove}
          mute={mute}
          unmute={unmute}
          flowType={flowType}
        />
      </>
    );
  } else {
    let item = 0;
    // Mixpanel.track('Grid view');
    return (
      <>
        {Array(rowCount)
          .fill(0)
          .map((_, index) => {
            const row = index + 1;
            const miniData = onCallParticipants.slice(item, itemCount * row);
            item += itemCount;
            return (
              <Row
                key={index}
                innerHeight={Math.floor((height - 96) / rowCount)}
                innerWidth={width}
              >
                {miniData.map(
                  (el, i) =>
                    el.participant &&
                    el.participant.sid && (
                      <ParticipantView
                        endCallModalType={endCallModalType}
                        rowCount={rowCount}
                        key={el.participant.sid}
                        el={el}
                        itemCount={itemCount}
                        changeLayout={data => {
                          // Mixpanel.track('Pin view - tile');
                          changeLayout(data);
                        }}
                        isAudioCall={isAudioCall}
                        isVideoEnabled={isVideoEnabled}
                        isAudioEnabled={isAudioEnabled}
                        remove={remove}
                        mute={mute}
                        unmute={unmute}
                        flowType={flowType}
                      />
                    )
                )}
              </Row>
            );
          })}
      </>
    );
  }
};

export default ViewVideo;
