import Axios from "axios";
import history from "./../history";
import createToast from "helpers/toastHelper";
import refreshToken from "./refreshToken";

const URL = process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_API_URL`];

const provoiderApi = Axios.create({
  baseURL: URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "X-TIMEZONE-OFFSET": new Date().getTimezoneOffset()
  }
});

const handleRefreshToken = () => {
  refreshToken()
    .then(data => {
      const { accessToken, refreshToken } = data.data;
      localStorage.setItem("token", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      createToast({
        title: "Session Restored. Please Continue.",
        appearance: "success"
      });

      history.push("/");
    })
    .catch(error => {
      localStorage.clear();
      history.push(`/signup`);
      window.location.reload();
    });
};

provoiderApi.interceptors.response.use(
  res => res,
  err => {
    if (err.response.status === 401) {
      handleRefreshToken();
    }
    if (err.response.status === 428) {
      const redirectUrl = encodeURIComponent(
        `${history.location.pathname}${history.location.search}`
      );
      history.push(`/accept-consent?redirect=${redirectUrl}`);
    }
    throw err;
  }
);

export default provoiderApi;
