import React, { Component } from 'react';
import styled from 'styled-components';

const TextWrapper = styled.div`
  display: flex;
  padding: 4px 0;
  flex: 1;
  cursor: pointer;
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  cursor: default;
  background-color: #fff;

  &:last-child {
    ${TextWrapper} {
      border-bottom: none;
    }
  }
`;

const Name = styled.p`
  align-self: center;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

const CheckedIcon = styled.i`
  color: #0072e4;
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
`;

const UncheckedIcon = styled.i`
  color: #bfc0c0;
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
`;

class SingleSelectQuestion extends Component {
  render() {
    const {
      question,
      question: { id, answers = [] },
      questionsAnswered,
      activeQuestion,
      onClickRadioHandler
    } = this.props;

    return (
      <Item>
        {Object.keys(answers).map(each => (
          <TextWrapper
            key={answers[each].id}
            onClick={e =>
              onClickRadioHandler(e, {
                questionId: id,
                answerId: answers[each].id
              })
            }>
            {answers[each].id === question.selectedAnswerId ? (
              <CheckedIcon className='material-icons'>check_circle</CheckedIcon>
            ) : (
              <UncheckedIcon className='material-icons'>
                panorama_fish_eye
              </UncheckedIcon>
            )}
            <Name>{answers[each].label}</Name>
          </TextWrapper>
        ))}
      </Item>
    );
  }
}

export default SingleSelectQuestion;
