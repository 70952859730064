import React, { Component } from "react";
import styled from "styled-components";
import Card from "@datashop/card";
import Icon from "@datashop/icon";
import { Logo, LogoWrapper } from "../Auth/Signup";
import mobileCheck from "../../helpers/mobileCheck";

const VFDHomeWrapper = styled.div`
  display: flex;
  padding: ${props => (mobileCheck() ? "16px" : "0")};
  flex: 1;
  flex-direction: column;
  background-color: ${props => (mobileCheck() ? "#f4f4f4" : "#fff")};
  margin-bottom: 15px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 16px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
`;

const CardMessage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 16px 23px 16px 16px;
`;

const CardTitle = styled.p`
  color: #151414;
  font-size: 16px;
  margin-bottom: 2px;
`;

const CardBody = styled.p`
  color: #868686;
  font-size: 14px;
  margin-bottom: 20px;
`;

const CardFooter = styled.p`
  color: #0070dd;
  font-size: 14px;
`;

const CardImg = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-left: 28px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`;

const OnlineStatus = styled.div`
  display: flex;
  margin-top: 4px;
`;

const Online = styled.div`
   margin: 4px 6px;
   height: 4px;
   width: 4px;
   background-color: ${props =>
     !props.mode || (props.mode === "messages" && props.staffList.length > 0)
       ? "#2EA843"
       : "#C5C5C5"}
   border-radius: 100%;
`;

const PhoneIcon = styled(Icon)`
  font-size: 14px;
  margin-right: 6px;

  + i {
    top: 12px;
  }
`;

const AddressIcon = styled(Icon)`
  font-size: 14px;
  margin-right: 6px;

  + i {
    top: 12px;
  }
`;

const HomeFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  color: grey;
`;

export const OnlineHeader = ({ staffList, mode }) => (
  <OnlineStatus>
    <Online mode={mode} staffList={staffList} />
    <p
      style={{
        color:
          !mode || (mode === "messages" && staffList.length > 0)
            ? "#2EA843"
            : "#C5C5C5",
        fontSize: "12px"
      }}
    >
      Online
    </p>
  </OnlineStatus>
);

const GotoChatBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 5;
`;

class VFDHome extends Component {
  goToVirtualWalkin = () => {
    this.props.history.push(`/virtual-walkin`);
  };

  goToVisitRequest = () => {
    this.props.history.push(`/visit-request`);
  };

  render() {
    const { details = {}, practiceDetails = null } = this.props;

    return (
      <VFDHomeWrapper data-test="vfdHome-homeWrapper">
        {mobileCheck() && (
          <LogoWrapper data-test="homeWrapper-logoWrapper">
            <Logo
              data-test="logoWrapper-logo"
              src={practiceDetails ? practiceDetails.logoUrl : details.logoUrl}
              alt="Innovaccer"
            />
          </LogoWrapper>
        )}
        <StyledCard shadow="medium" onClick={this.goToVirtualWalkin}>
          <CardMessage>
            <CardTitle>See a doctor now</CardTitle>
            <CardBody>
              For a virtual walk-in you'll have to select your provider.But your
              provider may take a while to join you.
            </CardBody>
            <CardFooter onClick={this.goToVirtualWalkin}>
              Begin virtual walk-in
            </CardFooter>
          </CardMessage>
          <CardImg>
            <img
              height={82}
              width={64}
              src={`${process.env.PUBLIC_URL}/img/walkIn.png`}
            />
          </CardImg>
        </StyledCard>
        <StyledCard shadow="medium" onClick={this.goToVisitRequest}>
          <CardMessage>
            <CardTitle>Book a virtual visit</CardTitle>
            <CardBody>
              You will receive a call from the front desk to confirm and
              schedule an appointment.
            </CardBody>
            <CardFooter onClick={this.goToVisitRequest}>
              Request an appointment{" "}
            </CardFooter>
          </CardMessage>
          <CardImg>
            <img
              height={82}
              width={64}
              src={`${process.env.PUBLIC_URL}/img/doctor.png`}
            />
          </CardImg>
        </StyledCard>
        <StyledCard
          onClick={() => {
            this.props.history.push(`/messages`);
          }}
          style={{
            flexDirection: "column",
            backgroundColor: "#DCECF9",
            padding: "16px 10px 16px 16px",
            cursor: "pointer"
          }}
        >
          <RowWrapper>
            <CardTitle>Get assistance</CardTitle>
            {/* <OnlineHeader /> */}
            <GotoChatBtnWrapper>
              <Icon name="arrow_forward" style={{ fontSize: "16px" }} />
            </GotoChatBtnWrapper>
          </RowWrapper>
          <CardBody style={{ width: "200px" }}>
            Our staff will get in touch with you for any query.
          </CardBody>
        </StyledCard>
        {mobileCheck() && (
          <HomeFooter>
            {practiceDetails.phoneNo ? (
              <p>
                <PhoneIcon name="phone" />
                {practiceDetails.phoneNo}
              </p>
            ) : (
              ""
            )}
            {practiceDetails.address ? (
              <p>
                <AddressIcon name="email" />
                {practiceDetails.address}
              </p>
            ) : (
              ""
            )}
          </HomeFooter>
        )}
      </VFDHomeWrapper>
    );
  }
}

export default VFDHome;
