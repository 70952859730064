import React, { Component } from 'react';
import styled from 'styled-components';
import Card from '@datashop/card';

import ProgressBar from '../EvaluatePatient/ProgressBar';
import QuestionNav from './QuestionNav';
import Question from './Question';

const QuestionWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  flex-direction: column;
`;

const QuestionCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 12px;
`;

class RenderQuestions extends Component {
  render() {
    const {
      currentQuestion,
      totalQuestions,
      questionsAnswered,
      surveyList,
      dependentSurveyList,
      activeQuestionNumber,
      answerMap,
      disableNext,
      onChange,
      canMoveForward,
      handleNavClick,
      language
    } = this.props;

    return (
      <QuestionWrapper>
        <ProgressBar
          language={language}
          activeQuestion={currentQuestion}
          totalQuestions={totalQuestions}
          questionsAnswered={questionsAnswered}
        />
        <QuestionCard shadow='light'>
          <Question
            question={surveyList[currentQuestion]}
            totalQuestions={totalQuestions}
            dependentSurveyList={dependentSurveyList}
            activeQuestionNumber={activeQuestionNumber}
            questionsAnswered={questionsAnswered}
            answerMap={answerMap}
            disableNext={disableNext}
            onChange={onChange}
          />
          <QuestionNav
            language={language}
            showError={!canMoveForward}
            activeQuestion={currentQuestion}
            totalQuestions={totalQuestions}
            handleNavClick={handleNavClick}
          />
        </QuestionCard>
      </QuestionWrapper>
    );
  }
}

export default RenderQuestions;
