import React from "react";
import styled from "styled-components";
import mobileCheck from "helpers/mobileCheck";

const ViewModeCheck = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ececec;
  z-index: 11;
`;

const ImageWrapper = styled.div`
  display: flex;
  margin: 15px 0 40px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const MessageText = styled.div`
  color: black;
  font-size: 16px;
  text-align: center;
  margin-bottom: 5px;
`;

const Text = styled.div`
  color: black;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
`;

const ViewMode = () => {
  if (mobileCheck()) {
    return (
      <ViewModeCheck>
        <ImageWrapper>
          <img src={`${process.env.PUBLIC_URL}/img/noPage.png`} />
        </ImageWrapper>
        <MessageText>
          <strong>Landscape mode not supported.</strong>
        </MessageText>
        <Text>Please rotate back to potrait mode.</Text>
      </ViewModeCheck>
    );
  }

  return "";
};

export default ViewMode;
