import React, { Component } from 'react';
import styled from 'styled-components';

const NavContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justify};
  padding: 12px;
  color: rgb(0, 114, 229);
  font-weight: bold;
  border-top: 1px solid rgb(232, 228, 228);
`;

const NavText = styled.div`
  cursor: pointer;
  color: ${props => props.color};
  opacity: ${({ showError }) => (showError ? '0.7' : '1')};
`;

const Language = {
  English: {
    previous: 'Previous',
    next: 'Next',
    complete: 'Complete'
  },
  Spanish: {
    previous: 'Previo',
    next: 'Siguiente',
    complete: 'Completo'
  }
};

class QuestionNav extends Component {
  render() {
    const {
      activeQuestion,
      totalQuestions,
      handleNavClick,
      showError,
      language
    } = this.props;
    const completeCondition = activeQuestion === totalQuestions - 1;
    const justify = activeQuestion === 0 ? 'flex-end' : 'space-between';
    return (
      <NavContainer justify={justify}>
        {activeQuestion >= 1 && (
          <NavText onClick={e => handleNavClick(e, 'prev')}>
            {Language[language].previous}
          </NavText>
        )}
        <NavText
          showError={showError}
          onClick={e =>
            !showError &&
            handleNavClick(e, completeCondition ? 'complete' : 'next')
          }
          color={completeCondition && '#2ea843'}>
          {completeCondition
            ? Language[language].complete
            : Language[language].next}
        </NavText>
      </NavContainer>
    );
  }
}

export default QuestionNav;
