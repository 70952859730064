/* eslint-disable react/prop-types */
import React, { Component, Fragment } from "react";
import styled from "styled-components";
import createToast from "helpers/toastHelper";

import NoMessage from "./NoMessage";
import MessageFooter from "./MessageFooter";
import MessageHeader from "./MessageHeader";
import MessageBody from "./MessageBody";
import { Loader } from "packages/loader";
import botApi from "helpers/botApi";
import MessageSocket from "./MessageSocket";
import refreshToken from "../../helpers/refreshToken";
import Text from "@datashop/text";
import Avatar from "@datashop/avatar";
import mobileCheck from "../../helpers/mobileCheck";

const ModalWrapper = styled.div`
  > div {
    margin: 0 auto;
    right: 0;
  }
`;

const modalStyle = {
  backgroundColor: "#fff",
  height: window.innerHeight,
  width: "100%",
  display: "flex"
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: ${props => (mobileCheck() ? "calc(100% - 40px)" : "600px")};
  box-shadow: ${props =>
    mobileCheck() ? "none" : "0 2px 8px 0 rgba(0,0,0,0.16)"};
  border-radius: ${props => (mobileCheck() ? "0" : "0.5rem")};
`;

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: ${props => (mobileCheck() ? "100%" : `400px`)};
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  height: ${props => (mobileCheck() ? "auto" : "inherit")};
`;

const NoMessageWrapper = styled.div`
  height: 550px;
  display: flex;
`;

const WaitingBanner = styled.div`
  display: flex;
  padding: 12px 16px;
  background-color: #dcecf9;
  justify-content: space-between;
  width: inherit;
`;

const UnreadMessagesBanner = styled.div`
  display: flex;
  padding: 12px 16px;
  background-color: #dcecf9;
  position: absolute;
  z-index: 2;
  justify-content: center;
  width: inherit;
  bottom: 65px;
  cursor: pointer;
`;

const Title = styled(Text)`
  color: #151414;
  font-weight: "bold";
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 2px;
`;

const GreyTitle = styled(Text)`
  color: #868686;
  font-weight: "bold";
  font-size: 14px;
  line-height: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

const ProviderInfoWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fff;
`;

class Messages extends Component {
  messageListRef = React.createRef();
  state = {
    isLoading: false,
    botDetails: {},
    inboxDetails: {},
    message: "",
    error: false,
    messageList: [],
    unreadMessages: [],
    earlierMessagesLoading: false,
    disconnectObserver: false,
    channelId: null,
    name: "",
    goToMessages: false,
    holdScroll: false,
    cursor: null,
    loadingMsgs: false,
    staffList: [],
    assignedStaff: "",
    gettingStaff: true,
    scrollTopPosition: ""
  };

  setupChat = () => {
    this.getBot()
      .then(({ data: { results } }) => {
        this.setState(
          {
            botDetails: results[0]
          },
          this.createInbox
        );
      })
      .catch(error => {
        this.setState({
          error: true
        });
      });
  };

  getBot = () => {
    const { organization_id } = this.props;

    botApi.defaults.headers.Authorization = `${localStorage.getItem("token")}`;
    botApi.defaults.headers.UserType = "patient";

    return botApi.get(`bot/?org_name=${organization_id}`);
  };

  getSnapshotBeforeUpdate() {
    const el = document.getElementById("messageContainer");
    return el ? el.scrollTop : "";
  }

  createInbox = () => {
    const { botDetails = {} } = this.state;
    const payload = {
      type: "BOT",
      add_users: [
        {
          user_id: botDetails.bot_id || "",
          is_admin: false
        }
      ]
    };
    botApi
      .post("inbox/", payload)
      .then(data => {
        this.setState({
          inboxDetails: data.data
        });
      })
      .catch(e => {
        this.setState({
          error: true
        });
      });
  };

  setUpHeaders = () => {
    botApi.defaults.headers.Authorization = `${localStorage.getItem("token")}`;
    botApi.defaults.headers.UserType = "patient";
  };

  sendMessage = () => {
    const {
      inboxDetails: { inbox_id },
      message,
      messageList,
      socket
    } = this.state;
    const payload = {
      sender: `PATIENT.${localStorage.getItem("userId")}`,
      recipient: inbox_id,
      message: {
        type: "CHAT_MESSAGE_TEXT",
        body: message
      },
      type: "BOT",
      event_type: "SendMessage_create"
    };
    if (socket.readyState === 1) {
      this.setState({ sendingMessage: true });
      socket.send(JSON.stringify(payload));
    }
    // else {
    //    createToast({ title: 'Not able to connect.Try Again' });
    // }
  };

  handleMarksAsRead = messageId => {
    const {
      socket,
      inboxDetails: { inbox_id }
    } = this.state;
    const payload = {
      kwargs: {
        message_id: messageId,
        inbox_id
      },
      message_id: messageId,
      event_type: "MarkMessageAsRead_partial_update"
    };
    if (socket.readyState === 1) {
      socket.send(JSON.stringify(payload));
    } else {
      createToast({ title: "Not able to connect.Try Again" });
    }
  };

  isMsgSenderPatient = (msg = {}) => {
    return msg && msg.sender && msg.sender.indexOf("PATIENT") > -1;
  };

  handleOtherUnreadMsgs = (list, index) => {
    const isPreviousMsgFromPatient = this.isMsgSenderPatient(list[index - 1]);
    if (isPreviousMsgFromPatient) {
      let currentIndex = index - 1;
      while (this.isMsgSenderPatient(list[currentIndex]) && currentIndex >= 0) {
        list[currentIndex].ticks.grey = true;
        list[currentIndex].ticks.blue = true;
        currentIndex--;
      }
    }
    return list;
  };

  handleSendMessageResponse = data => {
    const { event_type } = data;
    switch (event_type) {
      case "SendMessage_create_response":
        this.setState({
          messageList: [
            ...this.state.messageList,
            {
              ...data,
              sender: `PATIENT.${localStorage.getItem("userId")}`,
              read: "Unseen",
              ticks: {
                grey: null,
                blue: null
              }
            }
          ],
          message: "",
          sendingMessage: false
        });
        break;
      case "New_message_received":
        this.setState(
          {
            messageList: [...this.state.messageList, data]
          },
          () => {
            this.handleMarksAsRead(data.message_id);
          }
        );
        // if(this.scrollTopPosition === 0){
        //    this.setState({
        //       messageList: [
        //          ...this.state.messageList,
        //          data
        //       ]
        //    }, ()=>{ this.handleMarksAsRead(data.message_id) })
        // } else {
        //    this.setState({
        //       unreadMessages: [...this.state.unreadMessages, data],
        //       showUnreadMsgBanner: true
        //    })
        // }
        break;

      case "Message_read":
        let { message_parent_id } = data;
        let msgReadIndex = null;
        let updatedMessageList = this.state.messageList.map((msg, index) => {
          if (msg.message_parent_id === message_parent_id) {
            msgReadIndex = index;
            msg.ticks = {
              blue: true,
              grey: true
            };
          }
          return msg;
        });
        updatedMessageList = this.handleOtherUnreadMsgs(
          updatedMessageList,
          msgReadIndex
        );
        this.setState({
          messageList: updatedMessageList
        });
        break;

      case "UserViewset_list_response":
        const staffs = data.results;
        const onlineStaff = staffs.filter(staff => staff.is_online);
        this.setState({
          gettingStaff: false,
          staffList: onlineStaff || []
        });
        break;
      case "Bot_AssignedToStaff":
        const { first_name, last_name } = data;
        const newMsgList = [
          ...this.state.messageList,
          {
            type: "chatAssigned",
            message: `🙌 connected to ${first_name} ${last_name}`
          }
        ];
        this.setState({
          inboxDetails: {
            ...this.state.inboxDetails,
            assignedStaff: `${first_name} ${last_name}`
          },
          messageList: newMsgList
        });
        break;
      case "Bad_request":
      case "Not_found":
      case "Exception_500":
        if (this.state.sendingMessage) {
          const updatedMsgList = [...this.state.messageList];
          updatedMsgList.pop();
          this.setState({
            sendingMessage: false,
            messageList: updatedMsgList
          });
        }
        break;
      default:
        break;
    }
  };

  getInbox = () => {
    const isClosed = false;
    const noPage = false;
    let url = "inbox/";
    let newMsgList = [];
    let staff = "";
    let staffName = "";
    url = isClosed && `${url}?is_closed=true`;
    url = noPage && `url${isClosed ? "&" : "?"}no_page=true`;
    botApi
      .get(`inbox/${isClosed ? "?is_closed=true&type=BOT" : ""}`)
      .then(data => {
        const { results } = data.data;
        if (results.length > 0) {
          const bot_id = results[0].users[0].user_id;
          const { inbox_id, type, users, unread_message_count } = results[0];
          if (type === "GROUP") {
            staff = users.filter(user => user.user_type === "STAFF")[0];
            if (staff) {
              staffName = `${staff.first_name} ${staff.last_name}`;
              newMsgList = [
                ...this.state.messageList,
                {
                  type: "chatAssigned",
                  message: `🙌 connected to ${staffName}`
                }
              ];
            }
          }
          this.setState(
            {
              botDetails: {
                ...this.state.botDetails,
                bot_id
              },
              inboxDetails: {
                ...this.state.inboxDetails,
                inbox_id,
                type,
                assignedStaff: staffName,
                unread_message_count
              },
              loadingMsgs: true,
              loadingInbox: false,
              messageList:
                newMsgList.length > 0 ? newMsgList : this.state.messageList
            },
            this.getPreviousMessages
          );
        } else {
          this.setState({ loadingInbox: false });
          this.setupChat();
        }
      })
      .catch(error => {
        this.setState({
          error: true
        });
      });
  };

  readOfflineMsgs = msgList => {
    const {
      inboxDetails: { unread_message_count }
    } = this.state;
    if (unread_message_count) {
      const lastMsg = msgList[msgList.length - 1];
      setTimeout(() => {
        this.handleMarksAsRead(lastMsg.message_id);
      }, 1000);
    }
    //  let updatedMsgList = msgList.map(msg => {
    //    if (msg.sender.indexOf("PATIENT") > -1) {
    //      msg.ticks.blue = true;
    //      msg.ticks.grey = true;
    //      return msg;
    //    } else {
    //      return msg;
    //    }
    //  });
    return msgList;
  };

  getPreviousMessages = () => {
    const {
      inboxDetails: { inbox_id }
    } = this.state;
    botApi
      .get(`/inbox/${inbox_id}/get_messages/`)
      .then(data => {
        const { results } = data.data;
        const updatedMsgList = this.readOfflineMsgs(results.reverse());
        if (results.length > 0) {
          this.setState({
            messageList: [...this.state.messageList, ...updatedMsgList],
            loadingMsgs: false
          });
        } else {
          this.setState({ loadingMsgs: false });
        }
      })
      .catch(error => {
        this.setState({
          error: true
        });
      });
  };

  handleRefreshToken = () => {
    refreshToken()
      .then(data => {
        const { accessToken, refreshToken } = data.data;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        this.props.history.push(`/`);
        this.props.history.push(`/messages`);
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push(`/signup`);
        window.location.reload();
      });
  };

  setupResponseInterceptor = () => {
    botApi.interceptors.response.use(
      res => res,
      err => {
        const {
          data: { detail },
          status
        } = err.response;
        if (status === 401 || status === 403) {
          this.handleRefreshToken();
        }
        if (err.response.status === 404) {
          throw new Error(`${err.config.url} not found`);
        }
        if (err.response.status === 428) {
          const redirectUrl = encodeURIComponent(
            `${this.props.history.location.pathname}${this.props.history.location.search}`
          );
          this.props.history.push(`/accept-consent?redirect=${redirectUrl}`);
        }

        throw err;
      }
    );
  };

  componentDidMount() {
    this.setUpHeaders();
    this.setupResponseInterceptor();
    this.setState({ loadingInbox: true });
    // this.setupChat()
    this.getInbox();
    // const messageContainer =document.getElementById('messageContainer');
    // if(messageContainer){
    //    messageContainer.addEventListener('scroll', e=>{
    //       this.scrollTopPosition = e.target.scrollTop;
    //       if(this.state.unreadMessages.length > 0  && this.scrollTopPosition === 0){
    //          this.handleUnreadMsgs()
    //       }
    //    })
    // }
    const socket = MessageSocket.create(
      this.onConnect.bind(this),
      this.handleSendMessageResponse
    );
    this.setState({
      socket
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scrollTopPosition === "") {
      const messageContainer = document.getElementById("messageContainer");
      if (messageContainer) {
        this.setState({
          scrollTopPosition: messageContainer.scrollTop
        });
      }
    }
  }

  handleScroll = e => {
    this.setState({
      scrollTopPosition: e.target.scrollTop
    });
  };

  onConnect() {
    const payload = {
      query_params: { user_type: "STAFF" },
      event_type: "UserViewset_list"
    };
    if (this.state.socket.readyState === 1) {
      this.state.socket.send(JSON.stringify(payload));
      // this.readOfflineMsgs();
    }
  }

  componentWillUnmount() {
    MessageSocket.close(this.state.socket);
    // document.getElementById('#messageContainer').removeEventListener('scroll');
  }

  goBack = () => {
    this.props.history.push("/");
  };

  onInputChange = event => {
    const {
      keyCode,
      target: { value }
    } = event;
    if (keyCode === 13 && value.trim() !== "") {
      this.sendMessage();
    } else {
      this.setState({
        message: value
      });
    }
  };

  handleKeyDown = event => {
    const {
      keyCode,
      target: { value }
    } = event;
    if (keyCode === 13 && value.trim() !== "") {
      this.sendMessage();
    }
  };

  isStaffAssigned = () => {
    const { inboxDetails } = this.state;
    return inboxDetails.assignedStaff || inboxDetails.type === "GROUP";
  };

  renderWaitingBanner = () => {
    const { staffList = [], gettingStaff } = this.state;
    if (!this.isStaffAssigned()) {
      return (
        <WaitingBanner>
          <TitleContainer>
            <Title>Waiting for staff to join....</Title>
            {/* <GreyTitle>Team typically replies in 5 minutes.</GreyTitle> */}
          </TitleContainer>
          <ProviderInfoWrapper>
            {!gettingStaff ? (
              <Fragment>
                {/* <Icon name="people" style={{ color: '#DCECF9', fontSize: '16px', padding: '4px' }} /> */}
                {staffList[0] && (
                  <Avatar
                    text={`${staffList[0].first_name[0]} ${staffList[0].last_name[0]}`}
                    style={{ width: "24px", height: "24px", fontSize: "10px" }}
                  />
                )}
                <Text
                  fontSize="small"
                  style={{
                    color: "#2F2F2F",
                    padding: "4px 0 0 4px",
                    lineHeight: "18px"
                  }}
                >
                  {staffList.length > 1 ? ` + ${staffList.length - 1}` : ``}
                </Text>
              </Fragment>
            ) : (
              <div style={{ marginTop: "8px" }}>
                <Loader width="16px" height="16px" />
              </div>
            )}
          </ProviderInfoWrapper>
        </WaitingBanner>
      );
    } else {
      return null;
    }
  };

  hideWaitingBanner = () => {
    const messageContainer = document.getElementById("messageContainer");
    if (messageContainer) {
      const isScrollable =
        messageContainer.scrollHeight > messageContainer.clientHeight;
      return isScrollable && this.state.scrollTopPosition === 0;
    }
  };

  // showUnreadMsgsBanner = () => {
  //    const { unreadMessages } = this.state;
  //    if(unreadMessages.length > 0){
  //       return(
  //          <UnreadMessagesBanner onClick={this.handleUnreadMsgs}>
  //             <Badge round appearance="tawak">
  //                {unreadMessages.length}
  //             </Badge>
  //             <Text>unread messages</Text>
  //          </UnreadMessagesBanner>
  //       )
  //    }
  // }

  // handleUnreadMsgs = () => {
  //    const { unreadMessages, messageList } = this.state;
  //    unreadMessages.forEach(msg=> {
  //       this.handleMarksAsRead(msg.message_id)
  //    });
  //    this.setState({
  //       messageList: [...messageList, ...unreadMessages],
  //       unreadMessages: []
  //    })
  // }

  render() {
    const {
      loadingInbox,
      loadingMsgs,
      messageList,
      sendingMessage,
      staffList,
      inboxDetails,
      unreadMessages,
      scrollTopPosition
    } = this.state;
    return (
      <Wrapper>
        <MessageHeader
          name={this.state.name}
          goBack={this.goBack}
          staffList={staffList}
          assignedStaff={inboxDetails.assignedStaff}
        />
        {this.state.error ? (
          <NoMessageWrapper>
            <NoMessage type="error" />
          </NoMessageWrapper>
        ) : (
          <ContentWrapper>
            {!this.hideWaitingBanner() && this.renderWaitingBanner()}
            {/* {this.showUnreadMsgsBanner()} */}
            {loadingMsgs || loadingInbox ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <MessageWrapper>
                {messageList.length === 0 && !loadingMsgs && !loadingInbox ? (
                  <NoMessageWrapper>
                    <NoMessage type="noMessages" />
                  </NoMessageWrapper>
                ) : (
                  <MessageBody
                    // ref={this.messageListRef}
                    messageList={messageList}
                    loadingMsgs={loadingMsgs}
                    scrollTopPosition={this.scrollTopPosition}
                    unreadMessages={unreadMessages}
                    handleScroll={this.handleScroll}
                    handleMarksAsRead={this.handleMarksAsRead}
                  />
                )}
              </MessageWrapper>
            )}
            <MessageFooter
              handleInputChange={this.onInputChange}
              message={this.state.message}
              sendMessage={this.sendMessage}
              handleKeyDown={this.handleKeyDown}
              sendingMessage={sendingMessage}
            />
          </ContentWrapper>
        )}
      </Wrapper>
    );
  }
}

export default Messages;
