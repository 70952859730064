import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 24px;
`;

const Icon = styled.i`
  font-size: 64px;
  color: ${({ color }) => color};
`;

const Title = styled.p`
  margin-top: 16px;
  color: #000000;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 28px;
`;

const Message = styled.div`
  margin-top: 24px;
  color: #868686;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.38px;
  line-height: 24px;
`;

const iconMap = {
  0: { icon: "error_outline", color: "#2f2f2f" },
  1: { icon: "check_circle_outline", color: "#2EA843" },
  2: { icon: "phone_disabled", color: "#D93737" },
  3: { icon: "update", color: "#F07D00" },
  4: { icon: "hourglass_top", color: "#FFC208" }
};

function VisitStatus({ status, message, title }) {
  return (
    <Wrapper>
      <Icon color={iconMap[status].color} className="material-icons">
        {iconMap[status].icon}
      </Icon>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </Wrapper>
  );
}

export default VisitStatus;
