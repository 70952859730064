import Axios from "axios";
const URL =
  process.env[`REACT_APP_${process.env.REACT_APP_BUILD}_BRANDING_API`];

// const URL = "https://s3zhqlzn4f.execute-api.us-east-2.amazonaws.com/dev";
// const URL = 'https://17y6kj10e4.execute-api.us-east-2.amazonaws.com/preprod'
const brandApi = Axios.create({
  baseURL: URL,
  headers: {
    "X-TIMEZONE-OFFSET": new Date().getTimezoneOffset()
  }
});

export default brandApi;
