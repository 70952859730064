const colors = [
  '#4C9AE7',
  '#E15F5F',
  '#F9B061',
  '#FEBF3D',
  '#A7D961',
  '#57BA75',
  '#6474DD',
  '#9272C0'
];

export const getLetterIconColor = (firstName, lastName) => {
  return colors[
    (firstName.charCodeAt(0) + (lastName || firstName).charCodeAt(0)) % 8
  ];
};
