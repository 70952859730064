import React, { useState, memo } from "react";
import styled from "styled-components";
import Popup from "@datashop/popup";
import Button from "@datashop/button";

const MoreButton = styled(Button)`
  margin-left: 16px;
`;

const MenuOption = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  padding: 4px 0;
  bottom: 104px;
`;

const ButtonWrapper = styled(Button)`
  color: #2f2f2f;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
`;

const Options = ({ remove, flowType, isLocal }) => {
  const [showExpanded, toggleExpanded] = useState(false);

  return (
    <Popup
      trigger={
        <MoreButton dimension="tiny">
          <i className="material-icons">more_vert</i>
        </MoreButton>
      }
      onToggle={open => {
        toggleExpanded(open);
      }}
      position="LeftCenter"
      open={showExpanded}
      appendToBody={false}
    >
      <MenuOption>
        {!isLocal &&
          (flowType === "PROVIDER" || flowType === "STAFF") &&
          remove && (
            <ButtonWrapper
              appearance="transparent"
              onClick={() => {
                remove();
                toggleExpanded(false);
              }}
            >
              Remove
            </ButtonWrapper>
          )}
      </MenuOption>
    </Popup>
  );
};

export default memo(Options);
