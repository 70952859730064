import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  padding: 24px 16px 160px;
`;

const Text = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const Bold = styled.span`
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-top: 12px;
`;

const DisclosureStatement = () => (
  <Wrapper>
    <ContentWrapper>
      <Text>
        <Bold>Consent for Treatment - </Bold>
      </Text>
      <Text>
        &emsp;&emsp;I authorize Central Maine Medical Center/Central Maine
        Healthcare (CMMC/CMH), its physicians, employees, agents, independent
        physicians, and contractors, to diagnose, treat and care for me.
      </Text>
      <Text>
        &emsp;&emsp;- I understand and accept that Anesthetists, Pathologists,
        Radiologists, and some other physicians are independent or contracted
        physicians, and not employees or agents of the hospital.
      </Text>
      <Text>
        &emsp;&emsp;- I consent to examinations, tests, treatments,
        immunizations, medications, photo-documentation and other treatments or
        procedures determined to be beneficial.
      </Text>
      <Text>
        &emsp;&emsp;- I understand I may be transferred to another health care
        provider or facility, for my care and treatment. I understand I have the
        right to refuse all or part of care provided to me.
      </Text>
      <Text>
        <Bold>Assignment of payments and guarantee of payment - </Bold>
      </Text>
      <Text>
        &emsp;&emsp;I authorize and direct my health insurance carrier(s) or
        other third parties who are responsible for paying my health care to pay
        the costs associated with my treatment and care directly to CMMC/CMH. I
        guarantee full payment of charges not covered or paid by my health
        insurance within 90 days, including any co- payments or deductibles,
        collection costs and attorneys’ fees. If I am unable to pay, I agree to
        complete a detailed financial statement to make alternative arrangements
        for full payment.{" "}
      </Text>
      <Text>
        <Bold>
          Consent to Use and Disclosure of Protected Health Information -{" "}
        </Bold>
      </Text>
      <Text>
        &emsp;&emsp;I consent to Central Maine Healthcare (CMH) and its
        affiliates use and disclosure of my protected health information (“PHI”)
        in support of my diagnosis and treatment, payment for the medical
        services I receive, and the legitimate health care operations of the
        medical practice health care operations.
      </Text>
      <Text>
        <Bold>I consent</Bold> to CMH and all affiliates disclosure of PHI to
        other health care practitioners and facilities that are involved in
        providing medical services to me and to my family and close friends who
        are providing me with emotional support as I receive medical services.
        In addition, I consent to CMH and all affiliates disclosure of PHI to my
        health insurance carrier, utilization review organization, or
        third-party administrator to support payment for my medical services.
      </Text>
      <Text>
        <Bold>I understand</Bold> that CMH and all affiliates agreement,
        including independent and contracted professionals, to provide medical
        services to me, is conditional upon my signing of this consent form.
      </Text>
      <Text>
        <Bold>I understand</Bold> that CMH and all affiliates will access my
        external prescription history for the purpose of facilitating my care.
      </Text>
      <Text>
        <Bold>I understand</Bold> that CMH and all affiliates will disclose only
        the minimum amount of my health care information which is necessary, in
        the judgment of CMH and all of its affiliates, for the legitimate needs
        of the recipient or for my general well-being.
      </Text>
      <Text>
        <Bold>I understand</Bold> my PHI which is the subject of this consent
        includes demographic information; information about my physical or
        mental health condition; medication prescription history; information
        about the medical services provided to me, including payment
        information, if any of that information may be used to identify me.
        [Depending upon the medical services I request or require this
        information may include information about treatments for HIV/AIDS,
        sexually-transmitted diseases, mental health or psychiatric conditions,
        or substance abuse.]
      </Text>
      <Text>
        <Bold>I understand</Bold> that I have a right to restrict CMH and its
        affiliates use and disclosure of my PHI and that CMH and its affiliates
        is not obligated to agree to the requested restrictions, but that an
        agreement to a restriction binds CMH and its affiliates. I may revoke
        this consent at any time providing CMH and its affiliates with a
        written, signed, and dated request except to the extent that CMH and all
        affiliates have acted in reliance upon my consent. However, I understand
        that any restriction on the use and disclosure of PHI or revocation of
        this consent may result in improper diagnosis or treatment, denial of
        coverage of a claim for insurance benefits, or other adverse
        consequences.
      </Text>
      <Text>
        <Bold>I acknowledge</Bold> this consent will remain in effect for all
        subsequent uses and disclosures for the limited purposes outlined above
        for 30 months from the date of this consent, unless I revoke it earlier,
        as described above.
      </Text>
      <Text>
        <Bold>I understand</Bold> that CMH and its affiliates regard the
        safeguarding of PHI as an important duty. I understand, furthermore,
        that the elements of this consent are required by state and federal law
        for my protection and to ensure my informed consent to the use and
        disclosure of PHI necessary to support my relationship with CMH and all
        its affiliates.
      </Text>
      <Text>
        <Bold>After an office visit</Bold>, I consent to a message being left on
        my answering machine regarding tests, procedures, treatments, and
        results understanding that others within the household may have access
        to this information.
      </Text>
      <Text>
        <Bold>
          Notice of Privacy: I have received a copy of CMH and all affiliates’
          Notice of Privacy Practices which provides a more complete description
          of the uses and disclosures addressed above. I acknowledge that CMH
          and its affiliates reserve and its affiliates reserve the right to
          amend the Notice of Privacy Practices periodically. I understand that
          I may obtain a current copy of the Notice by contacting the office
          staff or my physician, at any time.
        </Bold>
      </Text>
      <Text>
        <Bold>I consent to being contacted:</Bold> You agree that Central Main
        Healthcare, including our business associates, may contact you by
        telephone at any telephone number provided by you or associated with
        your record, including your e-mail address of cell phone numbers, which
        could result in charges to you. Methods of contact may include using a
        pre-recorded/artificial voice messages and/or use of an automated
        dialing service as applicable.
      </Text>
      <Text>
        &emsp;&emsp;I understand, if I have a question about this consent or
        about CMH and all affiliates privacy practices, or if I want to have a
        copy of this consent, I may ask the office staff or my physician.
      </Text>
    </ContentWrapper>
  </Wrapper>
);

export default DisclosureStatement;
