import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "helpers/axios";
import { Mixpanel } from "helpers/mixpanelHelper";
import Popup from "@datashop/popup";
import Button from "@datashop/button";

const MoreButton = styled(Button)`
  margin-left: 32px;

  @media (max-width: 1024px) {
    margin-left: 24px;
  }
`;

const MsgWrapper = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  width: 320px;
  height: 264px;
  bottom: 104px;
  padding: 8px 8px 16px 8px;
`;

const MsgHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const NotesText = styled.p`
  color: #151111;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
const ContentWrapper = styled.div`
  margin-top: 8px;
  border: 1px solid #d3d1d1;
  border-radius: 4px;
  padding: 8px 12px;
  height: 200px;
`;

const TextArea = styled.textarea`
  outline: none;
  border: none;
  margin: 0px;
  resize: none;
  height: 100%;
  width: 100%;
`;

let timeout = undefined;
const Message = ({ visitId, userId }) => {
  const [showExpanded, toggleExpanded] = useState(false);
  const [text, changeText] = useState("");

  useEffect(() => {
    axios
      .get(
        `/innote-survey/telehealth/videos/${visitId}/notes?user_id=${userId}`
      )
      .then(({ data: { note } }) => {
        changeText(note);
      });
    return () => {
      if (text.length) Mixpanel.track("Notes Added");
    };
  }, []);

  const debounce = value => {
    changeText(value);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      axios.patch(`/innote-survey/telehealth/videos/${visitId}/notes`, {
        note: value,
        userId: userId
      });
    }, 1000);
  };

  return (
    <Popup
      trigger={
        <MoreButton appearance="secondary">
          <i className="material-icons">assignment</i>
          {window.innerWidth > 1024 && (
            <div style={{ marginLeft: 8 }}>Take notes</div>
          )}
        </MoreButton>
      }
      onToggle={open => {
        toggleExpanded(open);
      }}
      position="TopLeft"
      verticalOffset={42}
      open={showExpanded}
    >
      <MsgWrapper>
        <MsgHeader>
          <NotesText>Note</NotesText>
          <Button
            dimension="tiny"
            appearance="transparent"
            onClick={() => toggleExpanded(false)}
          >
            <i className="material-icons">close</i>
          </Button>
        </MsgHeader>
        <ContentWrapper>
          <TextArea
            onChange={event => debounce(event.target.value)}
            type="textarea"
            placeholder="Add a note here"
            value={text}
          />
        </ContentWrapper>
      </MsgWrapper>
    </Popup>
  );
};

export default Message;
