import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "@datashop/icon";
import StyledButton from "@datashop/button";
import { Loader } from "../../packages/loader/";
import createToast from "helpers/toastHelper";
import authApi from "../../helpers/authApi";
import mobileCheck from "../../helpers/mobileCheck";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  padding: ${props => (mobileCheck() ? "20px 15px 20px" : "0 15px 20px")};
`;

const Content = styled.section`
  margin-top: 15px;
  height: ${props => (mobileCheck() ? "100%" : "500px")};
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Article = styled.article`
  height: 100%;
`;

const Button = styled(StyledButton)`
  margin-top: 40px;
  margin-bottom: ${props => (mobileCheck() ? "15px" : "0")};
  height: 40px;
  font-weight: 300;
`;

const GoBack = styled(Icon)`
  font-size: 24px;
  color: #2f2f2f;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const TermsAndCondition = ({ history }) => {
  const [state, setState] = useState({
    hidden: true,
    isTermsLoading: false,
    isConcentLoading: false,
    consent: {
      id: null,
      content: ""
    }
  });

  useEffect(() => {
    loadLatestTnC();
  }, []);

  const loadLatestTnC = () => {
    setState({ ...state, isTermsLoading: true });
    authApi.defaults.headers.Authorization = getAuthToken();
    authApi.get("terms/latest").then(response => {
      setState({
        ...state,
        isTermsLoading: false,
        consent: {
          termId: response.data.termId,
          content: response.data.template
        }
      });
    });
  };

  const acceptConcent = () => {
    setState({ ...state, isConcentLoading: true });
    authApi.defaults.headers.Authorization = getAuthToken();
    authApi
      .post("terms/consent/", {
        terms: state.consent.termId,
        consent: "AGREE"
      })
      .then(response => {
        setState({
          ...state,
          isConcentLoading: false
        });

        if (response.data.consent === "AGREE") {
          const params = new URLSearchParams(history.location.search);
          const redirectUrl = decodeURIComponent(params.get("redirect"));
          redirectUrl && redirectUrl !== "null"
            ? history.push(redirectUrl)
            : history.push("/");
        } else {
          createToast({ title: "Failed to accept T&C. Try Again." });
        }
      })
      .catch(error => {
        createToast({ title: "Failed to accept T&C. Try Again." });
      });
  };

  const getAuthToken = () => {
    return `Bearer ${window.localStorage.getItem("token")}`;
  };

  const goBack = () => {
    const params = new URLSearchParams(history.location.search);
    const redirectUrl = decodeURIComponent(params.get("redirect"));
    redirectUrl && redirectUrl !== "null" && redirectUrl.indexOf("signup") == -1
      ? history.push(redirectUrl)
      : history.push("/signup");
  };

  return (
    <Wrapper data-test="wrapper__tnc-wrapper">
      <header data-test="wrapper__tnc-header">
        <GoBack name="arrow_back" onClick={() => goBack()} />
        <h2>Terms and Conditions</h2>
      </header>
      <section data-test="wrapper__tnc-content">
        <Content>
          {state.isTermsLoading && <Loader />}
          {state.consent.content && (
            <Article
              dangerouslySetInnerHTML={{ __html: state.consent.content }}
            />
          )}
        </Content>
      </section>
      <footer data-test="wrapper__tnc-footer">
        <Button
          data-test="wrapper__tnc-footer__button"
          style={{ width: "100%" }}
          disabled={state.isConcentLoading}
          onClick={acceptConcent}
          appearance="primary"
        >
          {state.isConcentLoading ? (
            <Loader stroke="#fff" />
          ) : (
            "Accept Terms and Conditions"
          )}
        </Button>
      </footer>
    </Wrapper>
  );
};
