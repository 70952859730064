import {
  REQUEST_GET_EVALUATE_PATIENT,
  GET_EVALUATE_PATIENT_FAILURE,
  GET_EVALUATE_PATIENT_SUCCESS
} from "./constants";

const initialState = {
  isLoading: false,
  error: null,
  data: []
};

function evaluatePatient(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GET_EVALUATE_PATIENT:
      return { ...initialState, isLoading: true };
    case GET_EVALUATE_PATIENT_SUCCESS:
      const newList = action.data.map(item => {
        return { ...item, selectedAnswerId: null };
      });
      return {
        ...state,
        data: newList,
        dependentSurveyList: action.dependentSurveyList,
        isLoading: false
      };
    case GET_EVALUATE_PATIENT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    default:
      return state;
  }
}

export default evaluatePatient;
