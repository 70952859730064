import React, { Fragment } from "react";
import styled from "styled-components";

import { getLetterIconColor } from "helpers/badgeColor";
import returnCamelCasedName from "helpers/nameTransformer";
import { OnlineHeader } from "../VFDHome/VFDHome";
import Icon from "@datashop/icon";
import Text from "@datashop/text";

const HeaderContainer = styled.div`
  padding: 18px 14px;
  display: flex;
  background-color: #ffffff;
`;

const HeaderText = styled.p`
  color: #151414;
  font-size: 16px;
  font-weight: 600;
  margin-right: 2px;
`;

const HeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RecipientLogo = styled.div`
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 12px;
  color: white;
  margin-right: 12px;
  background-color: ${({ color }) => color};
`;

const BackButton = styled(Icon)`
  font-size: 16px;
  margin-right: 12px;
  cursor: pointer;
`;

const MessageHeader = ({ goBack, name, staffList, assignedStaff }) => (
  <Fragment>
    <HeaderContainer>
      <BackButton name="arrow_back" onClick={goBack} />
      <HeaderText>{assignedStaff ? assignedStaff : "Get assistant"}</HeaderText>
      {!assignedStaff && <OnlineHeader mode="messages" staffList={staffList} />}
    </HeaderContainer>
  </Fragment>
);

export default MessageHeader;
